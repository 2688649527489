import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
    },
}));

const SubmitDialog = (props) => {
    const { onSubmit, onClose, open, title, fullScreen, forceFullScreen } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullScreen={(isMobile || forceFullScreen) && fullScreen}
            fullWidth={true}
        >
            <DialogTitle className={classes.title} disableTypography>
                {title}
            </DialogTitle>
            <DialogContent>{props.children}</DialogContent>
            <DialogActions disableSpacing style={{ padding: 20 }}>
                <Button size="small" color="secondary" onClick={onClose}>
                    لغو
                </Button>
                <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                        onClose();
                        onSubmit();
                    }}
                >
                    تایید
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SubmitDialog;
