import React, { useRef } from "react";
import { Container, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import MainContainer from "../../components/UI/Containers/MainContainer";
import LoadingButton from "../../components/UI/Buttons/LoadingButton";
import CalculatorHeader from "./Components/CalculatorHeader";
import CalculatorAttributes from "./Components/CalculatorAttributes";

import { getFieldAttributes, calculateAttributes } from "../../api/calculatorAPI";
import { showAlert } from "../../store/actions/snackbarActions";
import { toEnNumber } from "../../shared/toEnNumber";
import Styles from "./wageStyles";

const WageCalculator = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = Styles();
    const [loading, setLoading] = React.useState(false);
    const [attributes, setAttributes] = React.useState({ general_attributes: [], self_attributes: [] });
    const [formData, setFormData] = React.useState({});
    const contentRef = useRef({});

    const getAttributes = (field) => {
        if (field?.value) {
            setFormData({ field_id: field.value });
            setLoading(true);
            getFieldAttributes(field?.value).then((response) => {
                setLoading(false);
                setAttributes(response);
            });
        }
    };

    const calculate = (event) => {
        event.preventDefault();
        if (Object.keys(formData).length <= 1) {
            dispatch(showAlert("داده‌ای وارد نکرده اید.", "error"));
            return;
        }
        calculateAttributes({
            ...formData,
        }).then((response) => {
            dispatch(showAlert(response.message, response.type));
            if (response.type === "success") handleRedirect(response.data.tracking_code);
        });
    };

    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData((state) => ({
            ...formData,
            [inputIdentifier]: value,
        }));
    };

    const handleRedirect = (trackCode) => {
        history.push(`/wagefactor?tracking_code=${trackCode}`);
    };

    return (
        <MainContainer>
            <Grid container justify="center">
                <Grid item xs={12} md={9} lg={8} component="form" onSubmit={calculate}>
                    <CalculatorHeader
                        getAttributes={getAttributes}
                        setAttributes={setAttributes}
                        setCalculatorFormData={setFormData}
                    >
                        <LoadingButton
                            loading={loading}
                            disabled={!formData.field_id}
                            className={classes.submit}
                            type="submit"
                            fullWidth
                        >
                            محاسبه حق الزحمه
                        </LoadingButton>
                    </CalculatorHeader>
                    {attributes.self_attributes?.length > 0 && (
                        <Container className={classes.container}>
                            <CalculatorAttributes
                                contentRef={contentRef}
                                attributes={attributes.self_attributes}
                                handleChange={inputChangedHandler}
                                formData={formData}
                            />
                        </Container>
                    )}
                    {attributes.general_attributes?.length > 0 && (
                        <Container className={classes.container}>
                            <CalculatorAttributes
                                contentRef={contentRef}
                                attributes={attributes.general_attributes}
                                handleChange={inputChangedHandler}
                                formData={formData}
                            />
                        </Container>
                    )}
                </Grid>
            </Grid>
        </MainContainer>
    );
};

export default WageCalculator;
