import React from "react";
import { Button, Menu, MenuItem, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        color: "rgba(89, 201, 250, 1)",
        backgroundColor: "rgba(89, 201, 250, 0.1)",
        minWidth: "contain",
        border: "none !important",
        width: "100%",
        height: 45,
        padding: theme.spacing(1, 0),
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
        "&:hover": {
            backgroundColor: "rgba(89, 201, 250, 0.3)",
        },
        "&:disabled": {
            backgroundColor: "rgba(0,0,0,0.02)",
        },
    },
}));

const MenuButton = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { label, options, ...otherProps } = props;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Button className={classes.root} variant="outlined" onClick={handleClick}>
                {label}
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        {...option}
                        onClick={() => {
                            handleClose();
                            option.onClick();
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
};

export default MenuButton;
