import React from "react";
import Grid from "@material-ui/core/Grid";
import formContent from "./formData";
import formCreator from "../../../shared/formCreator";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import axios from "../../../api/instance";
import Moment from "moment";

const FinancialReport = (props) => {
    const [formData, setFormData] = React.useState({
        start_date: null,
        end_date: null,
    });
    const changeHandler = (val, name) => {
        setFormData((state) => ({ ...state, [name]: val }));
    };
    
    return (
        <Container maxWidth="sm">
            <Grid container spacing={4} style={{ width: "100%", margin: 0 }}>
                {formCreator(formContent(formData), changeHandler)}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        component="a"
                        href={
                            axios.defaults.baseURL +
                            `/assignees/financial-report?
                            start_date=${
                                formData.start_date ? Moment(formData.start_date).format("YYYY-MM-DD 00:00:00") : null
                            }&
                            end_date=${
                                formData.end_date ? Moment(formData.end_date).format("YYYY-MM-DD 00:00:00") : null
                            }`
                        }
                        color="primary"
                    >
                        دریافت اکسل
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default FinancialReport;
