import React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Button, Collapse, Grid, makeStyles, Typography } from "@material-ui/core";

import MenuItem from "./MenuItem";
import CardsDrawer from "./Drawer/CardsDrawer";
import InquireDialog from "./Dialog/InquireDialog";
import AddOrganizationDialog from "./Dialog/AddOrganization";

import { enterRole } from "../../../store/actions/rolesActions";
import { showDialog } from "../../../store/actions/dialogActions";
import getRouteFromRole from "../../../shared/getRouteFromRole";
import locale from "../../../shared/locale";
import { signContractAgreement } from "../../../store/actions/userActions";

const styles = makeStyles((theme) => ({
    active: {
        backgroundColor: "rgba(159, 162, 180, 0.08)",
    },
    menuItem: {
        marginTop: 40,
        color: "#A4A6B3",
        flexGrow: 1,
        "& a": {
            borderRadius: 4,
            "&:hover": {
                color: "#fff",
                backgroundColor: "rgba(159, 162, 180, 0.08)",
            },
        },
    },
    collapseButton: {
        padding: theme.spacing(0.5),
        fontSize: theme.typography.pxToRem(12),
        color: "#A4A6B3",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

const makeNav = (nav, openStatus, activeRole) => {
    const classes = styles();
    const suitableNav = nav.filter(
        (item) => item.accessibleRoles === "all" || item.accessibleRoles.includes(activeRole)
    );
    return suitableNav.map((item) => {
        const link = item.roleIndependent ? "/" + item.link : "/" + getRouteFromRole(activeRole) + item.link;
        return (
            <MenuItem
                icon={item.icon}
                label={item.key === "dashboard" ? item.label + " " + locale(activeRole) : item.label}
                open={openStatus}
                link={link}
                active={classes.active}
                key={item.key}
            />
        );
    });
};

const makeButton = (nav, openStatus) => {
    return <MenuItem icon={nav.icon} label={nav.label} open={openStatus} key={nav.key} onClick={nav.onClick} />;
};

const SideBarMenu = (props) => {
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { open, nav, anchor } = props;
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [openCollapse, setOpenCollapse] = React.useState(false);
    const user = useSelector((store) => store.user);
    const groupRoles = useSelector((store) => store.roles.group_roles);
    const roles = useSelector((store) => store.roles.roles);

    React.useEffect(() => {
        setOpenDrawer(false);
    }, [user.active_role]);

    let dashboardNav = nav.slice(0, 4);
    dashboardNav[0].onClick = () => {
        history.push("/");
        dispatch(enterRole(null));
    };
    dashboardNav[1].onClick = () => {
        if (user.contract_agreement_date) {
            dispatch(enterRole("EXPERT"));
            history.push(`/${user.active_route}`);
        } else {
            dispatch(
                showDialog({
                    type: "ACTION",
                    content: (
                        <Typography align="justify">
                            احتراماً اینجانب {user.name + " " + user.surname} کارشناس رسمی دادگستری در رشته حوزه قضائی
                            استان شهر آمادگی کامل دارم ضمن عضویت در سامانه شفافیت مرکز نسبت به کارشناسی موضوعات محوله
                            ابلاغ شده از سوی کمیسیون توسعه خدمات حقوقی مرکز و نیز سامانه “کارشناس رسمی” در اسرع وقت
                            اقدام نموده و همچنین به "سامانه ارجاع" اجازه می‌دهم پس از اعمال تخفیفات ، تا سقف بیست درصد
                            از حق الزحمه را کسر و به منظور توسعه امور مربوط به کاریابی، جذب، ممیزی و سایر خدمات تعریف
                            شده یا در راستای اجرای مسئولیت‌های اجتماعی کارشناسان کارسازی نماید.{" "}
                        </Typography>
                    ),
                    actions: [
                        {
                            label: "شرایط فوق را می‌پذیرم",
                            variant: "contained",
                            color: "primary",
                            onClick: () => {
                                dispatch(signContractAgreement());
                                history.push(`/${user.active_route}`);
                            },
                        },
                    ],
                })
            );
        }
    };
    dashboardNav[2].onClick = () => {
        setOpenDrawer(!openDrawer);
    };
    dashboardNav[3].onClick = () => {
        setOpenCollapse(!openCollapse);
    };

    return (
        <React.Fragment>
            <CardsDrawer anchor={anchor} open={openDrawer} onClose={() => setOpenDrawer(false)} />
            <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.menuItem}>
                {open && (
                    <Typography variant="caption" style={{ alignSelf: "flex-start", marginRight: 20 }} gutterBottom>
                        میزکار
                    </Typography>
                )}
                {makeButton(dashboardNav[0], open)}
                {roles.find((role) => role.id === "EXPERT") && makeButton(dashboardNav[1], open)}
                {groupRoles.length > 0 && makeButton(dashboardNav[2], open)}
                {open && (
                    <>
                        {makeButton(dashboardNav[3], open)}
                        <Collapse in={openCollapse}>
                            <Button
                                onClick={() =>
                                    dispatch(
                                        showDialog({
                                            type: "ACTION",
                                            content: <InquireDialog />,
                                        })
                                    )
                                }
                                className={classes.collapseButton}
                            >
                                افزودن حساب تخصصی
                            </Button>
                            <Button
                                onClick={() =>
                                    dispatch(
                                        showDialog({
                                            type: "ACTION",
                                            content: <AddOrganizationDialog />,
                                        })
                                    )
                                }
                                className={classes.collapseButton}
                            >
                                افزودن حساب سازمانی
                            </Button>
                        </Collapse>
                    </>
                )}

                {open ? (
                    <Typography
                        variant="caption"
                        style={{ alignSelf: "flex-start", marginRight: 20, marginTop: 20 }}
                        gutterBottom
                    >
                        مدیریت
                    </Typography>
                ) : (
                    <div style={{ marginTop: 50 }}></div>
                )}
                {makeNav(nav.slice(4), open, user.active_role)}
            </Grid>
        </React.Fragment>
    );
};

export default SideBarMenu;
