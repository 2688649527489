import React from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardCards from "../../../components/Dashboard/Cards/DashboardCards";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import ChartsContainer from "../../../components/Charts/ChartsContainer";
import CategoriesTable from "../../../components/Tables/CategoriesTable/CategoriesTable";

import { fetchReports, fetchCategories } from "../../../store/actions/reportsActions.js";
import { reportStatus } from "../../../shared/checkStatus.js";
import { fetchCharts } from "../../../api/userAPI";

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const [charts, setCharts] = React.useState([]);
    const { loading, reports, categories } = useSelector((store) => store.reports);

    React.useEffect(() => {
        dispatch(fetchReports(true));
        dispatch(fetchCategories(true));
        fetchCharts().then((response) => setCharts(response.data));
    }, []);

    return (
        <MainContainer>
            <ChartsContainer
                charts={[
                    { type: "Bar", data: charts.MonthReportChart, title: "تعداد گزارش / ماه" },
                    { type: "Bar", data: charts.StatusReportChart, title: "تعداد گزارش / وضعیت" },
                    { type: "Bar", data: charts.WageReportChart, title: "تعداد گزارش / حق الزحمه" },
                ]}
            />
            <DashboardCards
                cards={[
                    {
                        title: "تکمیل شده",
                        value: reports?.filter((item) => item.status === reportStatus.COMPLETED).length,
                        color: "green",
                    },
                    {
                        title: "در حال ارجاع",
                        value: reports?.filter((item) => item.status === reportStatus.ASSIGNING).length,
                        color: "blue",
                    },
                    {
                        title: "در حال نگارش",
                        value: reports?.filter((item) => item.status === reportStatus.IN_PROGRESS).length,
                        color: "yellow",
                    },
                ]}
                loading={loading}
                showDashboardButton
            />
            <CategoriesTable categories={categories} reports={reports} loading={loading} />
        </MainContainer>
    );
};

export default Dashboard;
