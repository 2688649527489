const vars = {
	    baseUrl: "https://api.vosoolyar.ir",
	    redirectUrl: "https://vosoolyar.ir",
	    mapirAccessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjIxNmFhN2IyZmNiODMxYjI5MTI2MTE2MTJhYWUzNzE5YzZlYjE2ZDBhNTI0MDQxMWVkYWVjMGI2ZTBiMDU0N2U0N2IzYzY4YzE4YWY0ODg3In0.eyJhdWQiOiIxMzcyNCIsImp0aSI6IjIxNmFhN2IyZmNiODMxYjI5MTI2MTE2MTJhYWUzNzE5YzZlYjE2ZDBhNTI0MDQxMWVkYWVjMGI2ZTBiMDU0N2U0N2IzYzY4YzE4YWY0ODg3IiwiaWF0IjoxNjE5NDI1NzMzLCJuYmYiOjE2MTk0MjU3MzMsImV4cCI6MTYyMjAxNzczMywic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.Ir2JI__Uma13BPLsuaguciy0hvWmXNPQaqOlEFVSdnuq47DID1Czww5hEbkCzzbW-Kw344nGAwiiyMZZ-Du5ePuli_esJlEDFt0949FaDTKQ_hWzdda9n8NB12P0q87lrVR7o2f-koj2e4eQwYJO8F6wGN2ZRJyNXa_Zasvobjk_R9N6-rw-LvPsg46pUxyipj5KkY7x-5RgCPlGvvUxdmF7iqyFHjBqQMmRH-bVNKP-Ux8rBB1y4F6HMR10y_WFhV5Gyyeh9SBhs4GPvIux3yv6s-H2rYfkHvdzVVAn8XRYHUdByz0AFMwEorS1wW1q0hD_sSaKo9asWy6WHNMeIA",
	    mapirReverseBaseUrl: "https://map.ir/reverse/no?",
	    mapirForwardBaseUrl: "https://map.ir/search/v2/autocomplete",
	    version: "2.0.0b2",
	    debug: true,
};

export default vars;

