import React from "react";
import { makeStyles, Button, InputBase, Paper, Typography } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import SelectInput from "../../../components/UI/Input/SelectInput";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        width: "40%",
        borderRadius: 8,
        boxShadow: "0px 0px 20px rgba(215, 222, 227, 0.4)",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    inputBase: {
        flex: 1,
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(16),
        height: 40,
    },
    input: {
        fontSize: theme.typography.pxToRem(14),
    },
    icon: {
        display: "flex",
        padding: theme.spacing(0, 0.5, 0, 1),
        color: "#A3A9B7",
        "& svg": {
            fontSize: 28,
        },
    },
    Button: {
        borderRadius: "0 8px 8px 0",
        height: "100%",
        minWidth: 0,
        width: 50,
    },
    selectContainer: {
        width: "35%",
        minWidth: 150,
        height: 30,
        paddingRight: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    select: {
        width: "100%",
        "& > .MuiInputBase-root": {
            height: "30px !important",
        },
        "& div": {
            fontSize: theme.typography.pxToRem(12),
        },
    },
}));

const SearchBar = (props) => {
    const classes = useStyles();
    const { onSubmit, onChange, value, ...otherProps } = props;

    return (
        <Paper
            component="form"
            onSubmit={(event) => {
                event.preventDefault();
                onSubmit();
            }}
            className={classes.root}
        >
            <div className={classes.icon}>
                <SearchIcon />
            </div>
            <InputBase
                className={classes.inputBase}
                {...otherProps}
                value={value}
                onChange={onChange}
                inputProps={{ className: classes.input }}
            />
            <div className={classes.selectContainer}>
                <Typography
                    variant="caption"
                    component="span"
                    color="textSecondary"
                    style={{ marginLeft: 6, marginBottom: 3 }}
                >
                    براساس:
                </Typography>
                <SelectInput
                    label=""
                    options={[{ label: "نام گزارش", value: "report" }]}
                    value={"report"}
                    variant="outlined"
                    className={classes.select}
                />
            </div>
        </Paper>
    );
};
export default SearchBar;
