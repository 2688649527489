import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import styles from "./LoginFormStyle";
import LoginForm from "./LoginForm";
import ValidateForm from "./ValidateForm";
import RegisterForm from "./RegisterForm";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const LoginContainer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const classes = styles();

    const [loginStep, setLoginStep] = React.useState("login");
    const [phoneNumber, setPhoneNumber] = React.useState(null);
    const setData = (phoneNumber, status) => {
        setPhoneNumber(phoneNumber);
        setLoginStep(status);
    };

    return (
        <Grid item xs={12} md={6}>
            <Card className={classes.cardRoot}>
                <Typography className={classes.title} component="h3">
                    ورود و ثبت نام کاربران
                </Typography>
                <CardContent>
                    {loginStep === "login" && <LoginForm changeStep={setData} active={true} isMobile={isMobile} />}
                    {loginStep === "validate" && (
                        <ValidateForm
                            phoneNumber={phoneNumber}
                            changeStep={setData}
                            active={true}
                            isMobile={isMobile}
                        />
                    )}
                    {loginStep === "register" && (
                        <RegisterForm
                            phoneNumber={phoneNumber}
                            changeStep={setData}
                            active={true}
                            isMobile={isMobile}
                        />
                    )}
                </CardContent>
            </Card>
        </Grid>
    );
};

export default LoginContainer;
