import React from "react";
import { useSelector, useDispatch } from "react-redux";

import SearchListInput from "../Input/SearchListInput";
import { fetchGroups } from "../../../store/actions/groupsActions";
import { fetchReports, fetchCategories } from "../../../store/actions/reportsActions";

const StoreField = (props) => {
    const {
        store,
        field,
        value,
        onChange,
        mapper = (value) => value,
        optionMapper = (item) => item,
        ...otherProps
    } = props;

    const dispatch = useDispatch();
    const options = useSelector((state) => state[store]?.[field]);
    const updatedOptions = options.map(optionMapper);
    const loading = useSelector((state) => state[store]?.["loading"]);

    React.useEffect(() => {
        dispatch(fetchGroups());
        dispatch(fetchCategories());
        dispatch(fetchReports());
    }, []);

    let updatedValue = updatedOptions.find((item) => mapper(item) === value);
    updatedValue = updatedValue ?? value;

    return (
        <SearchListInput
            options={updatedOptions ?? []}
            value={updatedValue}
            onChange={(value) => onChange(mapper(value) ?? value)}
            loading={loading}
            {...otherProps}
        />
    );
};

export default StoreField;
