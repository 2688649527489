import React from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ReportIcon from "@material-ui/icons/Report";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: -43
    },
    title: {
        textAlign: "center",
        marginBottom: 10,
        "& p": {
            fontSize: 20,
        },
    },
    buttons: {
        textAlign: "center",
    },
});

const MaintenancePage = (props) => {
    const styles = useStyles();
    const history = useHistory();
    const { message } = props;
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <ReportIcon fontSize="large" />
                <Typography>{message}</Typography>
            </div>
            <div className={styles.buttons}>
                <Button variant="outlined" color="primary" style={{ margin: 5 }} onClick={() => history.push("/")}>
                    بازگشت به صفحه‌ی اصلی
                </Button>
            </div>
        </div>
    );
};

export default MaintenancePage;
