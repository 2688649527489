import React from "react";
import { Grid, Typography, makeStyles, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(2, 0, 1, 0),
        },
    },
    text: {
        color: "#5c5f66",
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
    },
    chip: {
        height: 14,
        color: "#fff",
        fontWeight: 600,
        backgroundColor: "#d9213f",
        marginLeft: 8,
        "& span": {
            borderRadius: 8,
        },
    },
}));

const ChipTypo = (props) => {
    const classes = useStyles();
    const { chip, tableMargin } = props;

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.container}
            style={{ margin: tableMargin ? "32px 0 -12px 0" : "32px 0 12px 0" }}
        >
            <Typography className={classes.text} variant="h6">
                {props.children}
            </Typography>
            {chip > 0 && <Chip label={chip} size="small" className={classes.chip} />}
        </Grid>
    );
};

export default ChipTypo;
