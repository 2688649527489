const inquireInputs = (defaults) => {
    return {
        license_number: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره پروانه",
                type: "number",
            },
            validation: {
                required: true,
            },
            value: defaults.values?.license_number ?? "",
            gridConfig: {
                xs: 6,
                md: 4,
            },
        },
        issued_date: {
            elementType: "date-picker",
            elementConfig: {
                label: "تاریخ صدور",
            },
            gridConfig: {
                xs: 6,
                md: 4,
            },
            validation: {
                required: true,
            },
            value: defaults.values?.issued_date ?? null,
        },
        expiration_date: {
            elementType: "date-picker",
            elementConfig: {
                label: "اعتبار",
            },
            gridConfig: {
                xs: 6,
                md: 4,
            },
            validation: {
                required: true,
            },
            value: defaults.values?.expiration_date ?? null,
        },
        expertise: {
            elementType: "search-list-input",
            elementConfig: {
                label: "رشته",
                options: defaults.options?.expertise ?? [],
            },
            validation: {
                required: true,
            },
            gridConfig: {
                md: 4,
                xs: 6,
            },
            value: defaults.values?.expertise ?? null,
        },
        competences: {
            elementType: "multiple-select",
            elementConfig: {
                label: "صلاحیت",
                options: defaults.options?.competences ?? [],
                noOptionLabel: "رشته را انتخاب کنید",
            },
            gridConfig: {
                md: 8,
                xs: 12,
            },
            value: defaults.values?.competences ?? [],
        },
        province_id: {
            elementType: "province-fields",
            elementConfig: {},
            validation: {
                custom: (value) => {
                    if (!value.province_id) return false;

                    return true;
                },
                helperText: "مقدار ورودی نمیتواند خالی باشد",
            },
            value:
                {
                    province_id: defaults.values?.province_id,
                    town_id: defaults.values?.town_id,
                    city_id: defaults.values?.city_id,
                } ?? {},
        },
        address: {
            elementType: "count-text-field",
            elementConfig: {
                label: "آدرس",
                multiline: "true",
            },
            value: defaults.values?.address ?? "",
        },
        id_card: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "تصویر کارت شناسایی",
            },
            value: defaults.values?.id_card ?? null,
        },
        license: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "تصویر پروانه کارشناسی",
            },
            value: defaults.values?.license ?? null,
        },
    };
};

export default inquireInputs;
