const AddOrganizationFormData = (defaults) => {
    return {
        name: {
            elementType: "text-field",
            elementConfig: { label: "نام سازمان", variant: "outlined" },
            validation: { required: true },
            value: defaults?.name ?? "",
            gridConfig: {
                xs: 12,
                md: 6,
            },
        },
        description: {
            elementType: "text-field",
            elementConfig: {
                label: "توضیحات",
                multiline: true,
                variant: "outlined",
                rows: "3",
            },
            value: defaults?.description ?? "",
            gridConfig: {
                xs: 12,
            },
        },
    };
};

export default AddOrganizationFormData;
