import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Grid, TextField } from "@material-ui/core";

import LinearLoading from "../../../../components/Static/LinearLoading/LinearLoading";
import ChipTypo from "../../../../components/UI/Typography/ChipTypo";
import NoContent from "../../../../components/Static/NoContent/NoContent";
import MainContainer from "../../../../components/UI/Containers/MainContainer";
import ActionCard from "../../../../components/Surfaces/Cards/ActionCard";
import SubmitDialog from "../../../../components/Dialogs/SubmitDialog";
import ExpertsContainer from "./ExpertsContainer";

import { fetchReports } from "../../../../store/actions/reportsActions";
import { fetchGroups } from "../../../../store/actions/groupsActions";
import { showAlert } from "../../../../store/actions/snackbarActions";
import { getCompetences } from "../../../../api/sharedAPI";
import { getExpertsList } from "../../../../api/userAPI";
import { autoAssignExperts } from "../../../../api/assigneesAPI.js";
import formCreator from "../../../../shared/formCreator";
import searchExpertInputs from "./searchExpertInputs";
import locale from "../../../../shared/locale";

const SearchExpert = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const type = new URLSearchParams(history.location.search).get("type");
    const { reportId } = useParams();
    const provinces = useSelector((store) => store.geo);
    const [experts, setExperts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [requiredAssignees, setRequiredAssignees] = React.useState("");
    const [controls, setControls] = React.useState(
        searchExpertInputs({
            province_id: { options: provinces },
            hide: {
                license_number: type === "supervisor",
                expertise: type === "supervisor",
                competences: type === "supervisor",
            },
        })
    );

    React.useEffect(() => {
        getCompetences().then((response) => {
            const updatedResponse = response.map((item) => ({
                name: item.field,
                value: item.id,
                competences: item.competences.map((competence) => ({
                    name: competence.competence,
                    value: competence.id,
                })),
            }));
            let updatedControls = { ...controls };
            updatedControls["expertise"].elementConfig.options = updatedResponse;
            setControls(updatedControls);
        });
        dispatch(fetchReports());
        dispatch(fetchGroups());
    }, []);

    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedControls = { ...controls };
        updatedControls[inputIdentifier].value = value || "";

        if (inputIdentifier === "province_id") {
            if (value != null) updatedControls["town_id"].elementConfig.options = value.towns;
            else updatedControls["town_id"].elementConfig.options = [];

            updatedControls["town_id"].value = "";
            updatedControls["city_id"].elementConfig.options = [];
            updatedControls["city_id"].value = "";
        }
        if (inputIdentifier === "town_id") {
            if (value != null) updatedControls["city_id"].elementConfig.options = value.cities;
            else updatedControls["city_id"].elementConfig.options = [];

            updatedControls["city_id"].value = "";
        }
        if (inputIdentifier === "expertise") {
            if (value) updatedControls["competences"].elementConfig.options = value.competences || [];
            else updatedControls["competences"].elementConfig.options = [];

            updatedControls["competences"].value = [];
        }
        setControls(updatedControls);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        const filters = {};

        for (let key in controls) {
            if (!controls[key].value || controls[key].value?.length === 0) continue;
            filters[key] = controls[key].value;
            if (filters[key].id) filters[key] = filters[key].id;
            if (filters[key].name) filters[key] = filters[key].name;
        }
        filters["role_id"] = type;
        setLoading(true);
        setExperts([]);
        getExpertsList({ ...filters, competences: controls.competences.value.map((item) => item.value) }).then(
            (response) => {
                if (response.message) dispatch(showAlert(response.message, response.type));
                setExperts(response.data);
                setLoading(false);
            }
        );
    };
    const handleSubmit = () => {
        const filters = {};
        for (let key in controls) {
            filters[key] = controls[key].value;
            if (filters[key].id) filters[key] = filters[key].id;
            if (filters[key].name) filters[key] = filters[key].name;
        }

        setOpenDialog(false);
        autoAssignExperts({
            filters: filters,
            required_assignees: requiredAssignees,
            role_id: type,
            report_id: reportId,
        }).then((response) => {
            dispatch(showAlert(response.message, response.type));
        });
    };
    const formElements = formCreator(controls, inputChangedHandler);

    return (
        <MainContainer>
            <SubmitDialog
                title="انتخاب سیستمی"
                open={openDialog}
                onSubmit={handleSubmit}
                onClose={() => setOpenDialog(false)}
            >
                <TextField
                    type="number"
                    label="تعداد کارشناسان"
                    value={requiredAssignees}
                    onChange={(event) => setRequiredAssignees(event.target.value)}
                    style={{ width: "100%" }}
                />
            </SubmitDialog>
            <ChipTypo>جستجوی {locale(type)}</ChipTypo>
            <ActionCard
                component="form"
                onSubmit={handleSearch}
                actions={[
                    {
                        onClick: () => setOpenDialog(true),
                        variant: "contained",
                        label: "انتخاب سیستمی",
                    },
                    {
                        onClick: handleSearch,
                        variant: "contained",
                        color: "primary",
                        label: "جستجو",
                        type: "submit",
                        loading: loading,
                        style: { minWidth: 100 },
                    },
                ]}
            >
                <Grid container spacing={2}>
                    {formElements}
                </Grid>
            </ActionCard>
            <ChipTypo chip={experts?.length}>نتایج جستجو</ChipTypo>
            {loading && <LinearLoading width={200} title="در حال جستجو" />}
            {experts.length === 0 && !loading && <NoContent text="نتیجه ای یافت نشد" maxWidth={400} />}
            {experts?.length > 0 && <ExpertsContainer experts={experts} type={type} reportId={reportId} />}
        </MainContainer>
    );
};

export default SearchExpert;
