import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Avatar, Button, Box, Typography } from "@material-ui/core";

import ExpertInfoTable from "../../../components/Tables/ExpertInfoTable/ExpertInfoTable";
import DataTable from "../../../components/Tables/DataTable/DataTable";
import ActionDialog from "../../../components/Dialogs/ActionDialog";
import FilePreviewDialog from "../../../components/Dialogs/FilePreviewDialog";
import LicenseTable from "../../../components/Tables/LicenseTable/LicenseTable";

import Styles from "./styleReportDetails";
import { revokeAssigneeRole, removeAssignee } from "../../../api/assigneesAPI";
import checkStatus, { roles } from "../../../shared/checkStatus";
import { showAlert } from "../../../store/actions/snackbarActions";
import locale from "../../../shared/locale";

const AllExpertsContainer = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = Styles();

    const [openDialog, setOpenDialog] = React.useState({ info: false, documents: false, expert: {} });
    const { experts, setExperts, report } = props;
    const user = useSelector((store) => store.user);

    const expertsData = experts.map((expert) => ({
        data: {
            avatar: <Avatar src={expert.avatar_link} />,
            name: expert.name,
            surname: expert.surname,
            role: expert.assignee_role,
            status: (
                <span style={{ borderRight: `3px solid #A3A9B7` }} className={classes.status}>
                    {locale(expert?.status)}
                </span>
            ),
            action_1: expert.response?.documents && (
                <Button
                    className={classes.seeMore}
                    onClick={() => setOpenDialog({ ...openDialog, documents: true, expert: expert })}
                >
                    مشاهده
                </Button>
            ),
            action_2: (
                <Button
                    className={classes.seeMore}
                    onClick={() => setOpenDialog({ ...openDialog, info: true, expert: expert })}
                >
                    مشاهده
                </Button>
            ),
        },
        config: { className: classes.row, align: "center" },
    }));

    const handleRevoke = (roleId) => {
        revokeAssigneeRole({ assignee_id: openDialog.expert.id, role_id: roleId }).then((response) => {
            if (response.type === "success")
                setExperts(
                    experts.map((item) => {
                        if (item.assignee_role_id === roleId)
                            return {
                                ...item,
                                assignee_role: "کارشناس",
                                assignee_role_id: "EXPERT",
                            };
                        if (item.id !== openDialog.expert.id) return item;
                        else
                            return {
                                ...item,
                                assignee_role: locale(roleId),
                                assignee_role_id: roleId,
                            };
                    })
                );
            dispatch(showAlert(response.message, response.type));
        });
    };

    const handleRemoveAssignee = () => {
        removeAssignee({ assignee_id: openDialog.expert.id }).then((response) => {
            if (response.type === "success") {
                let removed = experts.find((item) => item.id === openDialog.expert.id);
                removed.status = "REJECTED";
                removed.status_comment = "حذف توسط سامانه";
                setExperts([...experts?.filter((item) => item.id !== openDialog.expert.id), { ...removed }]);
            }
            dispatch(showAlert(response.message, response.type));
        });
    };

    return (
        <React.Fragment>
            <FilePreviewDialog
                title={`پاسخ‌های ارسالی از ${openDialog.expert.name} ${openDialog.expert.surname}`}
                open={openDialog.documents}
                onClose={() => setOpenDialog({ ...openDialog, documents: false })}
                files={openDialog.expert?.response?.documents}
            />
            <ActionDialog
                title="اطلاعات کارشناس"
                open={openDialog.info}
                onClose={() => setOpenDialog({ ...openDialog, info: false })}
                actions={checkStatus(
                    [
                        {
                            role: [roles.GroupAdmin, roles.Referee],
                            condition: user?.active_group_type === "SUPERVISOR",
                            content: {
                                label: "مشاهده پروفایل",
                                variant: "contained",
                                color: "primary",
                                onClick: () =>
                                    history.push({
                                        pathname: `/${user.active_route}/expertprofile`,
                                        search: "userId=" + openDialog.expert.user_id,
                                    }),
                            },
                        },
                        {
                            role: [roles.Referee, roles.GroupAdmin],
                            condition: user?.active_group_type === "SUPERVISOR" && report?.is_person === 0,
                            content: {
                                label: "انتخاب به عنوان سرگروه",
                                variant: "contained",
                                color: "primary",
                                disabled: openDialog.expert.assignee_role === roles.Head,
                                hide: openDialog.expert.assignee_role === roles.Supervisor,
                                onClick: () => handleRevoke("HEAD"),
                            },
                        },
                        {
                            role: [],
                            content: {
                                label: "انتخاب به عنوان دبیر",
                                variant: "contained",
                                color: "primary",
                                disabled: openDialog.expert.assignee_role_id === "SECRETARY",
                                onClick: () => handleRevoke("SECRETARY"),
                            },
                        },
                        {
                            role: [],
                            content: {
                                label: "حذف نقش",
                                variant: "contained",
                                color: "secondary",
                                disabled: openDialog.expert.assignee_role_id === "EXPERT",
                                onClick: () => handleRevoke("EXPERT"),
                            },
                        },
                        {
                            role: [],
                            content: {
                                label: "حذف کارشناس",
                                variant: "contained",
                                color: "secondary",
                                style: { marginTop: 8, marginRight: 0, width: "100%" },
                                onClick: handleRemoveAssignee,
                            },
                        },
                    ],
                    "",
                    user?.active_role
                )}
            >
                <ExpertInfoTable expert={openDialog.expert} />
                <div style={{ marginTop: 10 }}>
                    {openDialog.expert?.licenses?.map((license) => (
                        <LicenseTable license={license} />
                    ))}
                </div>
            </ActionDialog>
            {experts?.length > 0 ? (
                <Grid container spacing={2} direction="column" justify="flex-start">
                    <Box className={classes.tableContainer}>
                        <Box className={classes.titleContainer}>کارشناسان</Box>
                        <DataTable
                            head={{
                                data: {
                                    avatar: "",
                                    name: "نام",
                                    surname: "نام خانوادگی",
                                    role: "نقش",
                                    status: "وضعیت",
                                    action_1: "پاسخ گزارش",
                                    action_2: "مشاهده جزئیات",
                                },
                                config: {
                                    className: classes.head,
                                    align: "center",
                                },
                            }}
                            body={expertsData}
                        />
                    </Box>
                </Grid>
            ) : (
                <Typography variant="body1" className={classes.checkTypo}>
                    کارشناسی وجود ندارد
                </Typography>
            )}
        </React.Fragment>
    );
};

export default AllExpertsContainer;
