import React from "react";
import { Grid, Typography, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Calculator from "./Calculator";
import ShowFactor from "./ShowFactor";

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        backgroundColor: "#303033",
        borderRadius: 5,
    },
    title: {
        fontFamily: "IranSansBold",
        textAlign: "center",
        padding: 4,
        margin: "9px 16px",
        backgroundColor: "#ACBBA8",
        borderRadius: 4,
    },
    inputColor: {
        backgroundColor: "#D9EFFF",
    },
}));

const CalculatorContainer = (props) => {
    const classes = useStyles();
    const [form, setForm] = React.useState("calculate");
    const changeForm = (id) => {
        setForm(id);
    };
    return (
        <Grid item xs={12} md={6}>
            <Card className={classes.cardRoot}>
                <Typography className={classes.title} component="h3">
                    محاسبه گر حق الزحمه کارشناس رسمی
                </Typography>
                <CardContent>
                    {form === "calculate" ? (
                        <Calculator changeForm={changeForm} />
                    ) : (
                        <ShowFactor changeForm={changeForm} />
                    )}
                </CardContent>
            </Card>
        </Grid>
    );
};

export default CalculatorContainer;
