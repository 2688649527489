import React from "react";
import { useParams } from "react-router-dom";
import { Grid, TextField, InputAdornment, IconButton } from "@material-ui/core";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";

import { sendMessage } from "../../../api/reportsAPI";
import styles from "./style";

const AddMessage = (props) => {
    const classes = styles();
    const { reportId } = useParams();
    const { addMessage } = props;
    const [message, setMessage] = React.useState("");

    const changeMessage = (e) => {
        let val = e.target.value;
        setMessage(val);
    };

    const handleSend = () => {
        let data = {
            report_id: reportId,
            message,
        };
        if (message !== "" || message !== undefined || message !== null) {
            sendMessage(data).then((response) => {
                if (response.type === "success") addMessage(response.data);
            });
            setMessage("");
        }
    };

    const uploadFile = (e) => {
        let data = new FormData();
        data.append("document", e.target.files[0]);
        data.append("report_id", reportId);
        sendMessage(data).then((response) => {
            if (response.type === "success") addMessage(response.data);
        });
    };
    return (
        <Grid container className={classes.root}>
            <Grid item md={12} sm={12} xs={12} style={{ margin: 10 }}>
                <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    label="متن پیام"
                    name="message"
                    onChange={changeMessage}
                    value={message}
                    multiline={true}
                    autoComplete="off"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <input
                                    // accept="image/*"
                                    style={{ display: "none" }}
                                    id="icon-button-file"
                                    type="file"
                                    onChange={uploadFile}
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton aria-label="conversation info" component="span">
                                        <AttachFileOutlinedIcon />
                                    </IconButton>
                                </label>
                                <IconButton
                                    aria-label="conversation info"
                                    component="span"
                                    style={{ transform: "rotate(180deg)" }}
                                    onClick={handleSend}
                                >
                                    <SendOutlinedIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default AddMessage;
