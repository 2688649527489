import React from "react";
import { v4 as uuidv4 } from "uuid";
import { makeStyles, Card, CardActionArea, Typography } from "@material-ui/core";
import AddFileIcon from "../../UI/Icons/AddFileIcon";

const useStyles = makeStyles((theme) => ({
    card: {
        border: "2px solid rgba(84, 97, 122, 0.5);",
        color: "#a3a9b7",
        boxShadow: "none",
        height: 63,
        borderRadius: 4,
    },
    actionArea: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1, 2),
        height: "100%",
        width: "100%",
    },
    input: {
        display: "none",
    },
}));

const UploadCard = (props) => {
    const classes = useStyles();
    const { label, onChange, multiple, ...otherProps } = props;

    const id = uuidv4() + "contained-button-file";
    let inputProps = {
        accept: `image/* , .zip , .pdf , .ppt , .pptx , .doc , .docx, .xml , application/msword , application/vnd.openxmlformats-officedocument.wordprocessingml.document ,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel`,
        className: classes.input,
        id: id,
        type: "file",
        onChange: onChange,
    };
    return (
        <Card className={classes.card}>
            {multiple ? <input {...inputProps} multiple /> : <input {...inputProps} />}

            <label htmlFor={id} style={{ width: "100%", height: "100%" }}>
                <CardActionArea className={classes.actionArea} component="span">
                    <Typography variant="body2" color="inherit" style={{ fontWeight: 700 }}>
                        {label}
                    </Typography>
                    <AddFileIcon color="inherit" style={{ fontSize: 30 }} />
                </CardActionArea>
            </label>
        </Card>
    );
};

export default UploadCard;
