import React from "react";
import { useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import ActionDialog from "../../../../components/Dialogs/ActionDialog";
import Styles from "../styleReportDetails";

import { showAlert } from "../../../../store/actions/snackbarActions";
import { editSpecificReport } from "../../../../store/actions/reportsActions";
import { checkReport, approximateReport } from "../../../../api/assigneesAPI";
import { assigneeStatus, reportStatus } from "../../../../shared/checkStatus";
import formCreator from "../../../../shared/formCreator";
import validateForm from "../../../../shared/validateForm";
import acceptReportInputs from "./acceptReportInputs";
import { Typography } from "@material-ui/core";

const AcceptOrReject = (props) => {
    const dispatch = useDispatch();
    const classes = Styles();
    const [open, setOpen] = React.useState("");
    const [formData, setFormData] = React.useState({});
    const [checkForm, setCheckForm] = React.useState(false);
    const { changeAssigneeData, report, assignee } = props;

    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedFormData = { ...formData };
        updatedFormData[inputIdentifier] = value;
        setFormData(updatedFormData);
    };

    const acceptOrReject = (status) => {
        checkReport({
            report_id: report.id,
            acceptance: status,
            comment: formData.status_comment,
        }).then((response) => {
            if (response.type === "success") {
                changeAssigneeData({
                    status: status
                        ? report.is_person
                            ? assigneeStatus.IN_PROGRESS
                            : assigneeStatus.ACCEPTED
                        : assigneeStatus.REJECTED,
                    status_comment: formData.status_comment,
                });
                if (report?.is_person === 1) {
                    dispatch(
                        editSpecificReport({
                            ...report,
                            status: status ? reportStatus.IN_PROGRESS : reportStatus.REJECTED,
                        })
                    );
                }
            }
            dispatch(showAlert(response.data.message, response.type));
        });
    };
    const estimateReport = () => {
        const validatedForm = validateForm(acceptReportInputs(formData));
        setCheckForm(true);
        if (validatedForm.error === false)
            approximateReport({ ...formData, assignee_id: assignee.id }).then((response) => {
                if (response.type === "success") changeAssigneeData({ status: assigneeStatus.APPROXIMATED });
                dispatch(showAlert(response?.message, response.type));
            });
    };

    let formElements = formCreator(acceptReportInputs(formData), inputChangedHandler);
    if (checkForm) {
        formElements = formCreator(validateForm(acceptReportInputs(formData)).data, inputChangedHandler);
    }

    return (
        <>
            <ActionDialog
                title={open === "REJECT" ? "دلیل رد گزارش را وارد نمایید" : "تخمین خود را از گزارش وارد نمایید"}
                open={open !== ""}
                fullScreen={true && open === "ACCEPT"}
                onClose={() => setOpen("")}
                actions={[
                    {
                        variant: "contained",
                        color: "primary",
                        label: "تایید",
                        show: open === "REJECT",
                        checkClose: true,
                        onClick: () => acceptOrReject(false),
                    },
                    {
                        variant: "contained",
                        color: "primary",
                        label: "ثبت اطلاعات",
                        show: open === "ACCEPT",
                        checkClose: true,
                        onClick: estimateReport,
                    },
                ]}
            >
                <Grid container spacing={2} style={{ padding: "20px 0" }}>
                    {open === "REJECT" ? formElements[0] : formElements.splice(1)}
                </Grid>
            </ActionDialog>
            <Grid container spacing={2} justify="space-between" alignItems="center">
                <Typography variant="body1" style={{ color: "#7F899B" }}>
                    تعیین وضعیت گزارش
                </Typography>
                <Typography variant="body2" className={classes.infoTypo}>
                    <InfoOutlinedIcon />
                    برای رد گزارش باید دلیل داشته باشید.
                </Typography>
                <Grid item>
                    <Button
                        variant="contained"
                        disableElevation
                        className={classes.greenButton}
                        onClick={report.supervisor_id ? () => acceptOrReject(true) : () => setOpen("ACCEPT")}
                    >
                        قبول گزارش
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        className={classes.redButton}
                        onClick={() => setOpen("REJECT")}
                    >
                        رد گزارش
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default AcceptOrReject;
