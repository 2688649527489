const acceptReportInputs = (defaults) => {
    return {
        status_comment: {
            elementType: "text-field",
            elementConfig: {
                label: "دلیل رد گزارش",
                multiline: true,
            },
            value: defaults.status_comment,
        },
        wage_from: {
            elementType: "price-field",
            elementConfig: {
                label: "حق الزحمه از",
            },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            validation: {
                required: true,
            },
            value: defaults.wage_from ?? "",
        },
        wage_to: {
            elementType: "price-field",
            elementConfig: {
                label: "حق الزحمه تا",
            },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            validation: {
                required: true,
            },
            value: defaults.wage_to ?? "",
        },
        time: {
            elementType: "text-field",
            elementConfig: {
                label: "تعداد روز تخمین",
            },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            validation: {
                required: true,
            },
            value: defaults.time ?? null,
        },
    };
};

export default acceptReportInputs;
