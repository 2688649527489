import React from "react";

import AcceptOrReject from "./Components/AcceptOrReject";
import UploadResponse from "./Components/UploadResponse";
import Styles from "./styleReportDetails";

import { checkStatus, assigneeStatus, roles } from "../../../shared/checkStatus";

const ExpertAction = (props) => {
    const { assignee } = props;
    const classes = Styles();

    let status = [
        {
            status: assigneeStatus.NO_ACTION,
            content: AcceptOrReject,
        },
        {
            role: roles.Expert,
            status: assigneeStatus.IN_PROGRESS,
            content: UploadResponse,
        },
        {
            role: roles.Secretary,
            status: assigneeStatus.ACCEPTED,
            content: UploadResponse,
        },
    ];
    status = checkStatus(status, assignee.status, assignee.assignee_role_id);

    if (status.length === 0) return "";
    const Component = status[0];

    return (
        <div className={classes.actionContainer}>
            <Component {...props} />
        </div>
    );
};

export default ExpertAction;
