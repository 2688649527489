import React from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";

import ProvinceFields from "../../../../components/UI/Fields/ProvinceFields";
import DatePicker from "../../../../components/UI/Input/DatePicker";
import RadioGroup from "../../../../components/Search/Filter/Components/RadioGroup";
import CheckboxGroup from "../../../../components/Search/Filter/Components/CheckboxGroup";
import Accordion from "../../../../components/Surfaces/FiltersAccordion/FiltersAccordion";
import formCreator from "../../../../shared/formCreator";

export const filtersObject = {
    created_at: {
        elementType: "date-range",
        elementConfig: {
            label: " تاریخ ساخت گزارش",
        },
        value: { after: null, before: null },
    },
    due_date: {
        elementType: "date-range",
        elementConfig: {
            label: " مهلت ارسال گزارش",
        },
        value: { after: null, before: null },
    },
    wage: {
        elementType: "text-range",
        elementConfig: {
            label: "حق الزحمه",
            type: "number",
            InputProps: { endAdornment: <InputAdornment position="end">تومان</InputAdornment> },
        },
        value: { after: "", before: "" },
    },
    has_updated_task: {
        elementType: "checkbox",
        elementConfig: {
            label: "نیاز به ابلاغ جدید",
            options: [
                { name: "داشته باشد", value: "1" },
                { name: "نداشته باشد", value: "0" },
            ],
        },
        value: "",
    },
    expert: {
        elementType: "checkbox",
        elementConfig: {
            label: "وضعیت کارشناسان",
            options: [
                { name: "دارای کارشناس ردشده", value: "2" },
                { name: "به حد نصاب رسیده باشد", value: "1" },
                { name: "انتخاب نشده", value: "0" },
            ],
        },
        value: "",
    },
    is_person: {
        elementType: "checkbox",
        elementConfig: {
            label: "نوع گزارش",
            options: [
                { name: "هیئتی", value: "0" },
                { name: "فردی", value: "1" },
            ],
        },
        value: "",
    },
    has_response: {
        elementType: "checkbox",
        elementConfig: {
            label: "پاسخ گزارش",
            options: [
                { name: "داشته باشد", value: "1" },
                { name: "نداشته باشد", value: "0" },
            ],
        },
        value: "",
    },
    response_confirms: {
        elementType: "checkbox",
        elementConfig: {
            label: "دارای تایید",
            options: [
                { name: "هیئت نظارت", value: "has_supervisor" },
                { name: "هیئت کارشناسی", value: "has_head" },
                { name: "دبیر", value: "has_writer" },
            ],
        },
        value: [],
    },
    has_supervisor: {
        elementType: "checkbox",
        elementConfig: {
            label: "هیئت نظارت",
            options: [
                { name: "داشته باشد", value: "2" },
                { name: "نداشته باشد", value: "1" },
                { name: "انتخاب نشده", value: "0" },
            ],
        },
        value: [],
    },
    has_head: {
        elementType: "checkbox",
        elementConfig: {
            label: "سرگروه",
            options: [
                { name: "داشته باشد", value: "2" },
                { name: "نداشته باشد", value: "1" },
                { name: "انتخاب نشده", value: "0" },
            ],
        },
        value: [],
    },
    has_secretary: {
        elementType: "checkbox",
        elementConfig: {
            label: "دبیر",
            options: [
                { name: "داشته باشد", value: "2" },
                { name: "نداشته باشد", value: "1" },
                { name: "انتخاب نشده", value: "0" },
            ],
        },
        value: [],
    },
    has_intern: {
        elementType: "checkbox",
        elementConfig: {
            label: "کارورز",
            options: [
                { name: "داشته باشد", value: "2" },
                { name: "نداشته باشد", value: "1" },
                { name: "انتخاب نشده", value: "0" },
            ],
        },
        value: [],
    },
    estimations: {
        elementType: "checkbox",
        elementConfig: {
            label: "وضعیت حق الزحمه",
            options: [
                { name: "تایید شده", value: "2" },
                { name: "تایید نشده", value: "1" },
                { name: "وارد نشده", value: "0" },
            ],
        },
        value: [],
    },
    expert_count: {
        elementType: "text-range",
        elementConfig: {
            label: "تعداد کارشناسان",
            type: "number",
        },
        value: { after: "", before: "" },
    },
    type: {
        elementType: "radio",
        elementConfig: {
            label: "جستجو بر اساس",
            options: [
                { name: "نام گزارش", value: "0" },
                { name: "نام سازنده", value: "1" },
            ],
        },
        value: "0",
    },
    address: {
        elementType: "province-fields",
        elementConfig: {
            label: "آدرس",
        },
        value: { province_id: null, town_id: null, city_id: null },
    },
    creator_name: {
        elementType: "text",
        elementConfig: {
            label: "نام سازنده گزارش",
        },
        value: "",
    },
    creator_surname: {
        elementType: "text",
        elementConfig: {
            label: "نام‌خانوادگی سازنده گزارش",
        },
        value: "",
    },
    group: {
        elementType: "store-field",
        elementConfig: {
            label: "کسب و کار",
            store: "groups",
            field: "groups",
            mapper: (value) => value?.id,
        },
        gridConfig: {
            xs: 12,
        },
        value: "",
    },
    category: {
        elementType: "store-field",
        elementConfig: {
            label: "پرونده",
            store: "reports",
            field: "categories",
            mapper: (item) => item?.name,
            optionMapper: (item) => ({ name: item.category, value: item.category }),
        },
        gridConfig: {
            xs: 12,
        },
        value: null,
    },
};

export const searchFilters = (filters, inputChangedHandler) => {
    let filterElements = [];
    let remainingElements = {};
    for (let key in filters) {
        const element = filters[key];
        switch (element.elementType) {
            case "radio":
                filterElements.push(
                    <Accordion key={key} title={element.elementConfig.label}>
                        <RadioGroup
                            {...element.elementConfig}
                            value={element.value}
                            onChange={(event) => inputChangedHandler(event?.target?.value, key)}
                        />
                    </Accordion>
                );
                break;
            case "checkbox":
                filterElements.push(
                    <Accordion key={key} active={element.value.length !== 0} title={element.elementConfig.label}>
                        <CheckboxGroup
                            {...element.elementConfig}
                            value={element.value}
                            onChange={(value) => inputChangedHandler(value, key)}
                        />
                    </Accordion>
                );
                break;
            case "date-range":
                filterElements.push(
                    <Accordion
                        key={key}
                        active={element.value.after !== null || element.value.before !== null}
                        title={element.elementConfig.label}
                    >
                        <Grid container spacing={1}>
                            <Grid item>
                                <DatePicker
                                    fullWidth
                                    value={element.value.before}
                                    onChange={(date) => inputChangedHandler({ ...element.value, before: date }, key)}
                                    label="از تاریخ"
                                />
                            </Grid>
                            <Grid item>
                                <DatePicker
                                    fullWidth
                                    value={element.value.after}
                                    onChange={(date) => inputChangedHandler({ ...element.value, after: date }, key)}
                                    label="تا تاریخ"
                                />
                            </Grid>
                        </Grid>
                    </Accordion>
                );
                break;
            case "text-range":
                filterElements.push(
                    <Accordion
                        key={key}
                        active={element.value.after !== "" || element.value.before !== ""}
                        title={element.elementConfig.label}
                    >
                        <Grid container spacing={1}>
                            <Grid item>
                                <TextField
                                    {...element.elementConfig}
                                    value={element.value.after}
                                    onChange={(event) =>
                                        inputChangedHandler({ ...element.value, after: event.target.value }, key)
                                    }
                                    fullWidth
                                    label="از"
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    {...element.elementConfig}
                                    value={element.value.before}
                                    onChange={(event) =>
                                        inputChangedHandler({ ...element.value, before: event.target.value }, key)
                                    }
                                    fullWidth
                                    label="تا"
                                />
                            </Grid>
                        </Grid>
                    </Accordion>
                );
                break;
            case "text":
                filterElements.push(
                    <Accordion key={key} active={element.value !== ""} title={element.elementConfig.label}>
                        <TextField
                            {...element.elementConfig}
                            value={element.value}
                            onChange={(event) => inputChangedHandler(event.target.value, key)}
                            fullWidth
                        />
                    </Accordion>
                );
                break;
            case "province-fields":
                filterElements.push(
                    <Accordion
                        key={key}
                        active={
                            element.value.province_id !== null ||
                            element.value.town_id !== null ||
                            element.value.city_id !== null
                        }
                        title={element.elementConfig.label}
                    >
                        <Grid container spacing={1}>
                            <ProvinceFields
                                provinceGrid={{ xs: 12 }}
                                townGrid={{ xs: 12 }}
                                cityGrid={{ xs: 12 }}
                                values={element.value}
                                onChange={(value) => inputChangedHandler(value, "address")}
                                mapper={(value) => value?.id}
                            />
                        </Grid>
                    </Accordion>
                );
                break;
            default:
                remainingElements[key] = element;
                break;
        }
    }
    filterElements.push(
        <Accordion title={"باقی فیلترها"}>
            <Grid container spacing={1}>
                {formCreator(remainingElements, inputChangedHandler)}
            </Grid>
        </Accordion>
    );
    return filterElements;
};
