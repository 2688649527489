import { SET_GROUPS, TOGGLE_LOADING_GROUP } from "../actions/groupsActions";

const initialState = {
    groups: [],
    loading: false,
};

const groupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GROUPS:
            return { ...state, groups: action.value };
        case TOGGLE_LOADING_GROUP:
            return { ...state, loading: action.value };
        default:
            return state;
    }
};

export default groupsReducer;
