import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#eceff5",
        color: "#2a2a2a",
        textAlign: "center",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(16),
        border: "1.5px solid #fff",
        "@media print": {
            border: "1.5px solid #000",
        },
        "&:first-child": {
            borderTopLeftRadius: 6,
        },
        "&:last-child": {
            borderTopRightRadius: 6,
        },
    },
    body: {
        fontSize: theme.typography.pxToRem(14),
        textAlign: "center",
        border: "1.5px solid #fff",
        borderRadius: "inherit",
        "@media print": {
            border: "1.5px solid #000",
        },
        "&:first-child": {
            color: "#000",
            textAlign: "justify",
        },
        "&:last-child": {
            fontWeight: 700,
            fontSize: theme.typography.pxToRem(16),
        },
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: "#f5f7fc",
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {},
    table: {
        borderCollapse: "separate",
    },
}));

const WageTable = (props) => {
    const classes = useStyles();
    const { rows } = props;

    return (
        <TableContainer className={classes.root} noElevation>
            <Table className={classes.table} aria-label="customized table">
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <StyledTableCell>عناوین</StyledTableCell>
                        <StyledTableCell align="right">حق‌الزحمه</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.text}>
                            <StyledTableCell component="th" scope="row">
                                {row.text}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ whiteSpace: "nowrap" }}>
                                {row.price}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default WageTable;
