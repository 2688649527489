const reportInputs = (defaults, user) => {
    const groupFlag = user?.active_group_type === "SUPERVISOR";

    return {
        name: {
            elementType: "text-field",
            elementConfig: {
                label: "عنوان گزارش",
            },
            validation: {
                required: true,
            },
            value: defaults.values?.name ?? "",
            gridConfig: {
                xs: 12,
                sm: 6,
                md: groupFlag ? 4 : 6,
            },
        },
        group_id: {
            elementType: "store-field",
            elementConfig: {
                label: "کسب و کار",
                disabled: defaults?.disabled?.group_id,
                store: "groups",
                field: "groups",
                mapper: (item) => item?.id,
            },
            validation: {
                required: true,
            },
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
            hide: !groupFlag,
            value: defaults.values?.group_id ?? "",
        },
        category: {
            elementType: "store-field",
            elementConfig: {
                label: "پرونده",
                inputChange: true,
                store: "reports",
                field: "categories",
                mapper: (item) => item?.name,
                optionMapper: (item) => ({ name: item.category, id: item.category }),
            },
            gridConfig: {
                xs: 12,
                sm: 6,
                md: groupFlag ? 4 : 6,
            },
            value: defaults.values?.category ?? "",
        },
        is_person: {
            elementType: "select-input",
            elementConfig: {
                label: "نوع گزارش",
                options: [
                    { label: "هیئتی", value: 0 },
                    { label: "فردی", value: 1 },
                ],
            },
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
            value: defaults.values?.is_person ?? 1,
        },
        is_court: {
            elementType: "select-input",
            elementConfig: {
                label: "مرجع گزارش",
                options: [
                    { label: "غیردادگاهی", value: 0 },
                    { label: "دادگاهی", value: 1 },
                ],
            },
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
            value: defaults.values?.is_court ?? 0,
        },
        due_date: {
            elementType: "date-picker",
            elementConfig: {
                label: "مهلت گزارش",
                disablePast: !defaults.values,
            },
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
            validation: {
                required: true,
            },
            value: defaults.values?.due_date ?? null,
        },
        province_id: {
            elementType: "province-fields",
            elementConfig: {},
            validation: {
                custom: (value) => {
                    if (!value.province_id) return false;

                    return true;
                },
                helperText: "مقدار ورودی نمیتواند خالی باشد",
            },
            value:
                {
                    province_id: defaults.values?.province_id,
                    town_id: defaults.values?.town_id,
                    city_id: defaults.values?.city_id,
                } ?? {},
        },
        documents: {
            elementType: "file-uploader",
            value: defaults.values?.documents ?? [],
        },
    };
};

export default reportInputs;
