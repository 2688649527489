import React from "react";
import { useSelector } from "react-redux";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import sampleImg from "../../../assets/images/lawImage.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles({
    root: {
        borderRadius: 20,
        boxShadow: "0 8px 8px 1px #0000002e",
        margin: 10,
        overflow: "unset",
    },
    cardTitle: {
        position: "absolute",
        top: -24,
        right: 0,
        width: "100%",
        textAlign: "center",
        fontSize: 50,
        fontFamily: "IRANSansBold",
        color: "#9a47f8",
    },
    cardSubTitle: {
        textAlign: "center",
        fontSize: 15,
        fontFamily: "IRANSansBold",
        marginTop: 10,
    },
    cardDescription: {
        textAlign: "center",
        fontSize: 10,
    },
});

const Members = ({ statics }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Grid container style={isMobile ? { padding: "15% 5% 0" } : { padding: "4% 12%" }}>
            {statics.map((member) => {
                return (
                    <Grid item md={3} xs={6}>
                        <Card className={classes.root}>
                            {/* <Card className={classes.root} style={{ margin: "0 8px" }}> */}
                            <CardContent style={{ position: "relative" }}>
                                <Typography gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
                                    {member.count}
                                </Typography>
                                <Typography gutterBottom variant="h5" component="h5" className={classes.cardSubTitle}>
                                    {member.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.cardDescription}
                                >
                                    در سامانه کارشناس رسمی
                                </Typography>
                            </CardContent>
                            {/* </Card> */}
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default Members;
