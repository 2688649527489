import axios from "axios";
import vars from "../vars";

const instance = axios.create({
    baseURL: vars.baseUrl,
    validateStatus: (status) => {
        if (status === 401) {
            if (!vars.debug) window.location.href = vars.redirectUrl;
        }
        return status >= 200 && status < 300; // default
    },
    withCredentials: true,
});

export default instance;
