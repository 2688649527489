import {SET_GEO} from "../actions/geoActions";

const geoReducer = (state = [], action) => {
    switch (action.type){
        case SET_GEO:
            return action.data;
        default:
            return state;
    }
}

export default geoReducer;