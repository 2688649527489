import React from "react";
import Mapir from "mapir-react-component";
import vars from "../../vars";

const Map = Mapir.setToken({
    transformRequest: (url)=> {
        return {
            url: url,
            headers: {
                'x-api-key': vars.mapirAccessToken,
                'Mapir-SDK': 'reactjs'
            }
        }
    }
});

class MapContainer extends React.Component{
    constructor(props) {
        super(props);
    }
    render() {
        return <Mapir Map={Map} {...this.props}/>
    }
}

export default MapContainer;