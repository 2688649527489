import React from "react";
import { Bar } from "react-chartjs-2";
import Card from "@material-ui/core/Card";
import { CardContent, makeStyles, Typography } from "@material-ui/core";

const Styles = makeStyles({
    CardRoot: {
        marginTop: 20,
        boxShadow: "0 0 20px 0 #d7dee366",
    },
    title: {
        backgroundColor: "#f9fafc",
        padding: 10,
        textAlign: "center",
        fontWeight: 600,
        fontSize: "14px !important",
    },
});

const BarChart = ({ data, title }) => {
    const classes = Styles();
    return (
        <Card className={classes.CardRoot}>
            <Typography component="h2" className={classes.title}>
                {title}
            </Typography>
            <CardContent>
                <Bar
                    data={data}
                    width={100}
                    height={200}
                    options={{
                        maintainAspectRatio: false,
                    }}
                />
            </CardContent>
        </Card>
    );
};

export default BarChart;
