const articleInfo = (attribute) => {
    if (!attribute.name) return "";
    let finalStr = "";
    if (attribute.article_number) finalStr += `ماده ${attribute.article_number} `;
    if (attribute.note_number) finalStr += `تبصره ${attribute.note_number} `;
    if (attribute.section_number) finalStr += `بند ${attribute.section_number} `;
    finalStr += " - ";

    return finalStr;
};

export default articleInfo;
