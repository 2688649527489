import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    head: {
        backgroundColor: "#F9FAFC",
        color: "#A3A9B7",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        "&:nth-of-type(3)": {
            textAlign: "left",
        },
        borderWidth: 0,
    },
    row: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        marginTop: 30,
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        "&:nth-of-type(3)": {
            textAlign: "left",
        },
        borderWidth: 2,
    },
    seeMore: {
        color: "#A3A9B7",
        backgroundColor: "rgba(196, 196, 196, 0.3)",
        border: "2px solid #A3A9B7",
        padding: theme.spacing(0.2, 3),
        borderRadius: 5,
        "&:hover": {
            textDecoration: "underline",
        },
    },
    greenButton: {
        color: "#03DE73",
        backgroundColor: "rgba(3, 222, 115, 0.2)",
        borderRadius: 5,
    },
    redButton: {
        color: "#FF4646",
        backgroundColor: "rgba(255, 70, 70, 0.2)",
        borderRadius: 5,
        marginLeft: theme.spacing(0.8),
    },
    pagination: {
        margin: 20,
        "& ul": {
            justifyContent: "flex-end",
        },
    },
    rejectedText: {
        color: "#FF4646",
        fontWeight: 600,
    },
    waitingText: {
        color: "#A3A9B7",
        fontWeight: 600,
    },
}));
