import { SET_ROLES, SET_GROUP_ROLES, UPDATE_GROUP_ROLE, TOGGLE_ROLES_DRAWER } from "../actions/rolesActions";

const initialState = {
    roles: [],
    group_roles: [],
    drawerOpen: false,
    loading: false,
};

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_ROLES_DRAWER:
            return { ...state, drawerOpen: action.value ?? !state.drawerOpen };
        case SET_ROLES:
            return { ...state, roles: action.value };
        case SET_GROUP_ROLES:
            const updatedRole = action.value.sort((first, second) => {
                if (first.state === "PENDING" && second.state !== "PENDING") return -1;
                if (first.state === "ACCEPTED" && second.state !== "ACCEPTED") return -1;
                return 0;
            });
            return { ...state, group_roles: updatedRole };
        case UPDATE_GROUP_ROLE:
            return {
                ...state,
                group_roles: state.group_roles.map((groupRole) => {
                    if (groupRole.group_member_id === action.value.group_member_id) return action.value;
                    return groupRole;
                }),
            };
        default:
            return state;
    }
};

export default rolesReducer;
