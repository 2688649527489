import React from "react";
import clsx from "clsx";
import { Badge, Button, CircularProgress, withStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        borderRight: 10,
    },
    actionButton: {
        color: "rgba(89, 201, 250, 1)",
        backgroundColor: "rgba(89, 201, 250, 0.1)",
        minWidth: "contain",
        border: "none !important",
        width: "100%",
        height: 45,
        padding: theme.spacing(1, 0),
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
        "&:hover": {
            backgroundColor: "rgba(89, 201, 250, 0.3)",
        },
        "&:disabled": {
            backgroundColor: "rgba(0,0,0,0.02)",
        },
    },
}));

const StyledBadge = withStyles(() => ({
    badge: {
        backgroundColor: "rgba(204, 240, 255, 1)",
        color: "rgba(89, 201, 250, 1)",
        border: "2px solid #fff",
        width: 25,
        height: 25,
        left: 7,
        top: 2,
        borderRadius: "50%",
    },
}))(Badge);

const BadgedButton = (props) => {
    const classes = useStyles();
    const { label, onClick, loading, badge, ...otherProps } = props;

    return (
        <StyledBadge
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            badgeContent={badge}
            className={classes.root}
        >
            <Button
                variant="contained"
                onClick={onClick}
                disabled={loading || otherProps.disabled}
                disableElevation
                {...otherProps}
                className={clsx(classes.actionButton, otherProps.className)}
            >
                {loading ? <CircularProgress size={22} /> : label}
            </Button>
        </StyledBadge>
    );
};

export default BadgedButton;
