import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

import CheckBox from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxFilled from "@material-ui/icons/CheckBox";

const ArticleCheckbox = (props) => {
    const { label, value, onChange, controlProps, checkboxProps } = props;

    return (
        <FormControlLabel
            value={value}
            {...controlProps}
            control={
                <Checkbox
                    color="#000"
                    icon={<CheckBox />}
                    checkedIcon={<CheckBoxFilled />}
                    {...checkboxProps}
                    disableRipple
                    checked={value}
                    onChange={() => onChange(!value)}
                />
            }
            label={label}
        />
    );
};

export default ArticleCheckbox;
