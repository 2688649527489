import React from "react";
import { useHistory } from "react-router";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";

import DataTable from "../../../components/Tables/DataTable/DataTable";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import LicenseTable from "../../../components/Tables/LicenseTable/LicenseTable";
import LinearLoading from "../../../components/Static/LinearLoading/LinearLoading";
import Styles from "./styleApproveLicenses";

import { fetchSuperAdminLicenses, checkSuperAdminLicenses } from "../../../api/userAPI";
import { showDialog } from "../../../store/actions/dialogActions";
import { showAlert } from "../../../store/actions/snackbarActions";

const ApproveLicenses = (props) => {
    const classes = Styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [licenses, setLicenses] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(null);
    const [lastPage, setLastPage] = React.useState(1);

    const user = useSelector((store) => store.user);

    React.useEffect(() => {
        const page = new URLSearchParams(history.location.search).get("page");
        if (!page) {
            history.push("/admin/approve-licenses?page=1");
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
    }, []);

    React.useEffect(() => {
        if (currentPage) getLiceneses(currentPage);
    }, [currentPage]);

    const changePage = (page) => {
        history.push("/admin/approve-licenses?page=" + page);
        setCurrentPage(page);
    };

    const getLiceneses = (page) => {
        setLoading(true);
        fetchSuperAdminLicenses(page).then((response) => {
            setLicenses(response.data.data);
            setLastPage(response.data.meta.last_page);
            setLoading(false);
        });
    };

    const checkLicense = (license_id, verified) => {
        checkSuperAdminLicenses({ license_id: license_id, verified: verified }).then((response) => {
            dispatch(showAlert(response.message, response.type));
        });
    };

    const tableBody = licenses.map((license, index) => ({
        data: {
            index: index + 1,
            name: user.name,
            surname: user.surname,
            status:
                license.verified === 0 ? (
                    <span className={classes.rejectedText}>رد شده</span>
                ) : (
                    <span className={classes.waitingText}>در انتظار تایید</span>
                ),
            action_2: (
                <Button
                    className={classes.seeMore}
                    onClick={() =>
                        dispatch(
                            showDialog({
                                actions: [
                                    {
                                        label: "رد پروانه",
                                        variant: "contained",
                                        className: classes.redButton,
                                        onClick: () => {
                                            checkLicense(license.id, false);
                                            setLicenses(
                                                licenses.map((item) => {
                                                    if (item.id === license.id) return { ...item, verified: 0 };
                                                    return item;
                                                })

                                            );
                                        },
                                    },
                                    {
                                        label: "تایید پروانه",
                                        variant: "contained",
                                        className: classes.greenButton,
                                        onClick: () => {
                                            checkLicense(license.id, true);
                                            setLicenses(licenses.filter((item) => item.id !== license.id));
                                        },
                                    },
                                ],
                                content: (
                                    <LicenseTable
                                        license={license}
                                        append={[
                                            {
                                                data: {
                                                    document: (
                                                        <>
                                                            کارت شناسایی
                                                            <Button
                                                                component="a"
                                                                download
                                                                href={license.id_card_path}
                                                                target="_blank"
                                                                variant="contained"
                                                                color="primary"
                                                                disableElevation
                                                                style={{ marginRight: "65%" }}
                                                                size="small"
                                                            >
                                                                مشاهده
                                                            </Button>
                                                        </>
                                                    ),
                                                },
                                                config: { colspan: 2 },
                                            },
                                            {
                                                data: {
                                                    documents: (
                                                        <>
                                                            پروانه کارشناسی
                                                            <Button
                                                                component="a"
                                                                download
                                                                href={license.license_path}
                                                                target="_blank"
                                                                variant="contained"
                                                                color="primary"
                                                                disableElevation
                                                                style={{ marginRight: "63.5%" }}
                                                                size="small"
                                                            >
                                                                مشاهده
                                                            </Button>
                                                        </>
                                                    ),
                                                },
                                                config: { colspan: 2 },
                                            },
                                        ]}
                                    />
                                ),
                                type: "ACTION",
                            })
                        )
                    }
                >
                    مشاهده
                </Button>
            ),
        },
        config: { className: classes.row, align: "center" },
    }));

    return (
        <MainContainer>
            {loading ? (
                <LinearLoading width={200} title="در حال بارگیری" />
            ) : (
                <DataTable
                    head={{
                        data: {
                            avatar: "ردیف",
                            name: "نام",
                            surname: "نام خانوادگی",
                            status: "وضعیت",
                            action_2: "جزئیات",
                        },
                        config: {
                            className: classes.head,
                            align: "center",
                        },
                    }}
                    body={tableBody}
                    pagination={true}
                    count={10}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Pagination
                                className={classes.pagination}
                                count={lastPage}
                                page={parseInt(currentPage)}
                                onChange={(event, value) => changePage(value)}
                            />
                        </Grid>
                    </Grid>
                </DataTable>
            )}
        </MainContainer>
    );
};

export default ApproveLicenses;
