import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import noContentImg from "../../../assets/images/No_Content.png";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    container: {
        height: "100%",
        width: "100%",
    },
    img: {
        height: "100%",
        width: "100%",
        marginBottom: -40,
    },
    text: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 700,
        color: "grey",
    },
}));

const NoContent = (props) => {
    const { text, maxWidth } = props;
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <div className={styles.container} style={{ maxWidth: maxWidth ?? "100%" }}>
                <img src={noContentImg} alt="no_content" className={styles.img} />
                <Typography variant="caption" className={styles.text}>
                    {text}
                </Typography>
            </div>
        </div>
    );
};

export default NoContent;
