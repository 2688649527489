import axios from "./instance";
import formData from "../pages/Referee/FinancialReport/formData";

export const getAssignees = (reportId) => {
    return axios
        .get("/reports/assignees", {
            params: {
                report_id: reportId,
            },
        })
        .then((response) => {
            return response?.data?.data;
        })
        .catch((error) => {
            console.log(error?.response?.data);
            return [];
        });
};

export const autoAssignExperts = (formData) => {
    return axios
        .post("/reports/assignees/auto-assign", formData)
        .then((response) => {
            return { data: response?.data, message: "انتخاب سیستمی انجام شد", type: "success" };
        })
        .catch((error) => {
            return { data: error.response.data, message: "انتخاب انجام نشد", type: "error" };
        });
};

export const assignExpert = (formData) => {
    return axios
        .post("/reports/assign", formData)
        .then((response) => {
            return { data: response?.data, message: response?.data?.message, type: "success" };
        })
        .catch((error) => {
            return { data: error.response.data, message: error.response.data.message, type: "error" };
        });
};

export const setWageAndEstimation = (formData) => {
    return axios
        .post("/assignees/estimations/edit", formData)
        .then((response) => {
            return { data: response?.data, message: "تغییرات موفقیت آمیز بود", type: "success" };
        })
        .catch((error) => {
            return { data: error.response.message, message: "تغییرات انجام نشد", type: "error" };
        });
};

export const revokeAssigneeRole = (formData) => {
    return axios
        .post("/reports/assignees/revoke", formData)
        .then((response) => {
            return { data: response?.data, message: response.data.message, type: "success" };
        })
        .catch((error) => {
            return { data: error?.response?.message, message: error?.response?.data?.message, type: "error" };
        });
};

export const removeAssignee = (formData) => {
    return axios
        .post("/assignees/remove", formData)
        .then((response) => {
            return { data: response?.data, message: response.data.message, type: "success" };
        })
        .catch((error) => {
            return { data: error.response.message, message: error?.response?.data?.message, type: "error" };
        });
};

export const checkReport = (formData) => {
    return axios
        .post("/assignees/check-report", formData)
        .then((response) => {
            return { data: response?.data, message: response.data.message, type: "success" };
        })
        .catch((error) => {
            return { data: error.response.message, message: error?.response?.data?.message, type: "error" };
        });
};

export const approximateReport = (formData) => {
    return axios
        .post("/assignees/approximate", formData)
        .then((response) => {
            return { data: response?.data, message: response.data.message, type: "success" };
        })
        .catch((error) => {
            return { data: error.response.message, message: error?.response?.data?.message, type: "error" };
        });
};
