import React from "react";
import { makeStyles, Grid, Table, TableBody, TableContainer, TableHead } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import CollapsibleRow from "./CollapsibleRow";
import TablePerPage from "./TablePerPage";

const useStyles = makeStyles((theme) => ({
    pagination: {
        margin: 20,
        "& ul": {
            justifyContent: "flex-end",
        },
    },
}));

const CollapsibleTable = (props) => {
    const classes = useStyles();
    const { head = {}, body = [] } = props;
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);

    const slicedBody = limit > 0 ? body.slice((page - 1) * limit, page * limit) : body;
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <CollapsibleRow data={head.data} config={head.config} disableCollapse />
                </TableHead>
                <TableBody>
                    {slicedBody.map((row, index) => (
                        <CollapsibleRow {...row} key={index} />
                    ))}
                </TableBody>
            </Table>
            {body.length > 10 && (
                <Grid container>
                    <Grid item xs={6}>
                        <TablePerPage limit={limit} changeLimit={(limit) => setLimit(limit)} />
                    </Grid>
                    <Grid item xs={6}>
                        <Pagination
                            className={classes.pagination}
                            count={Math.ceil(body.length / limit)}
                            onChange={(event, value) => setPage(value)}
                        />
                    </Grid>
                </Grid>
            )}
        </TableContainer>
    );
};

export default CollapsibleTable;
