import React from "react";
import axios from "../../../api/instance";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DataTable from "../../../components/Tables/DataTable/DataTable";
import AddEmployee from "./Dialogs/AddEmployee";
import RemoveEmployee from "./Dialogs/RemoveEmployee";
import ChangeEmployeeRole from "./Dialogs/ChangeEmployeeRole";

import { openDialog } from "../../../store/actions/dialogActions";
import Styles from "./styleOrganizationInfo";
import { statusColors } from "../../../shared/checkStatus";

const status = {
    ACCEPTED: "قبول شده",
    REJECTED: "رد شده",
    EXPIRED: "باطل شده",
    PENDING: "در انتظار تایید",
    NOT_REGISTERED: "ثبت نام نشده",
};

const Employees = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = Styles();
    const { title, currentGroup } = props;
    const groupId = new URLSearchParams(history.location.search).get("group_id");
    const [employees, setEmployees] = React.useState([]);
    const user = useSelector((state) => state.user);

    const tableBody = employees.map((emp, index) => ({
        data: {
            index: index + 1,
            name: emp.name ? emp.name + " " + emp.surname : "(ثبت نام نشده)",
            mobile: emp.mobile_number,
            role: emp.slug,
            status: <span style={{ color: statusColors[emp.state] }}>{status[emp.state]}</span>,
            actions: (
                <div style={{ width: "contain", display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 8 }}
                        disableElevation
                        onClick={() =>
                            dispatch(
                                openDialog({
                                    title: (
                                        <span style={{ fontSize: 16 }}>{`تغییر نقش ${emp.name} ${emp.surname}`}</span>
                                    ),
                                    content: (
                                        <ChangeEmployeeRole
                                            memberId={emp.group_member_id}
                                            memberRole={emp.role_id}
                                            isBusiness={employees[0]?.group_type === "BUSINESS"}
                                            changeRole={changeEmployeeRole}
                                        />
                                    ),
                                    maxWidth: "sm",
                                    fullWidth: true,
                                })
                            )
                        }
                    >
                        تغییر نقش
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        disableElevation
                        onClick={() =>
                            dispatch(
                                openDialog({
                                    title: "حذف کاربر",
                                    content: (
                                        <RemoveEmployee
                                            mobile={emp.mobile_number}
                                            memberId={emp.group_member_id}
                                            groupId={groupId}
                                            removeEmployee={removeEmployee}
                                        />
                                    ),
                                    maxWidth: "sm",
                                    fullWidth: true,
                                })
                            )
                        }
                    >
                        حذف
                    </Button>
                </div>
            ),
        },
        config: { className: classes.row },
    }));

    React.useEffect(() => {
        axios
            .get("/groups/get-members", { params: { group_id: groupId } })
            .then((res) => {
                setEmployees(res.data.data);
            })
            .catch((err) => {
                history.push(`/${user.active_route}/employees?group_id=${user.active_group_id}`);
            });
    }, [groupId]);

    const addNewEmpolyee = (data) => {
        let list = [...employees];
        list.push(data);
        setEmployees(list);
    };
    const removeEmployee = (data) => {
        let list = [...employees];
        list = list.filter((li) => li.group_member_id !== data);
        setEmployees(list);
    };
    const changeEmployeeRole = (employee, memberId) => {
        let list = [...employees];
        list = list.map((li) => {
            if (li.group_member_id === memberId) return employee;
            return li;
        });
        setEmployees(list);
    };

    return (
        <React.Fragment>
            <Grid container justify="space-between" alignItems="center" spacing={2} className={classes.titleContainer}>
                <Grid>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid
                    item
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() =>
                            dispatch(
                                openDialog({
                                    title: "اضافه کردن کاربر جدید",
                                    content: (
                                        <AddEmployee
                                            addNew={addNewEmpolyee}
                                            groupId={groupId}
                                            isBusiness={employees[0]?.group_type === "BUSINESS"}
                                        />
                                    ),
                                    maxWidth: "sm",
                                    fullWidth: true,
                                })
                            )
                        }
                        variant="contained"
                        color="primary"
                    >
                        اضافه کردن کاربر جدید
                    </Button>
                </Grid>
            </Grid>
            <DataTable
                head={{
                    data: {
                        index: "ردیف",
                        name: "نام و نام‌خانوادگی",
                        mobile: "شماره موبایل",
                        role: "نقش",
                        status: "وضعیت",
                        actions: "",
                    },
                    config: {
                        className: classes.head,
                    },
                }}
                body={tableBody}
                rowsPerPage={10}
            />
        </React.Fragment>
    );
};

export default Employees;
