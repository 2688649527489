import React from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardCards from "../../../components/Dashboard/Cards/DashboardCards";
import ChartsContainer from "../../../components/Charts/ChartsContainer";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import GroupsTable from "../../../components/Tables/GroupsTable/GroupsTable";

import { fetchReports, fetchCategories } from "../../../store/actions/reportsActions";
import { fetchGroups } from "../../../store/actions/groupsActions";
import { reportStatus } from "../../../shared/checkStatus.js";
import { fetchCharts } from "../../../api/userAPI";

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const [charts, setCharts] = React.useState([]);
    const { loading, reports } = useSelector((store) => store.reports);
    const { groups } = useSelector((store) => store.groups);

    React.useEffect(() => {
        dispatch(fetchReports(true));
        dispatch(fetchCategories(true));
        dispatch(fetchGroups(true));
        fetchCharts().then((response) => setCharts(response.data));
    }, []);

    return (
        <MainContainer>
            <ChartsContainer
                charts={[
                    { type: "Bar", data: charts.MonthReportChart, title: "تعداد گزارش / ماه" },
                    { type: "Bar", data: charts.StatusReportChart, title: "تعداد گزارش / وضعیت" },
                    { type: "Bar", data: charts.WageReportChart, title: "تعداد گزارش / حق الزحمه" },
                    { type: "Bar", data: charts.ProvinceReportChart, title: "تعداد گزارش / استان" },
                    { type: "Bar", data: charts.GroupReportChart, title: "تعداد گزارش / کسب و کار" },
                    { type: "Bar", data: charts.ReportTimeChart, title: "تعداد گزارش / مدت زمان پاسخ (روز)" },
                    { type: "Bar", data: charts.MeanWageExpertChart, title: "تعداد کارشناس / متوسط حق الزحمه" },
                    { type: "Bar", data: charts.TotalWageExpertChart, title: "تعداد کارشناس / مجموع حق الزحمه" },
                    { type: "Pie", data: charts.ExpertsReportChart, title: "تعداد گزارش / تعداد اعضای هیات کارشناسی" },
                ]}
            />
            <DashboardCards
                cards={[
                    {
                        title: "تکمیل شده",
                        value: reports?.filter((report) => report.status === reportStatus.COMPLETED).length,
                        color: "green",
                    },
                    {
                        title: "در حال ارجاع",
                        value: reports?.filter((report) => report.status === reportStatus.ASSIGNING).length,
                        color: "blue",
                    },
                    {
                        title: "در حال نگارش",
                        value: reports?.filter((report) => report.status === reportStatus.IN_PROGRESS).length,
                        color: "yellow",
                    },
                ]}
                loading={loading}
                showDashboardButton
            />
            <GroupsTable groups={groups} reports={reports} loading={loading} />
        </MainContainer>
    );
};

export default Dashboard;
