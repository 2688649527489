import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        width: "100%",
        bottom: 0,
        left: 0,
        padding: theme.spacing(0, 4),
    },
}));

export default useStyles;
