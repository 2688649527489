const validatForm = (controls) => {
    let updatedControls = { ...controls };
    let errorFlag = false;

    for (let key in updatedControls) {
        if (updatedControls[key].hide) continue;
        for (let validateKey in updatedControls[key]?.validation) {
            let helperText = "";

            switch (validateKey) {
                case "required":
                    if (
                        updatedControls[key].value == "" ||
                        updatedControls[key].value == [] ||
                        updatedControls[key].value == null
                    ) {
                        helperText = "مقدار ورودی نمیتواند خالی باشد";
                    }
                    break;
                case "minLength":
                    if (
                        updatedControls[key].value &&
                        updatedControls[key]?.value.length < updatedControls[key].validation[validateKey]
                    ) {
                        helperText = `مقدار ورودی باید حداقل ${updatedControls[key].validation[validateKey]} کاراکتر باشد`;
                    }
                    break;
                case "length":
                    if (
                        updatedControls[key].value &&
                        updatedControls[key]?.value?.length !== updatedControls[key].validation[validateKey]
                    ) {
                        helperText = `مقدار ورودی باید ${updatedControls[key].validation[validateKey]} رقم باشد`;
                    }
                    break;
                case "pattern":
                    if (
                        updatedControls[key].value &&
                        !updatedControls[key].validation[validateKey].test(updatedControls[key].value)
                    ) {
                        helperText = "فرمت ورودی نادرست است";
                    }
                    break;
                case "custom": {
                    if (updatedControls[key].validation[validateKey](updatedControls[key].value) === false) {
                        helperText = updatedControls[key].validation?.helperText ?? "فرمت ورودی نادرست است";
                    }
                }
                default:
                    break;
            }

            if (helperText) {
                errorFlag = true;
                updatedControls[key].elementConfig["error"] = true;
                updatedControls[key].elementConfig["helperText"] = helperText;
                break;
            } else {
                updatedControls[key].elementConfig["error"] = false;
                updatedControls[key].elementConfig["helperText"] =
                    updatedControls[key].elementConfig["helperText"] ?? "";
            }
        }
    }

    return { data: updatedControls, error: errorFlag };
};

export default validatForm;
