import React from "react";
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CheckBoxBlank from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBox from "@material-ui/icons/CheckBoxOutlined";
import { VisibilityOutlined } from "@material-ui/icons";

import DataTable from "../../../components/Tables/DataTable/DataTable";
import ActionDialog from "../../../components/Dialogs/ActionDialog";
import FilePreviewDialog from "../../../components/Dialogs/FilePreviewDialog";
import DownloadIcon from "@material-ui/icons/GetAppOutlined";
import ExpertInfoTable from "../../../components/Tables/ExpertInfoTable/ExpertInfoTable";
import { showAlert } from "../../../store/actions/snackbarActions";
import { revokeAssigneeRole } from "../../../api/assigneesAPI";

import Styles from "../../Admin/ReportDetails/styleReportDetails";
import { Avatar, Button } from "@material-ui/core";

const AssigneesTable = (props) => {
    const dispatch = useDispatch();
    const classes = Styles();
    const [dialog, setDialog] = React.useState({ open: false, data: [] });
    const { assignees, assignee, changeRole } = props;

    const closeDialog = () => setDialog((state) => ({ open: false, data: state.data }));
    const isHead = (item) => item.assignee_role_id == "HEAD";

    const changeAssigneeRole = (assignee_id, role_id, role_slug) => {
        revokeAssigneeRole({ assignee_id, role_id }).then((response) => {
            if (response.type === "success") changeRole(assignee_id, role_id, role_slug);
            dispatch(showAlert(response.message, response.type));
        });
    };

    const tableBody = assignees.map((item) => {
        let config = { className: classes.row, align: "center" };

        const isWriter = item.assignee_role_id === "SECRETARY";
        const isItemHead = isHead(item);
        if (item.id === assignee.id) config.style = { fontWeight: "bold" };
        if (item.response?.documents)
            item.downloadResponse = (
                <Button
                    className={classes.seeMore}
                    onClick={() =>
                        setDialog({
                            open: "responses",
                            data: item,
                        })
                    }
                >
                    مشاهده
                </Button>
            );
        item.viewExpertInfo = (
            <Button
                className={classes.seeMore}
                onClick={() =>
                    setDialog({
                        open: "expertDetails",
                        data: item,
                    })
                }
            >
                مشاهده
            </Button>
        );
        let updatedData = {
            avatar: <Avatar src={item.avatar_link} />,
            name: item.name,
            surname: item.surname,
            assignee_role: item.assignee_role,
            downloadResponse: item.downloadResponse,
            viewExpertInfo: item.viewExpertInfo,
        };
        if (isHead(assignee)) {
            updatedData.setWriter = (
                <IconButton
                    disabled={isWriter || isItemHead}
                    onClick={() => changeAssigneeRole(item.id, "SECRETARY", "دبیر")}
                >
                    {isWriter ? <CheckBox /> : <CheckBoxBlank />}
                </IconButton>
            );
        }

        return {
            data: updatedData,
            config,
        };
    });
    let headData = {
        avatar: "",
        name: "نام",
        surname: "نام خانوادگی",
        assignee_role: "سمت",
        downloadResponse: "پاسخ گزارش",
        viewExpertInfo: "مشاهده جزئیات",
    };
    if (isHead(assignee)) headData.setWriter = "انتخاب دبیر";

    return (
        <>
            <FilePreviewDialog
                title={`پاسخ‌های ارسالی از ${dialog.data.name} ${dialog.data.surname}`}
                open={dialog.open === "responses"}
                onClose={closeDialog}
                files={dialog?.data?.response?.documents ?? []}
            />
            <ActionDialog open={dialog.open === "expertDetails"} onClose={closeDialog} maxWidth="md" fullWidth>
                <ExpertInfoTable expert={dialog.data} />
            </ActionDialog>
            <DataTable
                head={{
                    data: headData,
                    config: {
                        className: classes.head,
                        align: "center",
                    },
                }}
                body={tableBody}
            />
        </>
    );
};

export default AssigneesTable;
