import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    titleContainer: {
        backgroundColor: "#fff",
        borderRadius: 5,
        padding: theme.spacing(2),
        marginTop: 15,
        "& h6": { fontSize: 16, fontWeight: 600 },
    },
    head: {
        backgroundColor: "#F9FAFC",
        color: "#A3A9B7",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        borderWidth: 0,
    },
    row: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        marginTop: 30,
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        borderWidth: 2,
    },
}));
