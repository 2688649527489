import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    root: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        position: "absolute",
        top: 0,
        left: 0
    },
    title: {
        textAlign: "center",
        marginBottom: 10,
        '& p': {
            fontSize: 20
        }
    },
    buttons: {
        textAlign: "center"
    }
});

const SupportPage = ({errorCode, goToDashboardLink}) => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <BugReportOutlinedIcon fontSize="large"/>
                <Typography>خطایی در سامانه رخ داد</Typography>
            </div>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                کد خطا: {errorCode}
            </Typography>
            <div className={styles.buttons}>
                <Button variant="outlined" color="primary" style={{margin: 5}} onClick={goToDashboardLink}>
                    بازگشت به صفحه‌ی اصلی
                </Button>
                <Button variant="outlined" color="primary" style={{margin: 5}} component="a"
                        href="https://support.edalatkhane.org" target="_blank">
                    تماس با پشتیبانی
                </Button>
            </div>
        </div>
    );
};

export default SupportPage;
