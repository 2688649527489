import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";

import Curve from "../../../assets/images/Curve.svg";
import { CardMedia } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles({
    root: {
        borderRadius: 20,
        boxShadow: "0 5px 8px 4px #0000000a",
        margin: 10,
    },
    customersHeader: {
        backgroundImage: `url('${Curve}')`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        height: 120,
    },
    titleContainer: {
        position: "absolute",
        top: -85,
        width: "100%",
        right: 0,
    },
    customersFooter: {
        backgroundImage: `url('${Curve}')`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        height: 165,
        transform: "rotate(180deg)",
    },
    customersTitle: {
        fontFamily: "IRANSansBold",
        fontSize: 20,
        textAlign: "center",
    },
    customersSubtitle: {
        fontSize: 15,
        textAlign: "center",
    },
    cardLogo: {
        margin: "15px 15px",
    },
});

const MyCustomers = ({ customers }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <>
            <Grid container className={classes.customersHeader}></Grid>
            <Grid
                container
                style={
                    isMobile
                        ? { padding: "0 2%", backgroundColor: "#e9eaf0", position: "relative" }
                        : { padding: "0 12%", backgroundColor: "#e9eaf0", position: "relative" }
                }
            >
                <Grid item xs={12} className={classes.titleContainer}>
                    <Typography component="h2" className={classes.customersTitle}>
                        مشتریان ما
                    </Typography>
                    <Typography component="p" className={classes.customersSubtitle}>
                        که افتخار همکاری با آنها را داشته ایم
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container style={{ display: "flex", justifyContent: "center" }}>
                        {customers.map((customer) => (
                            <Grid item md={2} xs={4}>
                                <Card className={classes.root}>
                                    <CardActionArea>
                                        <CardMedia className={classes.cardLogo}>
                                            <img src={customer.logo_path} style={{ width: "100%" }} />
                                        </CardMedia>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.customersFooter}></Grid>
        </>
    );
};

export default MyCustomers;
