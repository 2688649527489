import React from "react";
import jMoment from "moment-jalaali";
import JalaliUtils from "@date-io/jalaali";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton, InputAdornment } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import ArrowDown from "@material-ui/icons/ArrowDropDown";
import ClearIcon from "@material-ui/icons/Clear";

jMoment.loadPersian({ dialect: "persian-modern" });

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
}));

const CustomDatePicker = (props) => {
    const classes = useStyles();
    const { value, onChange, label, views, ...otherProps } = props;

    const dateFormat = (date) => {
        if (date) {
            if (views && views[0] === "year" && views.length === 1) return date.format("jYYYY");
            return date.format("jYYYY/jMM/jDD");
        }
        return "";
    };

    return (
        <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
            <DatePicker
                autoOk
                {...otherProps}
                label={label}
                value={value}
                onChange={onChange}
                className={classes.root}
                views={views !== undefined ? views : undefined}
                variant="inline"
                maxDateMessage=""
                minDateMessage=""
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {value && (
                                <IconButton size="small" onClick={() => onChange(null)}>
                                    <ClearIcon style={{ fontSize: 20 }} />
                                </IconButton>
                            )}
                            <IconButton size="small">
                                <ArrowDown />
                            </IconButton>
                        </InputAdornment>
                    ),
                    error: otherProps.error,
                }}
                helperText={otherProps.helperText}
                labelFunc={(date) => dateFormat(date)}
                disableToolbar
            />
        </MuiPickersUtilsProvider>
    );
};

export default CustomDatePicker;
