import React from "react";
import { NavLink } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText, IconButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    listIcon: {
        "& svg": {
            fontSize: 22,
        },
    },
    listText: {
        marginLeft: -20,
        "& span": {
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 500,
        },
    },
}));

const MenuItem = (props) => {
    const classes = useStyles();
    const { open, link, active, onClick } = props;
    const Icon = props.icon;

    let childProps = {};
    if (link) {
        childProps.component = NavLink;
        childProps.to = link;
    } else {
        childProps.onClick = onClick;
    }

    if (!open && Icon)
        return (
            <IconButton {...childProps} activeClassName={active} color="inherit" className={classes.listIcon}>
                <Icon style={{ fontSize: 24 }} />
            </IconButton>
        );
    return (
        <ListItem button {...childProps} activeClassName={active} onClick={onClick}>
            {Icon && (
                <ListItemIcon className={classes.listIcon}>
                    <Icon />
                </ListItemIcon>
            )}
            <ListItemText className={classes.listText}>{props.label}</ListItemText>
        </ListItem>
    );
};

export default MenuItem;
