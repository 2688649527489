import { TOGGLE_NOTIFICATIONS, SET_NOTIFICATIONS } from "../actions/notificationActions";

const initialState = {
    notifications: [],
    drawerOpen: false,
    loading: false,
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_NOTIFICATIONS:
            return { ...state, drawerOpen: action.value ?? !state.drawerOpen };
        case SET_NOTIFICATIONS:
            return { ...state, notifications: action.value };
        default:
            return state;
    }
};

export default notificationReducer;
