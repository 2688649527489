import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import sampleImg from "../../../assets/images/lawImage.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import IntroDescription from "../IntroDescription/IntroDescription";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    container: {
        width: "100%",
        margin: 0,
        position: "relative",
        padding: "0% 12%",
    },
    mobileContainer: {
        width: "100%",
        margin: 0,
        position: "relative",
    },
    root: {
        maxWidth: "100%",
        borderRadius: 20,
        marginLeft: 10,
        marginRight: 10,
        boxShadow: "0 5px 8px 4px #0000000a",
    },
    cardTitle: {
        fontSize: 13,
        margin: 15,
        fontFamily: "IRANSansBold",
        color: "#5c5f67",
    },
    cardDescription: {
        fontSize: 10,
    },
    cartContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: 140,
        marginTop: 20,
    },
});

const Attribute = ({ cards }) => {
    const hompage = useSelector((state) => state.hompage);
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <Grid container className={isMobile ? classes.mobileContainer : classes.container}>
            {isMobile && <IntroDescription type="Expert" />}
            {cards?.map((attr, key) => {
                return (
                    <Grid item md={4} sm={4} xs={key === 2 ? 12 : 6} style={{ marginTop: 20 }}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardContent className={classes.cartContent}>
                                    <img src={sampleImg} style={{ width: 40 }} />
                                    <Typography gutterBottom variant="h5" component="h5" className={classes.cardTitle}>
                                        {attr.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        className={classes.cardDescription}
                                    >
                                        {attr.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default Attribute;
