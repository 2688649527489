import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    filterHeader: {
        height: 45,
        margin: theme.spacing(2, 0),
    },
    title: {
        fontSize: 16,
        color: "rgba(0,0,0,0.85)",
    },
    toolbar: {
        color: "#5c5f66",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        minHeight: 50,
        fontWeight: 500,
    },
    sideFilter: {
        height: "100%",
        backgroundColor: "#FAFAFA",
        borderRadius: 8,
        boxShadow: "0px 0px 20px rgba(215, 222, 227, 0.4)",
        display: "flex",
        flexDirection: "column",
    },
    mobileContainer: {
        backgroundColor: "rgba(237, 239, 245, 255)",
        marginBottom: 20,
    },
    filterContainer: {
        backgroundColor: "rgba(237, 239, 245, 255)",
        marginBottom: 50,
    },
    filterButton: {
        width: "100%",
        borderRadius: 8,
        fontWeight: 600,
        marginTop: theme.spacing(1),
        maxWidth: 400,
    },
}));
