import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typography, Grid, Button, Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import locale from "../../../../shared/locale";
import { acceptOrRejectRole, enterRole } from "../../../../store/actions/rolesActions";
const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 8,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    header: {
        padding: theme.spacing(0.8, 0.5),
        backgroundColor: "#2B2C33",
        "& span": {
            fontSize: theme.typography.pxToRem(14),
            color: "#A4A6B3",
            textAlign: "center",
        },
    },
    content: {
        backgroundColor: "#363740",
        color: "#fff",
        padding: `${theme.spacing(2)} !important`,
        "& span": {
            fontSize: theme.typography.pxToRem(14),
        },
    },
    actionContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    checkButton: {
        display: "flex",
        marginTop: 10,
        "& > span": { flexDirection: "column" },
        "& svg": {
            border: "2px solid",
            borderRadius: 6,
            backgroundColor: "rgba(3, 222, 115, 0.2)",
        },
        "& span": {
            fontSize: theme.typography.pxToRem(12),
            width: "100%",
        },
    },
    greenButton: {
        color: "#03DE73",
        "& svg": {
            backgroundColor: "rgba(3, 222, 115, 0.2)",
        },
    },
    redButton: {
        color: "#FF4646",
        "& svg": {
            backgroundColor: "rgba(255, 70, 70, 0.2)",
        },
    },
    enterButton: {
        width: 90,
        marginTop: 10,
    },
    rejectedText: {
        color: "#FF4646",
        fontWeight: 600,
    },
}));
const OrganizationCard = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { slug = "", group_name = "", group_type = "", state = "PENDING" } = props.role;
    const { onClose } = props;

    const getTypo = (title, content) => (
        <div style={{ marginTop: 5 }}>
            <Typography component="span">{title}: </Typography>
            <Typography component="span">{content}</Typography>
        </div>
    );

    let actionNode = "";
    switch (state) {
        case "PENDING":
            actionNode = (
                <>
                    <Button
                        className={clsx(classes.checkButton, classes.greenButton)}
                        onClick={() => dispatch(acceptOrRejectRole(props.role, 1))}
                    >
                        <CheckIcon color="inherit" />
                        <span>قبول نقش</span>
                    </Button>
                    <Button
                        className={clsx(classes.checkButton, classes.redButton)}
                        onClick={() => dispatch(acceptOrRejectRole(props.role, 0))}
                    >
                        <CloseIcon color="inherit" />
                        <span>رد نقش</span>
                    </Button>
                </>
            );
            break;
        case "ACCEPTED":
            actionNode = (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        size="small"
                        className={classes.enterButton}
                        onClick={() => {
                            dispatch(enterRole(props.role.role_id, props.role.group_id));
                            onClose();
                            history.push("/");
                        }}
                    >
                        ورود
                    </Button>
                </>
            );
            break;
        case "REJECTED":
            actionNode = (
                <Typography variant="caption" className={classes.rejectedText}>
                    رد شده
                </Typography>
            );
        default:
            break;
    }

    return (
        <Card className={classes.card}>
            <CardHeader title={group_name} className={classes.header} />
            <CardContent style={{ textAlign: "right" }} className={classes.content}>
                <Grid container>
                    <Grid item xs={6}>
                        {getTypo("نقش", slug)}
                        {getTypo("نوع سازمان", locale(group_type))}
                    </Grid>
                    <Grid item xs={6} className={classes.actionContainer}>
                        {actionNode}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default OrganizationCard;
