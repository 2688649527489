import React from "react";
import { useHistory } from "react-router";
import axios from "../../../api/instance";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Button } from "@material-ui/core";

import LoadingButton from "../../../components/UI/Buttons/LoadingButton";

import { showAlert, TYPES } from "../../../store/actions/snackbarActions";
import { updateUserInformation } from "../../../store/actions/userActions";
import { editProfile } from "../../../api/userAPI";
import formCreator from "../../../shared/formCreator";
import validateForm from "../../../shared/validateForm";
import publicFormData from "./publicFormData";
import useStyles from "../profileStyles";

import { logout } from "../../../store/actions/userActions.js";

const PublicInfo = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user);
    const provinces = useSelector((store) => store.geo);
    const [loading, setLoading] = React.useState(false);
    const [checkForm, setCheckForm] = React.useState(false);

    const province = provinces.find((item) => item.id === user.province_id);
    const town = province?.towns.find((item) => item.id === user.town_id);
    const city = town?.cities.find((item) => item.id === user.city_id);
    const [formData, setFormData] = React.useState({
        values: {
            name: user.name,
            surname: user.surname,
            national_id: user.national_id,
            mobile_number: user.mobile_number,
            bank_name: user.bank_name,
            IBAN: user.IBAN,
            province_id: province?.id,
            town_id: town?.id,
            city_id: city?.id,
        },
        disabled: {
            national_id: user.licenses.find((item) => item.issued_date !== null),
        },
    });

    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedForm = { ...formData };
        updatedForm.values[inputIdentifier] = value;
        setFormData(updatedForm);
    };

    const submitForm = (event) => {
        event.preventDefault();
        const validatedForm = validateForm(publicFormData(formData, classes));
        let data = {};
        setCheckForm(true);
        for (let key in formData.values) {
            if (formData.values[key]?.id) data[key] = formData.values[key].id;
            else data[key] = formData.values[key];
        }
        if (validatedForm.error === false) {
            setLoading(true);
            editProfile(data).then((response) => {
                dispatch(showAlert(response.message, response.type));
                dispatch(updateUserInformation(response.data));
                setLoading(false);
            });
        }
    };

    let formElements = formCreator(publicFormData(formData, user), inputChangedHandler);
    if (checkForm) {
        formElements = formCreator(validateForm(publicFormData(formData, user)).data, inputChangedHandler);
    }

    return (
        <Paper component="form" onSubmit={submitForm} className={classes.paper}>
            <Grid container spacing={4} className={classes.containerFixer}>
                {formElements}
                <Button
                    color="secondary"
                    variant="contained"
                    className={classes.logoutButton}
                    onClick={() => {
                        axios
                            .post("/auth/logout")
                            .then((response) => {
                                dispatch(logout());
                                history.push("/");
                            })
                            .catch((error) => {});
                    }}
                >
                    خروج از حساب کاربری
                </Button>
                <LoadingButton
                    disabled={loading}
                    variant="contained"
                    type="submit"
                    loading={loading}
                    className={classes.saveButton}
                >
                    ذخیره تغییرات
                </LoadingButton>
            </Grid>
        </Paper>
    );
};

export default PublicInfo;
