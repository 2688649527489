import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Grid, Container, Typography, Avatar } from "@material-ui/core";

import MainContainer from "../../components/UI/Containers/MainContainer";
import ClipboardField from "../../components/UI/Fields/ClipboardField";
import LinearLoading from "../../components/Static/LinearLoading/LinearLoading";
import WageTable from "./WageTable";

import { getInvoice } from "../../api/calculatorAPI";
import { showAlert } from "../../store/actions/snackbarActions";
import currencySeprator from "../../shared/currencySeprator";
import articleInfo from "./Components/articleInfo";
import Styles from "./wageStyles";

import BlackLogo from "../../assets/images/logoblack.png";
import BlackTypo from "../../assets/images/typoblack.png";
const WageFactor = (props) => {
    const classes = Styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [relatedArticles, setRelatedArticles] = React.useState([]);
    const [invoice, setInvoice] = React.useState([]);
    const trackingCode = new URLSearchParams(history.location.search).get("tracking_code");

    React.useEffect(() => {
        setLoading(true);
        getInvoice(trackingCode).then((response) => {
            setLoading(false);

            if (response.type === "error") {
                dispatch(showAlert(response.message, "error"));
                history.push("/wagecalculator");
                return;
            }
            setRelatedArticles(response.data.related_attributes);
            setInvoice(
                response.data.invoice_attributes.map((item) => {
                    let amount = item.amount;
                    if (item.related_attributes?.length > 0)
                        amount += item.related_attributes.reduce((a, b) => {
                            return a + parseInt(b.amount);
                        }, 0);

                    return {
                        text: (
                            <React.Fragment>
                                {item.title}
                                <br />
                                {articleInfo(item) + item.name}
                                {item.user_input && (
                                    <Container className={classes.userInputContainer}>
                                        <Typography>{item.title}</Typography>
                                        <Typography>{currencySeprator(item.user_input)}</Typography>
                                        <div style={{ width: "100%", height: 10 }}></div>
                                        <Typography>حق‌الزحمه</Typography>
                                        <Typography>{`${currencySeprator(item.amount)} +`}</Typography>
                                    </Container>
                                )}
                                {item.related_attributes.map((relatedAttribute, index) => (
                                    <Container key={index} className={classes.userInputContainer}>
                                        <Typography variant="caption">{relatedAttribute.name}</Typography>
                                        <Typography></Typography>
                                        <Typography>
                                            {relatedAttribute.amount > 0
                                                ? `${currencySeprator(relatedAttribute.amount)} +`
                                                : `${currencySeprator(Math.abs(relatedAttribute.amount))} -`}
                                        </Typography>
                                    </Container>
                                ))}
                            </React.Fragment>
                        ),
                        price: `${currencySeprator(amount)} +`,
                        ...item,
                        amount: amount,
                    };
                })
            );
        });
    }, [classes.userInputContainer, trackingCode]);

    if (loading === true) {
        return (
            <MainContainer>
                <LinearLoading width={200} title="در حال بارگیری" />
            </MainContainer>
        );
    }
    return (
        <MainContainer style={{ marginTop: 10 }}>
            <Grid container justify="center">
                <Grid item xs={12} md={9} lg={8} component="form">
                    <Container className={classes.codeConatiner}>
                        <Avatar src={BlackTypo} alt="Logo" variant="square" className={classes.LogoImg} />
                        <Avatar src={BlackLogo} alt="Logo" variant="square" className={classes.LogoImg} />
                        <ClipboardField
                            containerProps={{
                                style: {
                                    border: "2px solid #a7a7a7",
                                },
                            }}
                            textProps={{
                                style: {
                                    fontWeight: 600,
                                    fontFamily: "sans-serif",
                                    color: "rgba(0,0,0,0.7)",
                                    fontSize: 14,
                                    marginRight: 4,
                                },
                            }}
                            iconProps={{
                                style: {
                                    fontSize: "1.25em",
                                    color: "rgba(0,0,0,0.7)",
                                },
                            }}
                            text={trackingCode}
                        />
                    </Container>
                    <WageTable rows={invoice.filter((item) => item.amount != 0)} />
                    <Container className={classes.resultContainer}>
                        <Typography>مجموع حق‌الزحمه</Typography>
                        <Typography>
                            {currencySeprator(
                                invoice.reduce((a, b) => {
                                    return a + parseInt(b.amount);
                                }, 0)
                            )}{" "}
                            تومان
                        </Typography>
                    </Container>
                    {relatedArticles.length > 0 && (
                        <Container className={classes.container}>
                            <Typography variant="body1" className={classes.articlesTitle}>
                                مواد مرتبط
                            </Typography>
                            <Container className={classes.inputContainer}>
                                {relatedArticles.map((attribute, index) => (
                                    <Typography variant="body2" key={index} gutterBottom className={classes.message}>
                                        {articleInfo(attribute)}
                                        {attribute.name}
                                    </Typography>
                                ))}
                            </Container>
                        </Container>
                    )}
                </Grid>
            </Grid>
        </MainContainer>
    );
};

export default WageFactor;
