import React from "react";
import { Grid, InputAdornment, TextField } from "@material-ui/core";

import DataTable from "../../../../components/Tables/DataTable/DataTable";
import ChipTypo from "../../../../components/UI/Typography/ChipTypo";
import FilePreview from "../../../../components/UI/Image/FilePreview";
import PriceField from "../../../../components/UI/Fields/PriceField";

const SetWageEstimation = (props) => {
    const { assignees, documents, setWageForm } = props;
    const [formData, setFormData] = React.useState({
        estimation: "",
        wages: {},
    });

    const acceptedAssignees = assignees.filter((item) => item.assignee_role_id !== "SUPERVISOR");
    React.useEffect(() => {
        let wages = {};
        let estimation = false;
        acceptedAssignees.forEach((item) => {
            wages[item.id] = item.wage ?? "";
            if (item.estimations_date) estimation = true;
        });
        setFormData({
            estimation: assignees.find((item) => item.estimation)?.estimation ?? "",
            wages: wages,
        });
    }, []);
    React.useEffect(() => {
        let isCorrect = true;
        if (!formData.estimation) isCorrect = false;
        for (let key in formData.wages) if (!formData.wages[key]) isCorrect = false;

        if (isCorrect) setWageForm(formData);
        else setWageForm(null);
    }, [formData]);

    const changeHandler = (val, name) => {
        setFormData((state) => ({ ...state, [name]: val }));
    };

    return (
        <>
            <PriceField
                label="برآورد"
                value={formData.estimation}
                fullWidth
                error={!formData.estimation}
                helperText={!formData.estimation && "مقدار ورودی نمیتواند خالی باشد"}
                onChange={(val) => changeHandler(val, "estimation")}
            />
            {assignees && (
                <DataTable
                    head={{
                        data: {
                            name: "نام",
                            surname: "نام خانوادگی",
                            wage: "حق‌الزحمه",
                        },
                    }}
                    body={acceptedAssignees.map((assignee) => ({
                        data: {
                            name: assignee.name,
                            surname: assignee.surname,
                            wage: (
                                <PriceField
                                    fullWidth
                                    name={assignee.id}
                                    onChange={(value) =>
                                        changeHandler({ ...formData.wages, [assignee.id]: value }, "wages")
                                    }
                                    value={formData.wages[assignee.id]}
                                    error={!formData.wages[assignee.id]}
                                    helperText={!formData.wages[assignee.id] && "مقدار ورودی نمیتواند خالی باشد"}
                                />
                            ),
                        },
                    }))}
                    rowsPerPage={0}
                />
            )}

            {documents && (
                <React.Fragment>
                    <ChipTypo tableMargin>اسناد</ChipTypo>
                    <Grid container spacing={1} style={{ marginTop: 10 }}>
                        {documents?.map((file, index) => {
                            return (
                                <Grid item xs={6} sm={4} lg={3} key={index}>
                                    <FilePreview
                                        src={file.link}
                                        label={file.name}
                                        onClick={() => window.open(file.link)}
                                        type={file.mime_type}
                                        icon="download"
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </React.Fragment>
            )}
        </>
    );
};

export default SetWageEstimation;
