import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "left",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5, 1.2),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const VerticalStepper = (props) => {
  const classes = useStyles();
  const { steps } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        style={{ padding: 0 }}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step.title}</StepLabel>
            <StepContent>
              {step.content}
              {step.action && (
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      مرحله قبل
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      {...step.action?.next}
                      onClick={() => {
                        handleNext();
                        if (step.action?.next?.onClick)
                          step.action.next.onClick();
                      }}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "پایان" : "مرحله بعد"}
                    </Button>
                  </div>
                </div>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default VerticalStepper;
