import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";

import UploadCard from "../../Surfaces/Cards/UploadCard";
import FilePreview from "../Image/FilePreview";
import { showAlert } from "../../../store/actions/snackbarActions";

const useStyles = makeStyles((theme) => ({
    previewContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        marginTop: 2,
    },
}));

const FileUploader = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { onChange, onRemove, uploadedFiles, helperText, label, maxFilesCount } = props;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <UploadCard
                    label={label ?? "افزودن اسناد"}
                    multiple
                    onChange={(event) => {
                        for (let i = 0; i < event.target.files.length; ++i) {
                            const file = event.target.files[i];
                            if (file.size > 5242880) {
                                dispatch(showAlert(`حجم فایل ورودی بیشتر از ۵ مگابایت است`, "error"));
                                return;
                            }
                        }
                        if (uploadedFiles.length + event.target.files.length <= maxFilesCount) onChange(event);
                        else dispatch(showAlert(`حداکثر تعداد فایل ${maxFilesCount} است`, "error"));
                    }}
                />
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    style={{ textAlign: "right", marginTop: 5 }}
                >
                    {helperText}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={9} className={classes.previewContainer}>
                <Grid container spacing={2}>
                    {uploadedFiles?.map((file, index) => {
                        return (
                            <Grid item xs={4} sm={3} md={2} key={file.id}>
                                <FilePreview
                                    src={file.link}
                                    label={file.name}
                                    onClick={() => onRemove(index)}
                                    type={file.mime_type}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FileUploader;
