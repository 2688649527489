import React from "react";
import { makeStyles } from "@material-ui/core";

import TableRadiusBox from "../../UI/Containers/TableRadiusBox";
import DataTable from "../DataTable/DataTable";

const useStyles = makeStyles((theme) => ({
    container: {},
    head: {
        color: theme.palette.table.main,
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#DEDEDE",
        border: "1px solid #DEDEDE",
        padding: theme.spacing(1, 2),
    },
    row: {
        color: theme.palette.table.main,
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        border: "1px solid #DEDEDE",
        width: "50%",
    },
}));

const ExpertInfoTable = (props) => {
    const { expert, append = [] } = props;
    const classes = useStyles();

    return (
        <TableRadiusBox>
            <DataTable
                body={[
                    {
                        data: { first: "اطلاعات کارشناس" },
                        config: {
                            className: classes.head,
                            colspan: "2",
                        },
                    },
                    {
                        data: {
                            first: `نام: ${expert.name ?? "____"}`,
                            second: `نام خانوادگی: ${expert.surname ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    {
                        data: {
                            first: `شماره تماس: ${expert.mobile_number ?? "____"}`,
                            second: `ایمیل: ${expert.email_address ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    {
                        data: {
                            first: `آدرس: ${expert.licenses[0].address ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                            colspan: "2",
                        },
                    },
                    ...append.map((element, index) => ({
                        data: { first: element },
                        config: { className: classes.row, colspan: "2" },
                    })),
                ]}
            />
        </TableRadiusBox>
    );
};

export default ExpertInfoTable;
