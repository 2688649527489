export const changeCurrentAssigneeData = (assignees, assignee, data) => {
    const data_keys = Object.keys(data);
    return assignees.map((item) => {
        if (item.id === assignee.id)
            data_keys.forEach((key) => {
                item[key] = data[key];
            });
        return item;
    });
};
export const changeAssigneeRole = (assignees, id, role_id, role_slug, append = false) => {
    return assignees.map((item) => {
        if (!append && item.assignee_role_id == role_id) {
            item.assignee_role_id = "EXPERT";
            item.assignee_role = "کارشناس";
        }
        if (item.id === id) {
            item.assignee_role_id = role_id;
            item.assignee_role = role_slug;
        }
        return item;
    });
};
