import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "justify",
        borderRadius: 6,
        margin: theme.spacing(2, 0),
        "& p": {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    title: {
        fontSize: theme.typography.pxToRem(14),
    },
}));
const FullWidthAlert = ({ type = "info", title, children }) => {
    const classes = useStyles();
    return (
        <Alert severity={type} className={classes.root}>
            <AlertTitle className={classes.title}>{title}</AlertTitle>
            {children}
        </Alert>
    );
};

export default FullWidthAlert;
