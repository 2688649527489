import React from "react";
import { Container, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import SearchListInput from "../../../components/UI/Input/SearchListInput";
import SubmitInput from "../../../components/UI/Input/SubmitInput";

import { getGroups, getFields, getInvoice } from "../../../api/calculatorAPI";

import Styles from "../wageStyles";
import { showAlert } from "../../../store/actions/snackbarActions";

const CalculatorHeader = (props) => {
    const history = useHistory();
    const classes = Styles();
    const { getAttributes, setAttributes, setCalculatorFormData } = props;

    const [fields, setFields] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [formData, setFormData] = React.useState({ group_id: { name: "همه گروه‌ها", value: 0 } });

    React.useEffect(() => {
        getGroups().then((response) => {
            const updatedResponse = response.map((item) => ({
                name: item.name,
                value: item.id,
            }));
            setGroups([{ name: "همه گروه‌ها", value: 0 }, ...updatedResponse]);
        });
        getFields().then((response) => {
            const updatedResponse = response.map((item) => ({
                name: item.field,
                value: item.id,
                group_id: item.group_id,
            }));
            setFields(updatedResponse);
        });
    }, []);
    React.useEffect(() => {
        if (formData.field_id?.group_id != formData.group_id?.value) inputChangedHandler(null, "field_id");
    }, [formData.group_id]);
    React.useEffect(() => {
        if (formData.field_id?.value == null) {
            setAttributes({});
        }
    }, [formData.field_id]);

    const handleRedirect = (trackCode) => {
        history.push(`/wagefactor?tracking_code=${trackCode}`);
    };
    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedForm = { ...formData };
        updatedForm[inputIdentifier] = value;
        setFormData(updatedForm);
    };
    const fillForm = (trackingCode) => {
        getInvoice(trackingCode).then((response) => {
            let updatedFormData = { ...formData };
            const groupId = groups.find((group) => group.name === response.data.invoice_attributes[0].group_name);
            const fieldId = fields.find((field) => field.name === response.data.invoice_attributes[0].field_name);
            updatedFormData["group_id"] = groupId;
            updatedFormData["field_id"] = fieldId;
            setFormData(updatedFormData);
            getAttributes(updatedFormData["field_id"]);

            let updatedForm = { field_id: fieldId.value };
            response.data.invoice_attributes.forEach((element) => {
                updatedForm[element.attribute_key] = element.user_input;
                element.related_attributes.forEach((item) => {
                    let newValue = Array.isArray(formData[item.attribute_key])
                        ? [...formData[item.attribute_key], item.related_attribute_key]
                        : [item.related_attribute_key];
                    updatedForm[item.attribute_key] = newValue;
                });
            });
            setCalculatorFormData(updatedForm);
        });
    };

    return (
        <React.Fragment>
            <Container
                className={classes.container}
                component="form"
                onSubmit={() => handleRedirect(formData.track_code)}
            >
                <SubmitInput
                    label="کد رهگیری"
                    className={classes.priceInput}
                    fullWidth
                    helperText=" برای مشاهده فاکتوری که قبلا ساخته اید، کد رهگیری خود را در کادر بالا وارد کرده و دکمه ثبت را بزنید."
                    inputProps={{
                        value: formData.track_code,
                        onChange: (event) => inputChangedHandler(event.target.value, "track_code"),
                    }}
                    buttonProps={{
                        label: "ثبت",
                        className: classes.priceAction,
                        type: "submit",
                        disabled: !formData.track_code,
                        onClick: () => handleRedirect(formData.track_code),
                    }}
                />
                <Button
                    className={classes.fillButton}
                    disabled={!formData.track_code}
                    onClick={() => fillForm(formData.track_code)}
                >
                    پر کردن فرم
                </Button>
            </Container>
            <Container className={classes.container} style={{ position: "sticky", top: 0, zIndex: 30 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <SearchListInput
                            fullWidth
                            value={formData.group_id}
                            onChange={(value) => inputChangedHandler(value, "group_id")}
                            label="گروه"
                            options={groups}
                            inputParams={{ variant: "outlined" }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <SearchListInput
                            fullWidth
                            value={formData.field_id}
                            onChange={(value) => {
                                inputChangedHandler(value, "field_id");
                                getAttributes(value);
                            }}
                            label="رشته"
                            options={fields.filter(
                                (item) => item.group_id == formData.group_id?.value || formData.group_id?.value == 0
                            )}
                            inputParams={{ variant: "outlined" }}
                        />
                    </Grid>
                    {props.children}
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default CalculatorHeader;
