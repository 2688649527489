import { dateFixer } from "./toEnNumber";

const getFormData = (object, mapper) => {
    const formData = new FormData();

    for (let key in object) {
        if (object[key] === undefined) continue;
        if (object[key]?._isAMomentObject) {
            formData.append(key, dateFixer(object[key]));
            continue;
        }
        if (typeof object[key] === "object" && object[key] !== null && !(object[key] instanceof File)) {
            if (Array.isArray(object[key])) {
                object[key].forEach((item) => formData.append(`${key}[]`, item));
            } else {
                for (const objectKey in object[key]) {
                    formData.append(key + `[${objectKey}]`, object[key][objectKey]);
                }
            }
            continue;
        }
        let finalKey = key;
        if (mapper) finalKey = mapper(key);
        formData.append(finalKey, object[key]);
    }
    return formData;
};

export const getControlsFormData = (object, additionalData = {}) => {
    let updatedObject = {};
    for (let key in object) {
        if (object[key].value?._isAMomentObject) updatedObject[key] = dateFixer(object[key].value);
        else if (object[key].value?.id) updatedObject[key] = object[key].value.id;
        else updatedObject[key] = object[key].value;
    }

    return getFormData({ ...updatedObject, ...additionalData });
};

export default getFormData;
