import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Box } from "@material-ui/core";

import PublicInfo from "./Components/PublicInfo";
import RolesTable from "./Components/RolesTable";
import LicensesTable from "./Components/LicensesTable";

import useStyles from "./profileStyles";

const Profile = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const groupRoles = useSelector((store) => store.roles.group_roles);
    const licenses = useSelector((store) => store.user.licenses);

    return (
        <Container className={classes.rootContainer}>
            <PublicInfo />
            {licenses.length > 0 && (
                <>
                    <Box className={classes.titleContainer}>پروانه‌ها</Box>
                    <LicensesTable licenses={licenses} />
                </>
            )}
            {groupRoles.length > 0 && (
                <>
                    <Box className={classes.titleContainer}>نقش‌ها</Box>
                    <RolesTable groupRoles={groupRoles} />
                </>
            )}
        </Container>
    );
};

export default Profile;
