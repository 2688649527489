const searchInputs = (defaults) => {
    return {
        province_id: {
            elementType: "search-list-input",
            elementConfig: {
                label: "جستجوی استان",
                options: defaults.options?.province_id ?? [],
            },
            gridConfig: {
                md: 4,
                xs: 6,
            },
            value: "",
        },
        town_id: {
            elementType: "search-list-input",
            elementConfig: {
                label: "جستجوی شهرستان",
                options: [],
            },
            gridConfig: {
                md: 4,
                xs: 6,
            },
            value: "",
        },
        city_id: {
            elementType: "search-list-input",
            elementConfig: {
                label: "جستجوی شهر",
                options: [],
            },
            gridConfig: {
                md: 4,
                xs: 6,
            },
            value: "",
        },
        expertise: {
            elementType: "select-input",
            elementConfig: {
                label: "رشته",
                options: [],
            },
            gridConfig: {
                md: 4,
                xs: 6,
            },
            value: "",
        },
        required_assignees: {
            elementType: "text-field",
            elementConfig: {
                label: "تعداد کارشناسان",
                type: "number",
            },
            validation: { required: true },
            gridConfig: {
                md: 4,
                xs: 6,
            },
            value: "",
        },
    };
};

export default searchInputs;
