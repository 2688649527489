const uploadResponseInputs = (values) => {
    let inputs = {
        estimation: {
            elementType: "price-field",
            elementConfig: {
                label: "براورد",
                variant: "outlined",
                size: "small",
            },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            validation: { required: true },
            value: values.estimation ?? "",
        },
        documents: {
            elementType: "file-uploader",
            elementConfig: {
                label: "بارگذاری پاسخ",
            },
            gridConfig: {
                xs: 12,
            },
            validation: { required: true },
            value: values.documents ?? [],
        },
    };

    for (let element in values.wages) {
        inputs[element] = {
            elementType: "price-field",
            elementConfig: {
                label: `حق‌الزحمه ${values.names[element]}`,
                variant: "outlined",
                size: "small",
            },
            validation: { required: true },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            value: values.wages[element] ?? "",
        };
    }

    return inputs;
};

export default uploadResponseInputs;
