import React from "react";
import {SvgIcon} from "@material-ui/core";

const AddFileIcon = props => {
    return (
        <SvgIcon {...props} width="28" height="32" viewBox="0 0 28 32" fill="none" >
                <path fill="none" fillRule="evenodd" clipRule="evenodd" d="M18.2102 2.01787H7.9275C6.3818 2.01185 4.89533 2.60519 3.78724 3.6705C2.67915 4.7358 2.03779 6.18814 2.00086 7.71568V24.2937C1.98742 25.0625 2.13223 25.8261 2.42649 26.5379C2.72075 27.2497 3.15832 27.895 3.71262 28.4345C4.26691 28.974 4.92637 29.3965 5.65093 29.6763C6.37549 29.9561 7.15003 30.0874 7.9275 30.0622H20.2767C21.8128 30.0463 23.2804 29.4312 24.36 28.3508C25.4396 27.2704 26.0438 25.8123 26.0412 24.2937V10.0777L18.2102 2.01787Z" stroke="#A3A9B7" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.804 2V6.44509C17.8038 7.48678 18.2216 8.486 18.9658 9.22358C19.7099 9.96117 20.7196 10.3769 21.7733 10.3796C23.8075 10.384 25.8898 10.3859 26.0304 10.3767" stroke="#A3A9B7" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.5271 17.5308H9.95093" stroke="#A3A9B7" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.7385 21.2768V13.7876" stroke="#A3A9B7" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    );
}

export default AddFileIcon