import { assigneeStatus, reportStatus } from "./checkStatus";

const locale = (str) => {
    const pool = {
        HEAD: "سرگروه",
        SECRETARY: "دبیر",
        EXPERT: "کارشناس",
        expert: "کارشناس",
        head: "سرگروه",
        intern: "کارورز",
        supervisor: "هیئت نظارت",
        People: "عمومی",
        Expert: "تخصصی",
        GroupMember: "سازمانی",
        GroupAdmin: "سازمانی",
        Referee: "سازمانی",
        Admin: "ادمین",
        SuperAdmin: "ادمین",
        Supervisor: "ناظر",
        SUPERVISOR: "ناظر",
        BUSINESS: "کسب و کار",
        // expert status pool
        [assigneeStatus.NO_ACTION]: "در انتظار بررسی",
        [assigneeStatus.ACCEPTED]: "در حال انجام",
        [assigneeStatus.REJECTED]: "رد شده",
        [assigneeStatus.REMOVED]: "حذف شده",
        [assigneeStatus.APPROXIMATED]: "تخمین زده شده",
        [assigneeStatus.IN_PROGRESS]: "در حال انجام",
        [assigneeStatus.RESPONDED]: "پاسخ داده شده",
        [assigneeStatus.RESPONSE_REJECTED]: "پاسخ رد شده",
        [assigneeStatus.CONFIRMING]: "در انتظار تایید پاسخ",
        [assigneeStatus.CONFIRMED]: "پاسخ تایید شده",
        [assigneeStatus.NOT_CONFIRMED]: "پاسخ تایید نشده",
        [assigneeStatus.PAID]: "پرداخت شده",

        // report status poolF
        [reportStatus.NO_EXPERT]: "بدون کارشناس",
        [reportStatus.ASSIGNING]: "در حال ارجاع",
        [reportStatus.IN_PROGRESS]: "در حال نگارش",
        [reportStatus.NOTIFIED]: "ابلاغ شده",
        [reportStatus.COMPLETED]: "تکمیل شده",
        [reportStatus.REJECTED]: "لغو شده",

        //wage calculator types
        number: "عدد",
        cost: "تومان",
        length: "متر",
        area: "متر مربع",
        volume: "متر مکعب",
    };
    return pool[str] ? pool[str] : str;
};

export default locale;
