import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";

import UploadCard from "../../Surfaces/Cards/UploadCard";
import FilePreview from "../Image/FilePreview";
import { showAlert } from "../../../store/actions/snackbarActions";

const useStyles = makeStyles((theme) => ({
    previewContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
    },
}));

const SingleFileUploader = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { onChange, onRemove, uploadedFile, helperText, label } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                {uploadedFile ? (
                    <div>
                        <FilePreview
                            src={uploadedFile.link}
                            label={uploadedFile.name}
                            onClick={onRemove}
                            type={uploadedFile.mime_type}
                        />
                        <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                            style={{ textAlign: "right", marginTop: 5 }}
                        >
                            {label}
                        </Typography>
                    </div>
                ) : (
                    <>
                        <UploadCard
                            label={label ?? "افزودن اسناد"}
                            onChange={(event) => {
                                let file = event.target.files[0];
                                if (file.size > 5242880) {
                                    dispatch(showAlert(`حجم فایل ورودی بیشتر از ۵ مگابایت است`, "error"));
                                    return;
                                }
                                onChange(event);
                            }}
                        />
                        <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                            style={{ textAlign: "right", marginTop: 5 }}
                        >
                            {helperText}
                        </Typography>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default SingleFileUploader;
