import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";

import ChipTypo from "../../../components/UI/Typography/ChipTypo";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import ActionCard from "../../../components/Surfaces/Cards/ActionCard";

import { autoAssignExperts } from "../../../api/assigneesAPI";
import { getCompetences } from "../../../api/sharedAPI";
import { showAlert } from "../../../store/actions/snackbarActions";
import { fetchReports } from "../../../store/actions/reportsActions";
import validateForm from "../../../shared/validateForm";
import formCreator from "../../../shared/formCreator";
import systematicAssignInputs from "./systematicAssignInputs";

const SystematicAssign = (props) => {
    const dispatch = useDispatch();
    const { reportId } = useParams();
    const provinces = useSelector((store) => store.geo);
    const [loading, setLoading] = React.useState(false);
    const [controls, setControls] = React.useState(systematicAssignInputs({ options: { province_id: provinces } }));

    React.useEffect(() => {
        dispatch(fetchReports());
        getCompetences().then((response) => {
            const updatedResponse = response.map((item) => ({
                label: item.field,
                value: item.id,
            }));
            let updatedControls = { ...controls };
            updatedControls["expertise"].elementConfig.options = updatedResponse;
            setControls(updatedControls);
        });
    }, []);
    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedControls = { ...controls };
        updatedControls[inputIdentifier].value = value;
        if (inputIdentifier === "province_id") {
            if (value != null) updatedControls["town_id"].elementConfig.options = value.towns;
            else updatedControls["town_id"].elementConfig.options = [];

            updatedControls["town_id"].value = "";
            updatedControls["city_id"].elementConfig.options = [];
            updatedControls["city_id"].value = "";
        }
        if (inputIdentifier === "town_id") {
            if (value != null) updatedControls["city_id"].elementConfig.options = value.cities;
            else updatedControls["city_id"].elementConfig.options = [];

            updatedControls["city_id"].value = "";
        }
        setControls(updatedControls);
    };
    const handleSubmit = () => {
        let filters = {};
        const validatedForm = validateForm(controls);
        setControls(validatedForm.data);
        for (let key in controls) {
            if (key !== "required_assignees") {
                if (key === "province_id" || key === "city_id" || key === "town_id")
                    filters[key] = controls[key].value.id;
                else filters[key] = controls[key].value;
            }
        }
        if (!validatedForm.error) {
            setLoading(true);
            autoAssignExperts({
                filters: filters,
                required_assignees: controls.required_assignees.value,
                role_id: "expert",
                report_id: reportId,
            }).then((response) => {
                setLoading(false);
                dispatch(showAlert(response.message, response.type));
            });
        }
    };
    const formElements = formCreator(controls, inputChangedHandler);

    return (
        <MainContainer>
            <ChipTypo>انتخاب سیستمی</ChipTypo>
            <ActionCard
                actions={[
                    {
                        onClick: handleSubmit,
                        variant: "contained",
                        color: "primary",
                        label: "ارجاع کارشناسان",
                        loading: loading,
                        style: { minWidth: 120 },
                    },
                ]}
            >
                <Grid container spacing={2}>
                    {formElements}
                </Grid>
            </ActionCard>
        </MainContainer>
    );
};

export default SystematicAssign;
