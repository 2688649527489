export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const TYPES = {
    ERROR: "error",
    WARNING: "warning",
    SUCCESS: "success",
    INFO: "info",
};

export const showAlert = (alertMsg, alertType) => ({ type: SHOW_ALERT, alertMsg, alertType });
export const hideAlert = () => ({ type: HIDE_ALERT });
export const clearAlert = () => ({ type: CLEAR_ALERT });
