import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

const MultipleSelect = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { value, label, options, onChange, noOptionLabel, ...otherProps } = props;

    return (
        <Autocomplete
            multiple
            fullWidth
            options={options ? options : []}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => onChange(value)}
            noOptionsText={noOptionLabel ?? "بی نتیجه"}
            renderInput={(params) => <TextField {...params} label={label} {...otherProps} variant={props.variant} />}
        />
    );
};

export default MultipleSelect;
