import React, { Component } from "react";

import SupportPage from "./SupportPage";
import axios from "../../api/instance";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.goToDashboard = this.goToDashboard.bind(this);
        this.state = {
            hasError: false,
            responseCode: "",
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        axios
            .post("/error/log", {
                name: error.name,
                message: error.message,
                stack: error.stack,
                link: window.location.href,
            })
            .then((res) => {
                this.setState({ responseCode: res.data.id });
            });
    }
    goToDashboard() {
        this.props.history.push("/");
        this.setState({ hasError: false });
    }
    render() {
        if (this.state.hasError) {
            return <SupportPage errorCode={this.state.responseCode} goToDashboardLink={this.goToDashboard} />;
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
