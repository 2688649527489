import React from "react";
import axios from "../../../api/instance";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DataTable from "../../../components/Tables/DataTable/DataTable";
import AddSubgroup from "./Dialogs/AddSubgroup";
import RemoveSubgroup from "./Dialogs/RemoveSubgroup";

import { openDialog } from "../../../store/actions/dialogActions";
import Styles from "./styleOrganizationInfo";

const SubGroups = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = Styles();
    const { title, currentGroup, groups, user } = props;
    const [subgroups, setSubGroups] = React.useState([]);

    React.useEffect(() => {
        setSubGroups(groups);
    }, [groups]);
    const tableBody = subgroups.map((group, index) => ({
        data: {
            index: index + 1,
            name: group.name,
            actions: (
                <React.Fragment>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 8 }}
                        disableElevation
                        onClick={() => history.push(`/${user.active_route}/orginfo?group_id=${group.id}`)}
                    >
                        مشاهده سازمان
                    </Button>
                    {/* <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        disableElevation
                        onClick={() =>
                            dispatch(
                                openDialog({
                                    title: "حذف سازمان",
                                    content: <RemoveSubgroup groupId={group.id} removeGroup={removeGroup} />,
                                    maxWidth: "sm",
                                    fullWidth: true,
                                })
                            )
                        }
                    >
                        حذف
                    </Button> */}
                </React.Fragment>
            ),
        },
        config: { className: classes.row },
    }));

    const removeGroup = (groupId) => {
        let updatedGroups = [...subgroups];
        updatedGroups = updatedGroups.filter((item) => item.id !== groupId);
        setSubGroups(updatedGroups);
    };

    const addGroup = (data) => {
        let updatedGroups = [...subgroups, data];
        setSubGroups(updatedGroups);
    };

    return (
        <React.Fragment>
            <Grid container justify="space-between" alignItems="center" spacing={2} className={classes.titleContainer}>
                <Grid>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid
                    item
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() =>
                            dispatch(
                                openDialog({
                                    title: "اضافه کردن زیر سازمان جدید",
                                    content: <AddSubgroup groupId={currentGroup.id} addGroup={addGroup} />,
                                    maxWidth: "sm",
                                    fullWidth: true,
                                })
                            )
                        }
                        variant="contained"
                        color="primary"
                    >
                        اضافه کردن زیر سازمان جدید
                    </Button>
                </Grid>
            </Grid>
            <DataTable
                head={{
                    data: {
                        index: "ردیف",
                        name: "نام سازمان",
                        actions: "",
                    },
                    config: {
                        config: { className: classes.head },
                    },
                }}
                body={tableBody}
                rowsPerPage={10}
            />
        </React.Fragment>
    );
};

export default SubGroups;
