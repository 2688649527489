import React from "react";

import { Dialog, DialogContent, Box, DialogTitle, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReportIcon from "@material-ui/icons/Report";

import FilePreview from "../UI/Image/FilePreview";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(18),
        color: "rgba(0,0,0,0.7)",
    },
    titleContainer: {
        padding: theme.spacing(1, 2, 1, 1),
    },
    content: {
        minHeight: 200,
        textAlign: "center",
        minWidth: 500,
    },
    icon: {
        marginLeft: 20,
        border: "1px solid",
        borderRadius: 4,
        padding: theme.spacing(0.5),
        "& svg": {
            fontSize: 12,
        },
    },
    fileContainer: {
        borderRadius: 6,
        border: "2px solid #DEDEDE",
        padding: theme.spacing(2),
    },
    fileTitle: {
        width: "calc(100% + 8px)",
        color: theme.palette.table.main,
        textAlign: "left",
        backgroundColor: "#DEDEDE",
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,
        padding: theme.spacing(1),
        marginLeft: -4,
    },
}));

const FilePreviewDialog = (props) => {
    const classes = useStyles();
    const { onClose, open, title, files, ...otherProps } = props;

    return (
        <Dialog open={open} onClose={onClose} {...otherProps} scroll={"paper"}>
            <DialogTitle className={classes.titleContainer} divider={false}>
                <IconButton onClick={onClose} size="small" className={classes.icon}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Box className={classes.fileTitle}>
                    <Typography variant="body2">{title}</Typography>
                </Box>
                <Grid container spacing={1} className={classes.fileContainer}>
                    {files?.map((file, index) => {
                        return (
                            <Grid item xs={6} sm={4} key={index}>
                                <FilePreview
                                    src={file.link}
                                    label={file.name}
                                    onClick={() => window.open(file.link)}
                                    type={file.mime_type}
                                    icon="download"
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                {!files && (
                    <React.Fragment>
                        <ReportIcon />
                        <Typography variant="body2" color="textSecondary">
                            سندی وجود ندارد
                        </Typography>
                    </React.Fragment>
                )}
            </DialogContent>
            {props.children}
        </Dialog>
    );
};

export default FilePreviewDialog;
