import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    FormControl,
    Typography,
    FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    button: {
        height: "100%",
        minHeight: 50,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        minWidth: "20px",
    },
    helperText: {
        fontSize: "0.75rem !important",
        margin: 0,
        marginTop: 7,
    },
    label: {
        // transform: "translate(14px, 14px) scale(1)",
    },
}));

const SubmitInput = (props) => {
    const classes = useStyles();
    const { label, inputProps, buttonProps, fullWidth, suffix, ...otherProps } = props;

    return (
        <FormControl variant="outlined" fullWidth={fullWidth} {...otherProps}>
            {label && <InputLabel className={classes.label}>{label}</InputLabel>}
            <OutlinedInput
                style={{ padding: 0 }}
                {...inputProps}
                endAdornment={
                    <InputAdornment position="end">
                        {suffix && (
                            <Typography
                                component="p"
                                variant="caption"
                                color="textSecondary"
                                style={{ marginLeft: 10 }}
                            >
                                {suffix}
                            </Typography>
                        )}
                        {buttonProps && (
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                {...buttonProps}
                                disableElevation
                            >
                                {buttonProps.label}
                            </Button>
                        )}
                    </InputAdornment>
                }
                labelWidth={label.length * 5 + 25}
            />
            <FormHelperText className={classes.helperText}>{props.helperText}</FormHelperText>
        </FormControl>
    );
};

export default SubmitInput;
