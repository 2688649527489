import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { faIR } from "@material-ui/core/locale";

export default createMuiTheme(
    {
        direction: "rtl",
        typography: {
            fontFamily: "YekanBakh , IRANSans, tahoma",
        },
        palette: {
            primary: {
                main: "#59C9FA",
            },
            secondary: {
                main: "#ff5b5b",
            },
            table: {
                main: "#54617A",
            },
        },
        overrides: {
            MuiCard: {
                root: {
                    borderRadius: 10,
                    boxShadow: "0 0 20px 0 #d7dee366",
                },
            },
            MuiListItemIcon: {
                root: {
                    color: "inherit",
                },
            },
            MuiInputLabel: {
                root: {
                    fontSize: 14,
                },
            },
            MuiInputBase: {
                input: {
                    fontSize: 16,
                    textAlign: "left",
                },
            },
            MuiButton: {
                contained: {
                    boxShadow: "none",
                },
                containedPrimary: {
                    color: "#fff",
                },
            },
            MuiPopover: {
                root: {
                    // zIndex: "1298 !important",
                    backgroundColor: "rgba(27, 27, 27, 0.4)",
                },
            },
            // MuiDialog: {
            //     root: {
            //         backdropFilter: "blur(6px)",
            //     },
            // },
        },
    },
    faIR
);
