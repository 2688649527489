import React from "react";
import axios from "../../../../api/instance";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "../../../../components/UI/Buttons/LoadingButton";

import { showAlert, TYPES } from "../../../../store/actions/snackbarActions";
import { updateUserInformation } from "../../../../store/actions/userActions";
import getRouteFromRole from "../../../../shared/getRouteFromRole";
import formCreator from "../../../../shared/formCreator";
import validateForm from "../../../../shared/validateForm";
import addFormData from "./addOrganizationFormData";

const AddOrganization = (props) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = React.useState({});
    const [checkForm, setCheckForm] = React.useState(false);

    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedControls = { ...formData };
        updatedControls[inputIdentifier] = value;
        setFormData(updatedControls);
    };
    const submitAddOrg = () => {
        const validatedForm = validateForm(addFormData(formData));
        setCheckForm(true);
        if (validatedForm.error === false)
            axios
                .post("/groups", formData)
                .then((res) => {
                    dispatch({ type: "CLOSE_DIALOG" });
                    dispatch(
                        updateUserInformation({
                            active_role: res.data.active_role,
                            active_route: getRouteFromRole(res.data.active_role),
                            active_role_slug: res.data.active_role_slug,
                        })
                    );
                    props.history.push("/");
                })
                .catch((err) => {
                    dispatch(showAlert(err?.response?.data?.message, TYPES.ERROR));
                });
    };

    let formElements = formCreator(addFormData(formData), inputChangedHandler);
    if (checkForm) {
        formElements = formCreator(validateForm(addFormData(formData)).data, inputChangedHandler);
    }
    return (
        <>
            <DialogContent>
                <Grid container spacing={1}>
                    {formElements}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={submitAddOrg}
                    disableElevation
                    color="primary"
                    variant="contained"
                    autoFocus
                    style={{ minWidth: 120 }}
                >
                    ثبت اطلاعات
                </Button>
            </DialogActions>
        </>
    );
};

export default AddOrganization;
