import React from "react";
import { useHistory } from "react-router-dom";
import { Card, CardActions, makeStyles, Button } from "@material-ui/core";

import LoadingButton from "../../UI/Buttons/LoadingButton";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(3, 2, 1, 2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1, 2),
        },
        borderRadius: 8,
        maxWidth: 1200,
        margin: "0 auto",
    },
    form: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingBottom: 30,
    },
    backButton: {
        color: "#54617A",
        width: 100,
    },
    submitButton: {
        color: "#fff",
        width: "150px",
    },
}));

const CreateCard = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { type, onSubmit, loading, disabled } = props;

    return (
        <Card className={classes.card} elevation={2}>
            <form
                className={classes.form}
                onSubmit={(event) => {
                    event.preventDefault();
                    onSubmit();
                }}
                noValidate
                autoComplete="off"
            >
                {props.children}
            </form>
            <CardActions style={{ justifyContent: "flex-end" }}>
                <Button
                    size="medium"
                    variant="contained"
                    color="default"
                    disableElevation
                    className={classes.backButton}
                    onClick={() => history.goBack()}
                >
                    لغو
                </Button>
                <LoadingButton
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.submitButton}
                    loading={loading}
                    disabled={disabled}
                    onClick={onSubmit}
                    disableElevation
                >
                    {type}
                </LoadingButton>
            </CardActions>
        </Card>
    );
};

export default CreateCard;
