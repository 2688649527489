import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

import ReportInfoTable from "../../../components/Tables/ReportInfoTable/ReportInfoTable";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import ReportHeader from "./ReportHeader";
import AssigneesTable from "./AssigneesTable";
import ExpertAction from "./ExpertAction";
import ExpertAlert from "./ExpertAlert";

import { fetchReports, fetchSingleReport } from "../../../store/actions/reportsActions";
import { getAssignees } from "../../../api/assigneesAPI";
import { changeAssigneeRole, changeCurrentAssigneeData } from "./updateAssignee";
import currencySeparator from "../../../shared/currencySeprator";

const ReportDetails = () => {
    const dispatch = useDispatch();
    const { reportId } = useParams();
    const [assignees, setAssignees] = useState(false);
    const user = useSelector((store) => store.user);
    const assignee = assignees ? assignees.find((item) => item.user_id == user.id) : null;
    const { reports } = useSelector((store) => store.reports);
    const report = reports.find((item) => item.id == reportId);

    React.useEffect(() => {
        getAssignees(reportId).then((response) => {
            setAssignees(response);
        });
        dispatch(fetchReports());
        dispatch(fetchSingleReport(reportId));
    }, []);

    return (
        <MainContainer>
            {report && assignees ? (
                <>
                    <ReportHeader
                        report={report}
                        assignees={assignees}
                        assignee={assignee}
                        changeAssigneeData={(...data) =>
                            setAssignees(changeCurrentAssigneeData(assignees, assignee, ...data))
                        }
                    >
                        <ReportInfoTable
                            report={report}
                            append={[
                                {
                                    data: {
                                        first:
                                            "برآورد: " +
                                            (assignee.estimation
                                                ? currencySeparator(assignee.estimation) +
                                                  " تومان" +
                                                  (assignee.estimations_date ? "" : " (تایید نشده) ")
                                                : "وارد نشده"),
                                        second:
                                            "حق‌الزحمه: " +
                                            (assignee.wage
                                                ? currencySeparator(assignee.wage) +
                                                  " تومان" +
                                                  (assignee.estimations_date ? "" : " (تایید نشده) ")
                                                : "وارد نشده"),
                                    },
                                },
                            ]}
                        />
                    </ReportHeader>
                    <ExpertAlert report={report} assignee={assignee} />
                    <ExpertAction
                        assignee={assignee}
                        report={report}
                        changeAssigneeData={(...data) =>
                            setAssignees(changeCurrentAssigneeData(assignees, assignee, ...data))
                        }
                        changeRole={(...data) => setAssignees(changeAssigneeRole(assignees, ...data))}
                        assignees={assignees.filter((item) => item.is !== "REJECTED" && item.is !== "REMOVED")}
                        assignee={assignee}
                    />
                    {assignee.status !== "REJECTED" && (
                        <AssigneesTable
                            assignees={assignees}
                            assignee={assignee}
                            changeRole={(...data) => setAssignees(changeAssigneeRole(assignees, ...data))}
                        />
                    )}
                </>
            ) : (
                <Skeleton variant="rect" height={220} animation="wave" />
            )}
        </MainContainer>
    );
};

export default ReportDetails;
