const reportInputs = (defaults) => {
    return {
        name: {
            elementType: "text-field",
            elementConfig: {
                label: "نام و نام خانوادگی مخاطب نامه",
            },
            validation: { required: true },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            value: "",
        },
        post: {
            elementType: "text-field",
            elementConfig: {
                label: "سمت مخاطب نامه",
            },
            validation: { required: true },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            value: "",
        },
        returned_mail_number: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره نامه بازگشتی",
            },
            validation: { required: true },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            value: "",
        },
        returned_mail_date: {
            elementType: "date-picker",
            elementConfig: {
                label: "تاریخ نامه بازگشتی",
            },
            validation: { required: true },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            value: null,
        },
    };
};

export default reportInputs;
