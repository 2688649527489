import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

import MainContainer from "../../../components/UI/Containers/MainContainer";
import HeadCard from "../../../components/Surfaces/Cards/HeadCard/HeadCard";
import { fetchReports, fetchCategories } from "../../../store/actions/reportsActions";
import { fetchGroups } from "../../../store/actions/groupsActions";
import CategoriesTable from "../../../components/Tables/CategoriesTable/CategoriesTable";

const GroupDetails = () => {
    const dispatch = useDispatch();
    const { groupId } = useParams();
    const { loading, reports, categories } = useSelector((store) => store.reports);
    const group = useSelector((store) => store.groups.groups).find((item) => item.id == groupId);

    React.useEffect(() => {
        dispatch(fetchReports());
        dispatch(fetchCategories());
        dispatch(fetchGroups());
    }, []);

    return (
        <MainContainer>
            {loading || !group ? (
                <Skeleton variant="rect" height={120} />
            ) : (
                <HeadCard
                    type="کسب و کار"
                    title={group.name}
                    caption={"تعداد گزارش ها : " + reports.filter((report) => report.group_id === group.id).length}
                />
            )}
            <CategoriesTable categories={categories} reports={reports} loading={loading} />
        </MainContainer>
    );
};

export default GroupDetails;
