import React from "react";
import MuiBtn from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingButton = (props) => {
    const { iconButton, loading, children, startIcon, ...properties } = props;
    const BtnComponent = iconButton ? IconButton : MuiBtn;

    return (
        <BtnComponent {...properties} disabled={loading || properties.disabled} startIcon={loading ? null : startIcon}>
            {loading ? <CircularProgress size={22} /> : children}
        </BtnComponent>
    );
};

export default LoadingButton;
