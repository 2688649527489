import React from "react";
import { FormGroup, FormControlLabel, Checkbox, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    label: {
        color: "#8a8e99",
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 500,
    },
    control: {
        textAlign: "left",
        "& > span:nth-child(2)": {
            marginRight: "auto",
        },
        backgroundColor: "rgba(196, 196, 196, 0.2)",
        borderRadius: 4,
        margin: theme.spacing(0.5, 0),
        padding: theme.spacing(0, 1),
    },
    inputGroup: {
        width: "100%",
        padding: theme.spacing(1, 0),
    },
}));

const CustomCheckboxGroup = (props) => {
    const classes = useStyles();
    const { options, value, onChange } = props;

    return (
        <FormGroup column="true" className={classes.inputGroup}>
            {options.map((option, index) => (
                <FormControlLabel
                    key={index}
                    value={option.value}
                    control={
                        <Checkbox
                            disableRipple
                            checked={value.indexOf(option.value) !== -1}
                            size="small"
                            color="primary"
                            onChange={() => {
                                if (value.indexOf(option.value) === -1) onChange([...value, option.value]);
                                else onChange(value.filter((item) => item !== option.value));
                            }}
                        />
                    }
                    label={<span className={classes.label}>{option.name}</span>}
                    labelPlacement="start"
                    className={classes.control}
                />
            ))}
        </FormGroup>
    );
};

export default CustomCheckboxGroup;
