import { CLEAR_ALERT, HIDE_ALERT, SHOW_ALERT } from "../actions/snackbarActions";

const initialState = {
    show: false,
    alertType: "",
    alertMsg: "",
};

const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_ALERT:
            return {
                show: true,
                alertType: action.alertType,
                alertMsg: action.alertMsg,
            };
            break;
        case HIDE_ALERT:
            return { ...state, show: false };
            break;
        case CLEAR_ALERT:
            return initialState;
            break;
        default:
            return state;
    }
};

export default snackbarReducer;
