const getRouteFromRole = (role) => {
    switch (role) {
        case "People":
            return "user";
        case "SuperAdmin":
            return "admin";
        case "Referee":
            return "referee";
        case "Supervisor":
            return "supervisor";
        case "GroupMember":
            return "org";
        case "GroupAdmin":
            return "org";
        case "Expert":
            return "expert";
        default:
            return "user";
    }
};

export default getRouteFromRole;
