import React from "react";
import { Grid, Typography, IconButton, Avatar } from "@material-ui/core";
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined";
import styles from "./style";

const ConversationHeader = (props) => {
    const classes = styles();
    const { assignees } = props;

    const [expanded, setExpanded] = React.useState(assignees.length <= 2);

    let avatarMargin = expanded ? 0 : -10;
    return (
        <Grid container className={classes.conversationHeader}>
            <div className={classes.headerContent}>
                {assignees.map((assignee, index) => (
                    <React.Fragment key={index}>
                        <Avatar
                            style={{ transition: "all .5s", marginRight: index === 0 ? 0 : avatarMargin }}
                            className={classes.avatar}
                            src={assignee.avatar_link}
                        />
                        <div
                            style={{
                                transition: "all .5s",
                                width: expanded ? 140 : 0,
                                visibility: expanded ? "visible" : "hidden",
                            }}
                        >
                            <Typography variant="body1" className={classes.nameTypo}>
                                {assignee.name + " " + assignee.surname}
                            </Typography>
                            <Typography variant="body2" className={classes.roleTypo}>
                                {assignee.assignee_role}
                            </Typography>
                        </div>
                    </React.Fragment>
                ))}

                {assignees.length > 2 && (
                    <IconButton onClick={() => setExpanded(!expanded)} className={classes.expandButton}>
                        <NavigateBeforeOutlinedIcon
                            style={{ transform: expanded ? "rotate(180deg)" : "", transition: "all .5s" }}
                        />
                    </IconButton>
                )}
            </div>
        </Grid>
    );
};

export default ConversationHeader;
