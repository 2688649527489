import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
    rootContainer: {
        width: "100%",
        maxWidth: 1000,
        padding: theme.spacing(3, 2, 4, 4),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1, 2, 4, 2),
        },
    },
    titleContainer: {
        textAlign: "left",
        backgroundColor: "#F1F2F4",
        color: "#54617A",
        padding: theme.spacing(1.6, 3),
        fontSize: theme.typography.pxToRem(16),
        marginTop: theme.spacing(3),
    },
    paper: {
        boxShadow: "0px 0px 20px rgba(215, 222, 227, 0.4)",
    },
    containerFixer: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    saveButton: {
        marginRight: 15,
        backgroundColor: "#51B6FF",
        color: "#fff",
        boxShadow: "none !important",
        width: 150,
    },
    logoutButton: {
        marginLeft: "auto",
        marginRight: 15,
        backgroundColor: "#FF5151",
        color: "#fff",
        boxShadow: "none !important",
        width: 150,
    },
    IBAN: {
        "& input": {
            textAlign: "right",
            direction: "rtl",
            marginRight: 10,
        },
    },

    // components styles
    tableContainer: {
        boxShadow: "0 0 20px 0 #d7dee366",
        marginTop: 15,
        borderRadius: 4,
    },
    tableHead: {
        backgroundColor: "#F9FAFC",
        color: "#A3A9B7",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        borderWidth: 0,
    },
    tableRow: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        marginTop: 30,

        borderWidth: 2,
    },
    blueButton: {
        color: theme.palette.primary.main,
        backgroundColor: "rgba(89, 201, 250, 0.2)",
        padding: theme.spacing(0.2, 3),
        borderRadius: 5,
    },
    greenButton: {
        color: "#03DE73",
        backgroundColor: "rgba(3, 222, 115, 0.2)",
        padding: theme.spacing(0.2, 2),
        borderRadius: 5,
    },
    redButton: {
        color: "#FF4646",
        backgroundColor: "rgba(255, 70, 70, 0.2)",
        padding: theme.spacing(0.2, 2),
        borderRadius: 5,
        marginLeft: theme.spacing(0.8),
    },
    rejectedText: {
        color: "#FF4646",
        fontWeight: 600,
    },
    roleRow: {
        "&:nth-of-type(4)": {
            textAlign: "center",
        },
    },
    licenseRow: {
        "&:nth-of-type(6)": {
            textAlign: "center",
        },
        "&:nth-of-type(5)": {
            textAlign: "center",
        },
    },
    recheckButton: {
        backgroundColor: "rgba(196, 196, 196, 0.3)",
        borderRadius: 5,
    },
}));
