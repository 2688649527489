import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    redButton: {
        backgroundColor: "rgba(255, 91, 91, 0.1)",
        color: "rgba(255, 91, 91, 1)",
        "&:hover": {
            backgroundColor: "rgba(255, 91, 91, 0.3)",
        },
    },
    greenButton: {
        color: "#4caf50",
        borderColor: "#4caf50",
        backgroundColor: "#4caf5021",
    },
    skeleton: {
        borderRadius: 10,
    },
    tableContainer: {
        boxShadow: "0 0 20px 0 #d7dee366",
        marginTop: 15,
        borderRadius: 4,
        width: "100%",
    },
    head: {
        backgroundColor: "#F9FAFC",
        color: "#A3A9B7",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        "&:nth-of-type(3)": {
            textAlign: "left",
        },
        borderWidth: 0,
    },
    row: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        marginTop: 30,
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        "&:nth-of-type(3)": {
            textAlign: "left",
        },
        borderWidth: 2,
    },
    seeMore: {
        color: "#A3A9B7",
        backgroundColor: "rgba(196, 196, 196, 0.3)",
        border: "2px solid #A3A9B7",
        padding: theme.spacing(0.2, 3),
        borderRadius: 5,
        "&:hover": {
            textDecoration: "underline",
        },
    },
    titleContainer: {
        backgroundColor: "#fff",
        borderRadius: theme.spacing(1, 1, 0, 0),
        textAlign: "left",
        padding: theme.spacing(1.5, 1, 1.5, 3),
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        color: "#54617A",
        borderRadius: 8,
        width: "100%",
    },
    status: {
        paddingLeft: theme.spacing(1),
        fontSize: theme.typography.pxToRem(12),
    },
    checkTypo: {
        margin: theme.spacing(1.5, 0),
        color: "#54617A",
        fontWeight: 600,
    },
}));
