import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    searchButton: {
        margin: theme.spacing(0, 1, 0, 2),
        height: 40,
        width: "15%",
        borderRadius: 8,
        marginTop: 3,
        fontSize: theme.typography.pxToRem(13),
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
            width: "50%",
            margin: theme.spacing(0),
        },
    },
    linkButton: {
        margin: theme.spacing(0, 1),
        height: 40,
        marginTop: 3,
        width: "15%",
        borderRadius: 8,
        backgroundColor: "#36B962",
        color: "#fff",
        fontSize: theme.typography.pxToRem(13),
        fontWeight: 600,
        whiteSpace: "nowrap",
        [theme.breakpoints.down("sm")]: {
            width: "50%",
            margin: theme.spacing(0),
        },
        "&:hover": {
            backgroundColor: "#228b45",
        },
    },
}));
