import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    sideBar: {
        backgroundColor: "#363740",
        position: "fixed",
        overflowY: "auto",
        overflowX: "hidden",
        top: 0,
        left: 0,
        zIndex: 1300,
        height: "100%",
        transition: "0.5s",
        "@media print": {
            display: "none",
        },
    },
    root: {
        padding: theme.spacing(5, 3, 0, 3),
    },
    arrowStyle: {
        cursor: "pointer",
        color: "white",
        margin: 10,
        transition: "transform 500ms",
        fontSize: 30,
    },
    avatar: {
        width: 60,
        position: "relative",
    },
    notification: {
        position: "absolute",
        zIndex: 200,
        backgroundColor: "#B2FFDA",
        border: "2px solid #363740",
        top: -5,
        left: 0,
        "& svg": {
            fontSize: 14,
            color: "#03DE73",
        },
        "&:hover": {
            backgroundColor: "#B2FFDA !important",
        },
    },
    nameTypo: {
        color: "#DDE2FF",
        width: "100%",
        fontWeight: 700,
        textAlign: "left",
        fontSize: theme.typography.pxToRem(14),
    },
    roleTypo: {
        color: "#A4A6B3",
        width: "100%",
        fontWeight: 400,
        textAlign: "justify",
        fontSize: theme.typography.pxToRem(12),
    },
    contactContainer: {
        margin: theme.spacing(1, 0, 2, 0),
        width: "calc(100% + 20px)",
    },
    contactIcon: {
        backgroundColor: "rgba(164, 166, 179, 1)",
        borderRadius: 4,
        height: 10,
        width: 10,
        "& svg": {
            color: "#363740",
            fontSize: 16,
        },
        "&:hover": {
            backgroundColor: "rgba(164, 166, 179, 1)",
        },
    },
    toggleButton: {
        padding: theme.spacing(0),
        position: "absolute",
        top: "calc(100vh / 2)",
        right: -6,
        backgroundColor: "#3F4049",
        boxShadow: "unset",
        borderRadius: 0,
        width: 40,
        height: 35,
        minWidth: "unset",
        transition: "all .5s",
        "&:hover": {
            boxShadow: "unset !important",
            backgroundColor: "#ffffff4d",
        },
        "& svg": {
            color: "#A4A6B3",
            marginRight: 8,
        },
    },
    toggleButtonClose: {
        height: "100%",
        transition: "all .5s",
        top: 0,
        color: "#A4A6B3",
    },
    toggleIconClose: {
        transform: "rotateZ(-180deg)",
        transition: "all .5s",
    },
    logo: {
        borderRadius: 0,
        width: 40,
        height: 40,
        marginLeft: 10,
        "& img": {
            objectFit: "contain",
        },
    },
    copyright: {
        fontSize: 10,
        marginTop: 10,
        color: "#A4A6B3",
    },
    profileButton: {
        padding: `${theme.spacing(0.5)}px !important`,
        fontSize: theme.typography.pxToRem(10),
        color: "#A4A6B3",
        backgroundColor: "rgba(159, 162, 180, 0.2)",
        marginRight: "7px",
    },
}));
