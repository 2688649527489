import axios from "../../api/instance";

export const SET_GROUPS = "SET_GROUPS";
export const ADD_GROUP = "ADD_GROUP";
export const TOGGLE_LOADING_GROUP = "TOGGLE_LOADING_GROUP";

export const toggleLoading = (value) => ({ type: TOGGLE_LOADING_GROUP, value: value });
export const setGroups = (groups) => {
    return { type: SET_GROUPS, value: groups };
};

export const fetchGroups = (force) => {
    return (dispatch, getState) => {
        if (getState().groups.groups.length > 0 && !force) return;
        dispatch(toggleLoading(true));
        axios
            .get("/groups/my-groups")
            .then((response) => {
                dispatch(setGroups(response.data.data));
                dispatch(toggleLoading(false));
            })
            .catch((error) => {
                dispatch(toggleLoading(false));
                console.log("problem fetching groups");
            });
    };
};
