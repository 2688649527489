import { combineReducers } from "redux";

import notificationReducer from "./notificationReducer";
import snackbarReducer from "./snackbarReducer";
import reportsReducer from "./reportsReducer";
import userReducer from "./userReducer";
import groupsReducer from "./groupsReducer";
import geoReducer from "./geoReducer";
import dialogReducer from "./dialogReducer";
import rolesReducer from "./rolesReducer";

export default combineReducers({
    notification: notificationReducer,
    reports: reportsReducer,
    groups: groupsReducer,
    snackbar: snackbarReducer,
    user: userReducer,
    geo: geoReducer,
    dialog: dialogReducer,
    roles: rolesReducer,
});
