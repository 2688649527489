import React from "react";
import { Box, makeStyles, Grid, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import LinearLoading from "../../../components/Static/LinearLoading/LinearLoading";
import CollapsibleTable from "../../../components/Tables/CollapsibleTable/CollpasibleTable";
import TableCard from "../../../components/Surfaces/Cards/TableCard/TableCard";

import { statusColors, statusBackColors } from "../../../shared/checkStatus";
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        boxShadow: "0 0 20px 0 #d7dee366",
        marginTop: 15,
        borderRadius: 4,
    },
    head: {
        backgroundColor: "#F9FAFC",
        color: "#A3A9B7",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        borderWidth: 0,
    },
    row: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        marginTop: 30,
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        borderWidth: 2,
    },
    checkTypo: {
        margin: theme.spacing(2, 0),
        color: "#54617A",
        fontWeight: 600,
    },
}));

const CategoriesTable = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const { groupId } = useParams();
    const { loading, reports, categories } = props;
    const user = useSelector((store) => store.user);

    const updatedCategories = categories.filter((item) => {
        let categoryReports = reports.filter(
            (report) => (report.group_id == groupId || !groupId) && report.category == item.category
        );

        return categoryReports.length !== 0;
    });

    const tableBody = updatedCategories.map((item, index) => {
        let cards = reports.filter(
            (report) => report.category === item.category && (report.group_id == groupId || !groupId)
        );

        cards = cards.map((card) => ({
            ...card,
            onClick: () => {
                let directRoute = `/${user.active_route}/report/${card.id}`;
                if (user.active_role == "Referee" || user.active_role == "SuperAdmin")
                    directRoute = `/${user.active_route}/group/${groupId}/report/${card.id}`;
                history.push(directRoute);
            },
        }));

        return {
            data: { index: index + 1, name: item.category, count: cards.length },
            children: (
                <Grid container spacing={2}>
                    {cards.map((card, index) => (
                        <Grid key={index} item xs={6} sm={4} md={3} lg={2}>
                            <TableCard
                                {...card}
                                backgroundColor={statusBackColors[card?.status]}
                                captionColor={statusColors[card?.status]}
                                key={index}
                            />
                        </Grid>
                    ))}
                </Grid>
            ),
            config: { className: classes.row, align: "center" },
        };
    });
    if (categories.length === 0)
        return (
            <Typography variant="body1" className={classes.checkTypo}>
                پرونده‌ای وجود ندارد
            </Typography>
        );
    if (loading) return <LinearLoading width={200} title="در حال بارگیری" />;
    return (
        <Box className={classes.tableContainer}>
            <CollapsibleTable
                head={{
                    data: {
                        index: "ردیف",
                        name: "نام پرونده",
                        count: "تعداد گزارش",
                        collapse: "جزئیات",
                    },
                    config: {
                        className: classes.head,
                        align: "center",
                    },
                }}
                body={tableBody}
            />
        </Box>
    );
};

export default CategoriesTable;
