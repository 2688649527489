import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import PostAddIcon from "@material-ui/icons/PostAdd";

import DashboardCard from "./DashboardCard";
import ButtonCard from "../../Surfaces/Cards/ButtonCard";

const DashboardCards = (props) => {
    const { cards, loading, showDashboardButton } = props;
    const history = useHistory();
    const user = useSelector((store) => store.user);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const colors = {
        red: "rgba(222,133,166,0.1)",
        yellow: "rgba(234, 151, 26, 0.1)",
        blue: "rgba(0, 178, 255, 0.1)",
        green: "rgba(0, 255, 194, 0.1)",
        grey: "rgba(241, 242, 244, 1)",
    };

    const textColors = {
        red: "rgba(222,133,166,1)",
        yellow: "rgba(234, 151, 26, 1)",
        blue: "rgba(0, 178, 255, 1)",
        green: "rgba(3, 222, 115, 1)",
        grey: "rgba(163, 169, 183, 1)",
    };

    return (
        <Grid container spacing={isMobile ? 1 : 2} alignItems="stretch">
            {showDashboardButton && (
                <Grid item xs={6} md={3}>
                    <ButtonCard
                        title="افزودن گزارش جدید"
                        onClick={() => history.push(`/${user.active_route}/createreport`)}
                        icon={<PostAddIcon />}
                    />
                </Grid>
            )}
            {cards.map((card, index) => (
                <Grid item key={index} xs={6} md={showDashboardButton ? 3 : 4}>
                    {loading ? (
                        <Skeleton variant="rect" height={50} animation="wave" style={{ borderRadius: 8 }} />
                    ) : (
                        <DashboardCard
                            number={card.value}
                            title={card.title}
                            color={colors[card.color]}
                            textColor={textColors[card.color]}
                            loading={loading}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export default DashboardCards;
