import React from "react";

import FullWidthAlert from "../../../components/UI/Surfaces/FullWidthAlert";
import Typography from "@material-ui/core/Typography";

import { checkStatus, assigneeStatus, reportStatus, roles } from "../../../shared/checkStatus";

const ExpertAlert = (props) => {
    const { report, assignee } = props;
    let status = [
        {
            status: assigneeStatus.NO_ACTION,
            content: {
                type: "info",
                body: "برای رد گزارش باید دلیل داشته باشید",
                title: "تایید یا رد گزارش",
            },
        },
        {
            status: assigneeStatus.REJECTED,
            content: {
                type: "error",
                body: `دلیل رد : ${assignee.status_comment}`,
                title: "گزارش رد شده",
            },
        },
        {
            status: assigneeStatus.REMOVED,
            content: {
                type: "error",
                body: `دلیل رد : ${assignee.status_comment}`,
                title: "حذف توسط سامانه",
            },
        },
        {
            status: assigneeStatus.APPROXIMATED,
            content: {
                type: "info",
                body: "تخمین از گزارش شما با موفقیت ثبت شده و در حال بررسی توسط سامانه است.",
                title: "اطلاعات ثبت شد",
            },
        },
        {
            status: assigneeStatus.ACCEPTED,
            condition: report.group_id === null,
            content: {
                type: "warning",
                body: "گزارش در انتظار پیش پرداخت کارفرما است.",
                title: "در انتظار پرداخت",
            },
        },
        {
            role: [roles.Expert, roles.Head],
            condition: report.is_person == 0,
            status: [assigneeStatus.IN_PROGRESS, assigneeStatus.ACCEPTED],
            content: {
                type: "warning",
                body: "دبیر این گزارش، هنوز پاسخی ارسال نکرده است.",
                title: "بارگزاری پاسخ",
            },
        },
        {
            condition: report.is_person == 1,
            status: assigneeStatus.IN_PROGRESS,
            content: {
                type: "warning",
                body: "پس از اعلام براورد و حق‌الزحمه‌ی خود پاسخ را ارسال نمایید.",
                title: "بارگزاری پاسخ",
            },
        },
        {
            role: roles.Secretary,
            status: assigneeStatus.ACCEPTED,
            content: {
                type: "warning",
                body: "پس از اعلام براورد و حق‌الزحمه‌ پاسخ را ارسال نمایید.",
                title: "بارگزاری پاسخ",
            },
        },
        {
            role: roles.Supervisor,
            status: assigneeStatus.ACCEPTED,
            content: {
                type: "info",
                body: "گزارش در حال نگارش توسط هیئت کارشناسی است.",
                title: "در حال نگارش",
            },
        },
        {
            status: assigneeStatus.RESPONDED,
            condition: report.group_id !== null,
            content: {
                type: "info",
                body: "پاسخ شما ثبت شده و در انتظار تایید بقیه اعضای گزارش است.",
                title: "پاسخ شما ثبت شد",
            },
        },
        {
            status: assigneeStatus.RESPONDED,
            condition: report.group_id === null,
            content: {
                type: "info",
                body: "پاسخ شما ثبت شده و در انتظار پرداخت نهایی است.",
                title: "پاسخ شما ثبت شد",
            },
        },
        {
            status: assigneeStatus.RESPONSE_REJECTED,
            content: {
                type: "error",
                body: "پاسخ دبیر توسط اعضای گزارش رد شده و در انتظار اصلاح است.",
                title: "پاسخ رد شده",
            },
        },
        {
            role: [roles.Expert, roles.Head],
            status: assigneeStatus.RESPONSE_REJECTED,
            content: {
                type: "error",
                body: "پاسخ دبیر توسط اعضای گزارش رد شده و در انتظار اصلاح است.",
                title: "پاسخ رد شده",
            },
        },
        {
            role: roles.Secretary,
            status: assigneeStatus.RESPONSE_REJECTED,
            content: {
                type: "error",
                body: "پاسخ شما توسط اعضای گزارش رد شده و در انتظار اصلاح است.",
                title: "پاسخ رد شده",
            },
        },
        {
            role: [roles.Expert, roles.Head, roles.Supervisor],
            status: assigneeStatus.CONFIRMING,
            content: {
                type: "warning",
                body: "پاسخ ثبت شده و در انتظار تایید شما است.",
                title: "تایید پاسخ",
            },
        },
        {
            role: [roles.Expert, roles.Head, roles.Supervisor],
            status: assigneeStatus.CONFIRMED,
            condition: report.status !== reportStatus.COMPLETED,
            content: {
                type: "warning",
                body: "پاسخ دبیر توسط شما تایید شده و در انتظار تایید بقیه اعضای گزارش است",
                title: "پاسخ تایید شده.",
            },
        },
        {
            role: [roles.Expert, roles.Head, roles.Supervisor],
            status: assigneeStatus.CONFIRMED,
            condition: report.status !== reportStatus.COMPLETED,
            content: {
                type: "warning",
                body: "پاسخ دبیر توسط شما تایید شده و در انتظار تایید بقیه اعضای گزارش است",
                title: "پاسخ تایید شده.",
            },
        },
        {
            condition: report.status === reportStatus.COMPLETED,
            content: {
                type: "success",
                body: "گزارش تکمیل شده",
                title: "پایان گزارش",
            },
        },
        {
            status: assigneeStatus.PAID,
            content: {
                type: "success",
                body: "پرداخت نهایی شما انجام شد.",
                title: "گزارش تکمیل شده.",
            },
        },
    ];
    status = checkStatus(status, assignee.status, assignee.assignee_role_id);

    return (
        <FullWidthAlert title={status[0]?.title} type={status[0]?.type}>
            <Typography>{status[0]?.body}</Typography>
        </FullWidthAlert>
    );
};

export default ExpertAlert;
