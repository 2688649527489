import React from "react";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import MapContainer from "./MapContainer";
import Mapir from "mapir-react-component";
import axios from "../../api/instance";
import vars from "../../vars";
import throttle from "lodash.throttle";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

const ReportMap = ({ data, setData, textFieldGrid, mapGrid }) => {
    const clickMap = (Map, e) => {
        const coords = e.lngLat;
        setData((state) => ({ ...state, coords: [coords.lng, coords.lat] }));
        axios
            .get(vars.mapirReverseBaseUrl + `lat=${e.lngLat.lat}&lon=${e.lngLat.lng}`, {
                withCredentials: false,
                headers: {
                    "x-api-key": vars.mapirAccessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setData((state) => ({
                    ...state,
                    address: res.data.address,
                    province: res.data?.province,
                    town: res.data?.county,
                    city: res.data?.city,
                }));
            });
    };
    const fetchAddress = React.useMemo(
        () =>
            throttle((val) => {
                axios
                    .post(
                        vars.mapirForwardBaseUrl,
                        {
                            text: val,
                        },
                        {
                            withCredentials: false,
                            headers: {
                                "Content-Type": "application/json",
                                "x-api-key": vars.mapirAccessToken,
                            },
                        }
                    )
                    .then((res) => {
                        setData((state) => ({
                            ...state,
                            values: res.data.value,
                            loading: false,
                        }));
                    });
            }, 500),
        []
    );
    const setAddress = (e, val) => {
        if (val || !data.isInitial) {
            setData((state) => ({ ...state, address: val, loading: true, values: [], isInitial: false }));
            fetchAddress(val);
        }
    };
    const setCoords = (e, v) => {
        setData((state) => ({ ...state, coords: v ? v.geom.coordinates : [] }));
    };
    return (
        <>
            <Grid item {...textFieldGrid}>
                <Autocomplete
                    renderInput={(params) => <TextField {...params} multiline label="آدرس" />}
                    options={data.values}
                    loading={data.loading}
                    onInputChange={(e, value) => setAddress(e, value)}
                    inputValue={data.address ?? ""}
                    getOptionLabel={(item) => item.address}
                    filterOptions={(item) => item}
                    clearOnBlur={false}
                    onChange={setCoords}
                />
            </Grid>
            <Grid item {...mapGrid}>
                <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "rgba(0,0,0,0.54)" }}>
                    موقعیت جغرافیایی
                </Typography>
                <MapContainer
                    userLocation
                    onClick={clickMap}
                    containerStyle={{
                        width: "100%",
                        height: 300,
                        borderRadius: 4,
                    }}
                    center={data.coords.length ? data.coords : null}
                >
                    {data.coords.length === 2 && <Mapir.Marker coordinates={data.coords} anchor="bottom" />}
                </MapContainer>
            </Grid>
        </>
    );
};

export default ReportMap;
