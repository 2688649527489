import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Grid, Divider, Button, InputBase } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import FrameworkAccordion from "./FrameworkAccordion";
import PopoverButton from "../../../components/UI/Buttons/PopoverButton";
import ReportTag from "./ReportTag";
import Styles from "./styleReportFramework.js";

import addTagInputs from "./addTagInputs";
import formCreator, { inputCreator } from "../../../shared/formCreator";

const ReportSection = (props) => {
    const classes = Styles();
    const { values, options, changeHandler, expanded } = props;
    const [tagForm, setTagForm] = React.useState({ key: "", value: "" });

    let tagFormElements = formCreator(addTagInputs({ values: tagForm }), (val, identifier) =>
        setTagForm({ ...tagForm, [identifier]: val })
    );

    return (
        <div className={classes.sectionRoot}>
            <FrameworkAccordion defaultExpanded={expanded}>
                <Grid container spacing={2} style={{ padding: 20 }} direction="row">
                    <Grid item xs={12} style={{ padding: 20 }}>
                        <InputBase
                            fullWidth
                            value={values.text}
                            multiline
                            rows={7}
                            onChange={(event) => changeHandler(event.target.value, "text")}
                            placeholder="متن مورد نظر را اینجا وارد کنید...."
                        />
                    </Grid>
                    <Divider flexItem className={classes.contentDivider} />
                    <Grid container spacing={2} style={{ paddingRight: 15 }}>
                        <Grid item>
                            <PopoverButton
                                label={
                                    <>
                                        <AddIcon />
                                        افزودن برچسب
                                    </>
                                }
                                buttonProps={{ disableElevation: true, className: classes.tagButton }}
                            >
                                <div className={classes.tagContainer}>
                                    <Grid container spacing={1}>
                                        {tagFormElements}
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            style={{ marginRight: "auto", marginTop: 10 }}
                                            onClick={() => {
                                                setTagForm({ key: "", value: "" });
                                                changeHandler([...values.tags, { ...tagForm, id: uuidv4() }], "tags");
                                            }}
                                        >
                                            ثبت
                                        </Button>
                                    </Grid>
                                </div>
                            </PopoverButton>
                        </Grid>
                        {values.tags?.map((tag, index) => (
                            <Grid item key={tag.id}>
                                <ReportTag
                                    header={tag.key}
                                    content={tag.value}
                                    onRemove={() =>
                                        changeHandler(
                                            values.tags.filter((item, itemIndex) => tag.id !== item.id),
                                            "tags"
                                        )
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Divider flexItem className={classes.contentDivider} />
                    <Grid item xs={12}>
                        {inputCreator(
                            "file-uploader",
                            values.documents ?? [],
                            (value) => changeHandler(value, "documents"),
                            {
                                label: "افزودن اسناد",
                            }
                        )}
                    </Grid>
                </Grid>
            </FrameworkAccordion>
        </div>
    );
};

export default ReportSection;
