import React from "react";
import moment from "moment-jalaali";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid, Slide, Typography, Container, Popover, IconButton } from "@material-ui/core";

import { toggleNotifications } from "../../../store/actions/notificationActions";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
    paper: {
        height: "100vh",
        backgroundColor: "#3F4049",
        borderRadius: 0,
        maxHeight: "unset",
        zIndex: -1,
    },
    titleContainer: {
        backgroundColor: "#363740",
        cursor: "pointer",
        color: "#DDE2FF",
        "&:hover": {
            color: "#000",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1.5, 1),
    },
    arrow: {
        position: "absolute",
        left: 10,
        "& svg": {
            fontSize: 18,
        },
    },
    title: {
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(16),
        color: "#DDE2FF",
    },
    list: {
        width: 300,
        padding: theme.spacing(2, 3.5),
    },
    notifContainer: {
        backgroundColor: "#363740",
        padding: theme.spacing(1, 0.5),
        borderRadius: 4,
        color: "#DDE2FF",
        marginBottom: theme.spacing(2.5),
        cursor: "pointer",
        "&:hover": {
            color: "#fff",
        },
    },
    notifText: {
        fontSize: theme.typography.pxToRem(13),
        lineHeight: 1.8,
        padding: "2px 5px 0px 5px",
        textAlign: "justify",
    },
    timeTypo: {
        color: "#DDE2FF",
        fontSize: theme.typography.pxToRem(10),
    },
}));

const NotificationDrawer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { anchor } = props;
    const user = useSelector((store) => store.user);
    const { notifications, drawerOpen } = useSelector((store) => store.notification);

    const toggleDrawer = (value) => {
        dispatch(toggleNotifications(value));
    };

    let finalNotifications = notifications.map((notification, index) => {
        let directRoute = `/${user.active_route}/report/${notification?.notificationable?.id}`;
        if (user.active_role == "Referee" || user.active_role == "Superadmin")
            directRoute = `/${user.active_route}/group/${notification?.notificationable?.group_id}/report/${notification?.notificationable?.id}`;

        return (
            <React.Fragment key={index}>
                <Typography variant="caption" className={classes.timeTypo}>
                    {moment(notification.created_at).fromNow()}
                </Typography>
                <Container className={classes.notifContainer} onClick={() => history.push(directRoute)}>
                    <Typography variant="body2" className={classes.notifText}>
                        {notification.content}
                    </Typography>
                </Container>
            </React.Fragment>
        );
    });

    return (
        <Popover
            anchorEl={anchor.current}
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
            marginThreshold={0}
            PaperProps={{ className: classes.paper }}
            TransitionComponent={Slide}
            TransitionProps={{ direction: "left" }}
        >
            <Container className={classes.titleContainer}>
                <IconButton size="medium" color="inherit" className={classes.arrow} onClick={() => toggleDrawer(false)}>
                    <ArrowForwardIosIcon />
                </IconButton>
                <Typography variant="body1" className={classes.title}>
                    اعلانات
                </Typography>
            </Container>
            <Grid container spacing={2} className={classes.list}>
                {finalNotifications}
            </Grid>
        </Popover>
    );
};

export default NotificationDrawer;
