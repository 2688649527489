import React from "react";
import { InputBase, Collapse, Paper, InputAdornment, Button, Divider, IconButton } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        borderRadius: 2,
        boxShadow: "none",
        border: "2px solid rgba(81, 182, 255, 0.4)",
        margin: "0 !important",
        display: "flex",
        flexWrap: "wrap",
    },
    expandIconContainer: {
        transform: "none!important",
    },
    detailsButton: {
        borderRadius: 0,
        backgroundColor: "rgba(81, 182, 255, 0.4)",
        color: "#454545",
        fontSize: theme.typography.pxToRem(12),
        height: 51,
        width: 100,
        fontWeight: 600,
    },
    inputBase: {
        width: "inherit",
    },
    input: {
        padding: theme.spacing(2, 4),
    },
    expandIcon: {
        transition: "all 0.5s",
    },
    divider: {
        height: 2,
        width: "100%",
        backgroundColor: "rgba(81, 182, 255, 0.4)",
    },
    contactContainer: {
        margin: theme.spacing(1, 0, 2, 0),
        width: "calc(100% + 20px)",
    },
    contactIcon: {
        backgroundColor: "rgba(164, 166, 179, 1)",
        borderRadius: 4,
        height: 10,
        width: 10,
        "& svg": {
            color: "#363740",
            fontSize: 16,
        },
        "&:hover": {
            backgroundColor: "rgba(164, 166, 179, 1)",
        },
    },
}));

const FrameworkAccordion = (props) => {
    const classes = useStyles();
    const { defaultExpanded, ...otherProps } = props;
    const [expanded, setExpanded] = React.useState(defaultExpanded ?? false);

    return (
        <Paper
            component="form"
            onSubmit={(event) => {
                event.preventDefault();
            }}
            className={classes.root}
        >
            <Autocomplete
                options={[{ title: "شی", value: "asd" }]}
                getOptionLabel={(option) => option.title}
                fullWidth
                renderInput={(params) => (
                    <div style={{ width: "100%" }} ref={params.InputProps.ref}>
                        <InputBase
                            className={classes.inputBase}
                            fullWidth
                            placeholder="عنوان مورد نظر را وارد کنید"
                            {...params.inputProps}
                            inputProps={{ className: classes.input }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        style={{
                                            backgroundColor: "rgba(81, 182, 255, 0.2)",
                                            borderRadius: 2,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        style={{
                                            backgroundColor: "rgba(81, 182, 255, 0.2)",
                                            borderRadius: 2,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                    <Button
                                        disableElevation
                                        variant="contained"
                                        size="large"
                                        className={classes.detailsButton}
                                        onClick={() => setExpanded(!expanded)}
                                    >
                                        {expanded ? (
                                            <>
                                                بستن
                                                <ExpandMoreIcon
                                                    className={classes.expandIcon}
                                                    style={{ transform: "rotate(180deg)" }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                جزئیات
                                                <ExpandMoreIcon className={classes.expandIcon} />
                                            </>
                                        )}
                                    </Button>
                                </InputAdornment>
                            }
                        />
                    </div>
                )}
            />
            <Collapse in={expanded} style={{ width: "100%" }}>
                <Divider className={classes.divider} flexItem />
                {props.children}
            </Collapse>
        </Paper>
    );
};

export default FrameworkAccordion;
