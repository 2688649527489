import React from "react";
import { defaults } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";

import BarChart from "./BarChart";
import PieChart from "./PieChart";
import DoughnutChart from "./DoughnutChart";
import LineChart from "./LineChart";

defaults.global.defaultFontFamily = "YekanBakh";

const ChartsContainer = (props) => {
    // each object in charts array should have a type , title and data
    const { charts, loading } = props;

    let chartNodes = charts.map(({ type, title, data }, index) => {
        switch (type) {
            case "Line":
                return <LineChart data={data} title={title} />;
            case "Bar":
                return <BarChart data={data} title={title} />;
            case "Pie":
                return <PieChart data={data} title={title} />;
            case "Doughnut":
                return <DoughnutChart data={data} title={title} />;
            default:
                return "";
        }
    });

    return (
        <Grid container spacing={2}>
            {chartNodes.map((node, index) => (
                <Grid item key={index} xs={6} md={4}>
                    {node}
                </Grid>
            ))}
        </Grid>
    );
};

export default ChartsContainer;
