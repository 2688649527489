import React from "react";
import { useDispatch, useSelector } from "react-redux";

import LinearLoading from "../../../components/Static/LinearLoading/LinearLoading";
import DashboardCards from "../../../components/Dashboard/Cards/DashboardCards";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import ReportsTable from "../../../components/Tables/ReportsTable/ReportsTable";
import LinkButton from "../../../components/UI/Buttons/LinkButton";

import { fetchReports } from "../../../store/actions/reportsActions.js";
import { reportStatus } from "../../../shared/checkStatus.js";
import ChartsContainer from "../../../components/Charts/ChartsContainer";
import { fetchCharts } from "../../../api/userAPI";

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const [charts, setCharts] = React.useState([]);
    const { loading, reports } = useSelector((store) => store.reports);

    React.useEffect(() => {
        dispatch(fetchReports(true));
        fetchCharts().then((response) => setCharts(response.data));
    }, []);

    return (
        <MainContainer>
            <ChartsContainer
                charts={[
                    { type: "Bar", data: charts.MonthReportChart, title: "تعداد گزارش / ماه" },
                    { type: "Bar", data: charts.StatusReportChart, title: "تعداد گزارش / وضعیت" },
                    { type: "Bar", data: charts.ExpertsReportChart, title: "تعداد گزارش / تعداد اعضای هیات کارشناسی" },
                ]}
            />
            <DashboardCards
                cards={[
                    {
                        title: "تکمیل شده",
                        value: reports?.filter((item) => item.status === reportStatus.COMPLETED).length,
                        color: "green",
                    },
                    {
                        title: "در حال ارجاع",
                        value: reports?.filter((item) => item.status === reportStatus.ASSIGNING).length,
                        color: "blue",
                    },
                    {
                        title: "در حال نگارش",
                        value: reports?.filter((item) => item.status === reportStatus.IN_PROGRESS).length,
                        color: "yellow",
                    },
                ]}
                loading={loading}
                // showDashboardButton
            />

            {loading ? <LinearLoading width={200} title="در حال بارگیری" /> : <ReportsTable reports={reports} />}

            <div style={{ marginTop: "10%" }}>
                <LinkButton
                    component="a"
                    href="https://edalatkhane.org/mag/%d8%b1%d8%a7%d9%87%d9%86%d9%85%d8%a7-%d8%b3%d8%a7%d9%85%d8%a7%d9%86%d9%87-%da%a9%d8%a7%d8%b1%d8%b4%d9%86%d8%a7%d8%b3-%d8%b1%d8%b3%d9%85%db%8c-%d8%b9%d8%af%d8%a7%d9%84%d8%aa%d8%ae%d8%a7%d9%86%d9%87/"
                    target="_blank"
                    label="آموزش استفاده از سامانه کارشناس رسمی برای کارشناسان(متخصصین)"
                />
                <LinkButton
                    component="a"
                    href="https://edalatkhane.org/mag/%d8%b3%d8%a7%d9%85%d8%a7%d9%86%d9%87-%da%a9%d8%a7%d8%b1%d8%b4%d9%86%d8%a7%d8%b3-%d8%b1%d8%b3%d9%85%db%8c-%da%a9%d8%a7%d8%b1%d8%a8%d8%b1%d8%a7%d9%86-%d8%b3%d8%a7%d8%b2%d9%85%d8%a7%d9%86%db%8c/"
                    target="_blank"
                    label="آموزش استفاده از سامانه کارشناس رسمی برای کاربران سازمانی"
                />
            </div>
        </MainContainer>
    );
};

export default Dashboard;
