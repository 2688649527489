import React from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";

const PopoverButton = (props) => {
    const { label, buttonProps } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <div>
            <Button variant="contained" onClick={handleClick} {...buttonProps}>
                {label}
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {props.children}
            </Popover>
        </div>
    );
};

export default PopoverButton;
