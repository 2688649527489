import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: { width: "100%" },
    actionButton: {
        height: 45,
        padding: theme.spacing(1, 3),
        margin: theme.spacing(1),
        borderRadius: 12,
        fontSize: 12,
        backgroundColor: "#fff",
        "&:nth-of-type(odd)": {
            border: "2px solid rgba(138,230,190,255)",
        },
        "&:nth-of-type(even)": {
            border: "2px solid rgba(138,197,230,255)",
        },
        "&:hover": {
            backgroundColor: "rgba(240,240,240)",
        },
    },
}));

const LinkButton = (props) => {
    const classes = useStyles();
    const { label, onClick, badge, ...otherProps } = props;

    return (
        <Button variant="contained" disableElevation className={classes.actionButton} onClick={onClick} {...otherProps}>
            {label}
        </Button>
    );
};

export default LinkButton;
