import axios from "../../api/instance";

export const TOGGLE_NOTIFICATIONS = "OPEN_NOTIFICATIONS";
export const SET_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const SEEN_NOTIFICATION = "SEEN_NOTIFICATION";
export const TOGGLE_LOADING_NOTIFICATION = "TOGGLE_LOADING_NOTIFICATION";

export const toggleNotifications = (value) => ({ type: TOGGLE_NOTIFICATIONS, value: value });
export const toggleLoading = (value) => ({ type: TOGGLE_LOADING_NOTIFICATION, value: value });
export const setNotifications = (notifications) => ({ type: SET_NOTIFICATIONS, value: notifications });

export const fetchNotifications = () => {
    return (dispatch) => {
        dispatch(toggleLoading(true));
        axios
            .get("/users/notifications")
            .then((response) => {
                dispatch(setNotifications(response.data.data));
                dispatch(toggleLoading(false));
            })
            .catch((error) => {
                dispatch(toggleLoading(false));
                console.log("problem fetching notifications");
            });
    };
};
