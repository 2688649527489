import React from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";

import LoadingButton from "../../../../components/UI/Buttons/LoadingButton";
import uploadResponseInputs from "./uploadResponseInputs";

import { uploadResponse } from "../../../../api/reportsAPI";
import { showAlert } from "../../../../store/actions/snackbarActions";
import formCreator from "../../../../shared/formCreator";
import validateForm from "../../../../shared/validateForm";
import getFormData from "../../../../shared/getFormData";

const UploadResponse = (props) => {
    const dispatch = useDispatch();
    const { assignees, assignee, report, changeAssigneeData } = props;

    const [formData, setFormData] = React.useState({
        estimation: "",
        wages: "",
    });
    const [checkForm, setCheckForm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const acceptedAssignees = assignees.filter((item) => item.assigne_role_id !== "SUPERVISOR");
    React.useEffect(() => {
        let wages = {};
        let names = {};
        acceptedAssignees.forEach((item) => {
            wages[item.id] = item.wage ?? "";
            names[item.id] = `${item.name} ${item.surname}`;
        });
        setFormData({
            estimation: assignees.find((item) => item.estimation)?.estimation ?? "",
            wages: wages,
            names: names,
        });
    }, []);

    const sendData = (event) => {
        event.preventDefault();
        setCheckForm(true);
        if (formData.documents?.length === 0 || !formData.documents) {
            dispatch(showAlert("شما پاسخی آپلود نکردید", "error"));
            return;
        }
        const validatedForm = validateForm(uploadResponseInputs(formData));
        if (validatedForm.error === false) {
            setLoading(true);
            uploadResponse(getFormData({ ...formData, report_id: report.id })).then((response) => {
                dispatch(showAlert(response.message, response.type));
                setLoading(false);
                if (response.type === "success") {
                    changeAssigneeData({
                        status: report.is_person ? "RESPONDED" : "RESPONDED",
                        estimation: formData.estimation,
                        wage: formData.wages[assignee.id],
                        response: {
                            documents: formData?.documents.map((file) => {
                                return {
                                    link: URL.createObjectURL(file),
                                    name: file.name,
                                    mime_type: file.type,
                                };
                            }),
                        },
                    });
                }
            });
        }
    };

    const inputChangedHandler = (val, inputIdentifier) => {
        if (!isNaN(inputIdentifier)) {
            let wages = formData["wages"];
            wages[inputIdentifier] = val;
            setFormData((state) => ({ ...state, wages: wages }));
        } else {
            setFormData((state) => ({ ...state, [inputIdentifier]: val }));
        }
    };

    let formElements = formCreator(uploadResponseInputs(formData), inputChangedHandler);
    if (checkForm) {
        formElements = formCreator(validateForm(uploadResponseInputs(formData)).data, inputChangedHandler);
    }
    return (
        <form onSubmit={sendData}>
            <Grid container spacing={2}>
                {formElements}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
                <LoadingButton
                    disableElevation
                    style={{ width: 120 }}
                    loading={loading}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    ثبت پاسخ و حق‌الزحمه
                </LoadingButton>
            </Grid>
        </form>
    );
};

export default UploadResponse;
