import React from "react";
import { SvgIcon } from "@material-ui/core";

const FileIcon = (props) => {
    return (
        <SvgIcon {...props} width="23" height="27" viewBox="0 0 23 27" fill="none">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.161 1.01591H6.17799C3.36953 1.00511 1.06741 3.24377 1.00125 6.05088V20.5158C0.939139 23.3688 3.20076 25.733 6.05377 25.7965C6.09563 25.7965 6.13613 25.7978 6.17799 25.7965H16.9649C19.7923 25.6817 22.0201 23.3458 21.9999 20.5158V8.13967L15.161 1.01591Z"
                stroke={props.color}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
            />
            <path
                d="M14.8064 1V4.92779C14.8064 6.8451 16.3564 8.3992 18.2738 8.4046H21.9936"
                stroke={props.color}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};

export default FileIcon;
