import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    conversationHeader: {
        backgroundColor: "#54617A",
        borderRadius: theme.spacing(1, 1, 0, 0),
        padding: theme.spacing(0.5, 4),
        height: "fit-content",
        minHeight: 70,
    },
    conversationInfoBtnContainer: {
        textAlign: "left",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    conversationTitleContainer: {
        display: "flex",
        alignItems: "center",
        paddingright: 10,
        height: 50,
        overflow: "hidden",
    },
    headerContent: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    avatar: {
        width: theme.spacing(5.5),
        height: theme.spacing(5.5),
    },
    nameTypo: {
        color: "#DDE2FF",
        width: "100%",
        fontWeight: 700,
        textAlign: "left",
        fontSize: theme.typography.pxToRem(12),
        marginLeft: 5,
        whiteSpace: "nowrap",
    },
    roleTypo: {
        color: "#A4A6B3",
        width: "100%",
        fontWeight: 400,
        textAlign: "justify",
        fontSize: theme.typography.pxToRem(10),
        marginLeft: 5,
        whiteSpace: "nowrap",
    },
    expandButton: {
        marginLeft: 10,
        padding: 3,
        border: "2px solid #D3D9E5",
        color: "#D3D9E5",
    },
}));

export default useStyles;
