import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import DataTable from "../../../../components/Tables/DataTable/DataTable";
import FilePreview from "../../../../components/UI/Image/FilePreview";
import PriceTypo from "../../../../components/UI/Typography/PriceTypo";
import TableRadiusBox from "../../../../components/UI/Containers/TableRadiusBox";

const useStyles = makeStyles((theme) => ({
    container: {},
    head: {
        color: theme.palette.table.main,
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#DEDEDE",
        border: "1px solid #DEDEDE",
        padding: theme.spacing(0.5, 2),
        whiteSpace: "nowrap",
    },
    row: {
        color: theme.palette.table.main,
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        border: "1px solid #DEDEDE",
        whiteSpace: "nowrap",
        "&:nth-child(3)": {
            align: "right",
        },
    },
    priceContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
}));

const EstimationDialog = (props) => {
    const { assignees } = props;
    const classes = useStyles();

    const acceptedAssignees = assignees.filter(
        (assignee) => assignee.status !== "REJECTED" && assignee.assignee_role_id !== "SUPERVISOR"
    );
    const documents = acceptedAssignees?.find((item) => item.response)?.response?.documents;

    const tableBody = acceptedAssignees.map((assignee) => ({
        data: {
            name: `نام: ${assignee.name}`,
            surname: `نام خانوادگی: ${assignee.surname}`,
            wage: (
                <div className={classes.priceContainer}>
                    حق‌الزحمه: <PriceTypo>{assignee.wage}</PriceTypo>
                </div>
            ),
        },
        config: { className: classes.row },
    }));
    return (
        <TableRadiusBox>
            {assignees && (
                <DataTable
                    body={[
                        {
                            data: {
                                first: "اطلاعات پاسخ گزارش",
                            },
                            config: { className: classes.head, colspan: 3 },
                        },
                        {
                            data: {
                                first: (
                                    <div className={classes.priceContainer}>
                                        برآورد:
                                        <PriceTypo>
                                            {assignees.find((item) => item?.estimation !== null)?.estimation}
                                        </PriceTypo>
                                    </div>
                                ),
                            },
                            config: { className: classes.row, colspan: 3 },
                        },
                        ...tableBody,
                        {
                            data: {
                                documents: documents && (
                                    <>
                                        اسناد پاسخ
                                        <Grid container spacing={1} style={{ marginTop: 10 }} justify="flex-end">
                                            {documents?.map((file, index) => {
                                                return (
                                                    <Grid item xs={6} sm={4} lg={3} key={index}>
                                                        <FilePreview
                                                            src={file.link}
                                                            label={file.name}
                                                            onClick={() => window.open(file.link)}
                                                            type={file.mime_type}
                                                            icon="download"
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </>
                                ),
                            },
                            config: { className: classes.row, colspan: 3 },
                        },
                    ]}
                    rowsPerPage={0}
                />
            )}
        </TableRadiusBox>
    );
};

export default EstimationDialog;
