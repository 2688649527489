import React from "react";
import { useDispatch, useSelector } from "react-redux";

import LinearLoading from "../../../components/Static/LinearLoading/LinearLoading";
import DashboardCards from "../../../components/Dashboard/Cards/DashboardCards";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import ChartsContainer from "../../../components/Charts/ChartsContainer";

import { fetchReports } from "../../../store/actions/reportsActions.js";
import { assigneeStatus, reportStatus } from "../../../shared/checkStatus.js";
import ReportsTable from "../../../components/Tables/ReportsTable/ReportsTable";
import { fetchCharts } from "../../../api/userAPI";

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const [charts, setCharts] = React.useState([]);
    const { loading, reports } = useSelector((store) => store.reports);

    React.useEffect(() => {
        dispatch(fetchReports(true));
        fetchCharts().then((response) => setCharts(response.data));
    }, []);

    return (
        <MainContainer>
            <ChartsContainer
                charts={[
                    { type: "Bar", data: charts.MonthReportChart, title: "تعداد گزارش / ماه" },
                    { type: "Bar", data: charts.StatusReportChart, title: "تعداد گزارش / وضعیت" },
                    { type: "Line", data: charts.WageReportChart, title: "تعداد گزارش / حق الزحمه" },
                ]}
            />
            <DashboardCards
                cards={[
                    {
                        title: "تکمیل شده",
                        value: reports?.filter((item) => item.status === reportStatus.COMPLETED).length,
                        color: "green",
                    },
                    {
                        title: "پیشنهادی",
                        value: reports?.filter((item) => item.assignee_status === assigneeStatus.NO_ACTION).length,
                        color: "blue",
                    },
                    {
                        title: "در حال نگارش",
                        value: reports?.filter((item) => item.status === reportStatus.IN_PROGRESS).length,
                        color: "yellow",
                    },
                ]}
                loading={loading}
            />
            {loading ? <LinearLoading width={200} title="در حال بارگیری" /> : <ReportsTable reports={reports} />}
        </MainContainer>
    );
};

export default Dashboard;
