import React from "react";

const LogoIcon = ({ fill }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <svg fill={fill} width="43" height="12" viewBox="0 0 43 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.365967 11.6293C0.423692 11.4192 0.481802 11.2091 0.549148 10.999C1.48584 8.05739 3.19528 5.5817 5.6767 3.59933C7.64668 2.02805 9.89682 0.968348 12.4075 0.411094C13.4793 0.173572 14.5703 0.0456766 15.6713 0.0182704C16.2412 0.00913509 16.8012 0 17.3707 0C24.0149 0 30.6487 0 37.2925 0C37.8817 0 38.4417 0.082218 38.9824 0.319737C39.6581 0.630339 40.1603 1.11451 40.5563 1.70831C41.0101 2.4026 41.4449 3.10602 41.8987 3.80031C41.9568 3.90082 42.0145 4.0013 42.0918 4.09262C42.3331 4.3941 42.3912 4.73214 42.3524 5.09754C42.285 5.69134 42.0534 6.21207 41.5992 6.63227C41.2229 6.98855 40.7495 7.17127 40.2281 7.21695C40.0637 7.23523 39.8902 7.23523 39.7262 7.23523C29.4607 7.23523 19.2056 7.23523 8.94089 7.23523C6.59415 7.23523 4.4891 7.92035 2.6542 9.29064C1.80448 9.93016 1.08022 10.6792 0.491418 11.538C0.471791 11.5745 0.442932 11.6019 0.42369 11.6293C0.385206 11.6293 0.375588 11.6293 0.365967 11.6293Z" />
            </svg>
            <svg fill={fill} width="53" height="10" viewBox="0 0 53 10" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.407227 9.60899C0.76474 8.86903 1.16998 8.18387 1.64333 7.52614C3.34276 5.14181 5.56365 3.33301 8.316 2.118C9.60982 1.54248 10.9618 1.14964 12.3718 0.921276C13.3181 0.765956 14.2644 0.701996 15.23 0.701996C26.1324 0.701996 37.0344 0.701996 47.9276 0.701996C48.5937 0.701996 49.231 0.811636 49.8198 1.1314C50.3898 1.44196 50.8343 1.88048 51.1718 2.41036C51.6259 3.09549 52.0603 3.78977 52.4948 4.48405C52.5626 4.59369 52.6299 4.70333 52.7073 4.80381C52.9201 5.07785 52.9874 5.38846 52.9585 5.71734C52.9104 6.3477 52.6688 6.89578 52.1958 7.33426C51.819 7.68142 51.3457 7.86414 50.8246 7.90983C50.6599 7.91895 50.496 7.92811 50.332 7.92811C36.2236 7.92811 22.1155 7.92811 8.00697 7.92811C5.37085 7.92811 2.86017 8.43967 0.4942 9.57243C0.464953 9.59075 0.44571 9.59987 0.407227 9.60899Z" />
            </svg>
        </div>
    );
};

export default LogoIcon;
