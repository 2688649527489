import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    ticketListNoData: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    root: {
        height: "calc(100% - 100px)",
        overflowY: "scroll",
        overflowX: "hidden",
        padding: theme.spacing(4),
        backgroundColor: "#fff",
    },
}));

export default useStyles;
