import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";

import MobileAppBar from "../components/AppBars/MobileAppBar/MobileAppBar";
import SideBar from "../components/AppBars/SideBar/SideBar";
import Breadcrumbs from "../components/Static/BreadCrumbs/BreadCrumbs";
import { useRoutes } from "./routes";

import Profile from "../pages/Profile/Profile";
import WageCalculator from "../pages/WageCalculator/WageCalculator";
import WageFactor from "../pages/WageCalculator/WageFactor";

const AppRoutes = (props) => {
    const theme = useTheme();
    const routes = useRoutes();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const [openSidebar, setOpenSidebar] = React.useState(true);
    const user = useSelector((store) => store.user);

    return (
        <div
            style={{
                transition: "all .5s",
                width: isMobile ? "100%" : openSidebar ? "calc(100% -  250px" : "calc(100% - 140px)",
                marginRight: isMobile ? 0 : openSidebar ? 250 : 140,
                marginBottom: isMobile ? "15vw" : 0,
            }}
        >
            {isMobile ? (
                <MobileAppBar />
            ) : (
                <React.Fragment>
                    <Breadcrumbs />
                    <SideBar
                        open={openSidebar}
                        setOpenSidebar={setOpenSidebar}
                        onClick={() => setOpenSidebar((flag) => !flag)}
                        user={user}
                    />
                </React.Fragment>
            )}

            <React.Suspense fallback={""}>
                <Switch>
                    <Route path="/wagecalculator" component={WageCalculator} />
                    <Route path="/wagefactor" component={WageFactor} />
                    <Route path="/profile" component={Profile} />
                    {routes.map((route, key) => {
                        if (route.role === "all" || route.role === user.active_role) {
                            return <Route key={key} path={route.path} component={route.component} />;
                        }
                    })}
                    <Route path="/">
                        <Redirect to={`/${user.active_route}/dashboard`} />
                    </Route>
                </Switch>
            </React.Suspense>
        </div>
    );
};

export default AppRoutes;
