import { makeStyles } from "@material-ui/core/styles";
import BG from "./../../assets/images/headerBG.jpg";

export default makeStyles({
    expertIntroHeader: {
        backgroundImage: `url('${BG}')`,
        backgroundSize: "cover",
        height: 600,
        borderRadius: "0 0 20px 20px",
    },
});
