import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    sectionRoot: {
        margin: theme.spacing(3, 0),
    },
    container: {
        backgroundColor: "#F6F6F6",
        display: "flex",
        borderRadius: 2,
        width: "fit-content",
        position: "relative",
        border: "2px solid #C4C4C4",
    },
    typo: {
        padding: theme.spacing(1, 2.5, 1, 2.5),
        fontSize: theme.typography.pxToRem(15),
        color: "#717780",
    },
    dividerRotated: {
        backgroundColor: "#C4C4C4",
        width: 2,
        transform: "rotate(-20deg)",
    },
    icon: {
        position: "absolute",
        color: "#000",
        right: -2,
        top: -12,
        zIndex: 20,
        border: "2px solid #C4C4C4",
        backgroundColor: "#FFFFFF !important",
        padding: 2,
        "& svg": {
            fontSize: 14,
        },
    },
    contentDivider: {
        height: 2,
        width: "calc(100% + 24px)",
        margin: "20px 0px 20px -12px",
        backgroundColor: "rgba(81, 182, 255, 0.4)",
    },
    tagContainer: {
        minHeight: 150,
        minWidth: 250,
        padding: 20,
    },
    tagButton: {
        borderRadius: 2,
        backgroundColor: "#EBEBEB",
        color: "#666B73",
        fontSize: theme.typography.pxToRem(15),
        // border :"2px solid #828890"
    },
    newSectionButton: {
        color: "#666B73",
        backgroundColor: "#fff",
        borderRadius: 2,
        border: "2px solid rgba(81, 182, 255, 0.4)",
        padding: 15,
        fontWeight: 600,
    },
}));
