import React from "react";
import axios from "../../../api/instance";

import { Button, CircularProgress, TextField, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { showAlert } from "../../../store/actions/snackbarActions";
import { toEnNumber } from "../../../shared/toEnNumber";

import styles from "./LoginFormStyle";

const LoginForm = (props) => {
    const classes = styles();
    const dispatch = useDispatch();

    const [formData, setFormData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const changeInput = (e) => {
        let val = e.target.value.replace(/[^0-9 | ^۰|۱|۲|۳|۴|۵|۶|۷|۸|۹]+/g, "");
        setFormData({ ...formData, [e.target.id]: toEnNumber(val) });
    };
    const submitForm = (e) => {
        e.preventDefault();
        if (formData.mobile_number) {
            setLoading(true);
            axios
                .post("/auth/login", formData)
                .then((response) => {
                    dispatch(showAlert("کد تایید با موفقیت ارسال شد", "success"));
                    setLoading(false);
                    dispatch({ type: "SET_TIME", time: response.data.remaining_time });
                    props.changeStep(formData.mobile_number, "validate");
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.data.mobile_exists === false) {
                            props.changeStep(formData.mobile_number, "register");
                        }
                    }
                });
        } else {
            dispatch(showAlert("ساختار موبایل وارد شده صحیح نمیباشد.", "error"));
        }
    };
    return (
        <form onSubmit={submitForm} style={{ width: "100%", marginTop: 10 }}>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12} className={classes.inputFieldContainer}>
                    <TextField
                        InputProps={{
                            className: classes.input,
                        }}
                        value={formData.mobile_number}
                        fullWidth
                        size="small"
                        placeholder="شماره موبایل"
                        variant="outlined"
                        id="mobile_number"
                        onChange={changeInput}
                    />
                </Grid>
                <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.inputFieldContainer}
                    style={{ paddingTop: 0, marginTop: 5 }}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            fontSize: 15,
                            fontFamily: "IRANSansBold",
                            backgroundColor: "#9A44FC",
                        }}
                        onClick={submitForm}
                        disabled={loading}
                        type="submit"
                    >
                        {loading ? <CircularProgress size={26} color="inherit" /> : "دریافت کد تایید"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default LoginForm;
