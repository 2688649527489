import axios from "./instance";

export const getResponses = (reportId) => {
    return axios
        .get("/reports/responses", {
            params: {
                report_id: reportId,
            },
        })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            console.log(error?.response?.data);
            return [];
        });
};

export const createTask = (payload) => {
    return axios
        .post("/reports/tasks", payload)
        .then((response) => {
            return { data: response?.data, message: response?.data?.message, type: "success" };
        })
        .catch((error) => {
            return { data: error.response?.data, message: error?.response?.data?.message, type: "error" };
        });
};

export const getFilterReports = (payload) => {
    return axios
        .get("/reports/filter", {
            params: payload,
        })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            console.log(error?.response?.data);
            return [];
        });
};

export const uploadResponse = (payload) => {
    return axios
        .post("/reports/response", payload)
        .then((response) => {
            return { data: response?.data, message: response?.data?.message, type: "success" };
        })
        .catch((error) => {
            return { data: error.response?.data, message: error?.response?.data?.message, type: "error" };
        });
};

export const sendMessage = (payload) => {
    return axios
        .post("/reports/comments/send", payload)
        .then((response) => {
            return { data: response?.data?.data, message: response?.data?.message, type: "success" };
        })
        .catch((error) => {
            return { data: error.response?.data, message: error?.response?.data?.message, type: "error" };
        });
};

export const fetchMessages = (reportId) => {
    return axios
        .get("/reports/comments?report_id=" + reportId)
        .then((response) => {
            return { data: response?.data?.data, message: response?.data?.message, type: "success" };
        })
        .catch((error) => {
            return { data: [], message: error?.response?.data?.message, type: "error" };
        });
};
