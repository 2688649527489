import React from "react";
import moment_jalaali from "moment-jalaali";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import DataTable from "../DataTable/DataTable";
import TableRadiusBox from "../../UI/Containers/TableRadiusBox";

const useStyles = makeStyles((theme) => ({
    container: {},
    head: {
        color: theme.palette.table.main,
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#DEDEDE",
        border: "1px solid #DEDEDE",
        padding: theme.spacing(0.5, 2),
    },
    row: {
        color: theme.palette.table.main,
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        border: "1px solid #DEDEDE",
        width: "50%",
    },
}));

const LicenseTable = (props) => {
    const classes = useStyles();
    const { license, append = [] } = props;
    const provinces = useSelector((store) => store.geo);
    const province = provinces.find((item) => item.id === license?.province_id);
    const town = province?.towns.find((item) => item.id === license?.town_id);
    const city = town?.cities.find((item) => item.id === license?.city_id);

    return (
        <TableRadiusBox className={classes.container}>
            <DataTable
                body={[
                    {
                        data: { first: "پروانه کارشناس" },
                        config: {
                            className: classes.head,
                            colspan: "2",
                        },
                    },
                    {
                        data: {
                            first: `شماره پروانه: ${license.license_number ?? "____"}`,
                            second: `رشته: ${license.expertise ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    {
                        data: {
                            first: `اعتبار: ${moment_jalaali(license.expiration_date).format("jYYYY/jM/jD") ?? "____"}`,
                            second: `تاریخ صدور: ${
                                license.issued_date ? moment_jalaali(license.issued_date).format("jYYYY/jM/jD") : "____"
                            }`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    {
                        data: {
                            first: (
                                <>
                                    صلاحیت:{" "}
                                    {license?.competences?.length > 0 ? (
                                        <ul style={{ textAlign: "right" }}>
                                            {license.competences.map((competence) => (
                                                <li>{competence.competence}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        "(صلاحیتی ثبت نشده)"
                                    )}
                                </>
                            ),
                        },
                        config: {
                            className: classes.row,
                            colspan: "2",
                        },
                    },
                    {
                        data: {
                            first: `استان: ${province?.name ?? "____"}`,
                            second: `شهرستان: ${town?.name ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    {
                        data: {
                            first: `شهر: ${city?.name ?? "____"}`,
                            second: `آدرس: ${license?.address ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    ...append.map((element, index) => ({
                        ...element,
                        config: { ...element.config, className: classes.row },
                    })),
                ]}
            />
        </TableRadiusBox>
    );
};

export default LicenseTable;
