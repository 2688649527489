import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  cardRoot: {
    backgroundColor: "#303033",
    borderRadius: 5,
    position: "relative",
  },
  title: {
    padding: 8,
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#292929",
  },
  LoginFormContainer: {
    backgroundColor: "#4444444a",
    boxShadow: "0 8px 14px 2px #0000003d",
    // width: "35%",
    padding: 20,
    borderRadius: 20,
  },
  mobileLoginFormContainer: {
    backgroundColor: "#4444444a",
    boxShadow: "0 8px 14px 2px #0000003d",
    width: "83%",
    padding: 20,
    borderRadius: 20,
    marginBottom: 20,
  },
  inputFieldContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      // margin: 10,
      // width: "50%",
    },
  },
  input: {
    backgroundColor: "#fff",
  },
  remainingBtn: {
    fontSize: 15,
    "&:disabled": {
      borderColor: "#caebfb",
      color: "#caebfb",
    },
  },
});
