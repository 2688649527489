import React from "react";
import axios from "../../../api/instance";
import styles from "./LoginFormStyle";
import { Button, CircularProgress, TextField, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import { showAlert } from "../../../store/actions/snackbarActions";
import { updateUserInformation } from "../../../store/actions/userActions";

const ValidateForm = ({ phoneNumber, changeStep }) => {
    const dispatch = useDispatch();
    const classes = styles();
    const [formData, setFormData] = React.useState({
        mobile_number: phoneNumber,
    });
    const [loading, setLoading] = React.useState(false);
    const [remTime, setRemTime] = React.useState(120);
    const changeInput = (e) => {
        let val = e.target.value.replace(/[^0-9 | ^۰|۱|۲|۳|۴|۵|۶|۷|۸|۹]+/g, "");
        setFormData({ ...formData, [e.target.id]: val });
    };
    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (remTime > 0) {
                setRemTime(remTime - 1);
            }
        }, 1000);
    }, [remTime]);

    const submitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post("/auth/login/validate", formData)
            .then((response) => {
                setLoading(false);
                dispatch(updateUserInformation(response.data.data));
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    const resendCode = () => {
        setLoading(true);
        axios
            .post("/auth/login", formData)
            .then((response) => {
                dispatch(showAlert("کد تایید با موفقیت ارسال شد", "success"));
                setLoading(false);
                setRemTime(response.data.remaining_time);
            })
            .catch((err) => {
                setLoading(false);
                if (err.response) {
                    if (err.response.data.mobile_exists === false) {
                        changeStep(formData.mobile_number, "register");
                    }
                }
            });
    };
    return (
        <>
            <Button
                onClick={() => changeStep(phoneNumber, "login")}
                style={{
                    position: "absolute",
                    color: "#fff",
                    top: 0,
                    left: 0,
                    zIndex: 10,
                }}
                endIcon={<ArrowBackIcon />}
            >
                بازگشت
            </Button>
            <form onSubmit={submitForm} style={{ width: "100%", marginTop: 10 }}>
                <Grid container spacing={1}>
                    <Grid item md={12} xs={12} className={classes.inputFieldContainer}>
                        <TextField
                            InputProps={{
                                className: classes.input,
                            }}
                            fullWidth
                            value={formData.verification_code}
                            size="small"
                            placeholder="کد تایید"
                            variant="outlined"
                            id="verification_code"
                            onChange={changeInput}
                        />
                    </Grid>
                    <Grid item md={12} xs={12} className={classes.inputFieldContainer} style={{ paddingTop: 0 }}>
                        <Grid container spacing={2} style={{ marginTop: 5 }}>
                            <Grid item xs={12} md={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        fontSize: 15,
                                        fontFamily: "IRANSansBold",
                                        backgroundColor: "#9A44FC",
                                    }}
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={26} color="inherit" /> : "تایید کد ارسال شده"}
                                </Button>
                            </Grid>
                            <Grid xs={12} md={6} style={{ display: "flex" }}>
                                <Button
                                    onClick={resendCode}
                                    className={classes.remainingBtn}
                                    disabled={remTime > 0 ? true : false}
                                    fullWidth
                                    color="primary"
                                >
                                    {remTime > 0 ? remTime + " ثانیه تا ارسال مجدد" : "ارسال مجدد کد"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default ValidateForm;
