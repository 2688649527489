import React from "react";
import axios from "../../../../api/instance";
import GetAppIcon from "@material-ui/icons/GetApp";

import LinearLoading from "../../../../components/Static/LinearLoading/LinearLoading";
import MainContainer from "../../../../components/UI/Containers/MainContainer";
import FilterContainer from "../../../../components/Search/Filter/FilterContainer";
import SearchBar from "../../../../components/Search/SearchBar/SearchBar";
import ReportsTable from "../../../../components/Tables/ReportsTable/ReportsTable";
import NoContent from "../../../../components/Static/NoContent/NoContent";
import Button from "../../../../components/UI/Buttons/LoadingButton";
import Styles from "../../../Referee/Search/Report/styleSearchReport";

import { searchFilters, filtersObject } from "./searchFilters";
import { getFilterReports } from "../../../../api/reportsAPI";
import { dateFixer } from "../../../../shared/toEnNumber";
import { fixReport } from "../../../../store/actions/reportsActions";

const SearchPage = (props) => {
    const classes = Styles();
    const [inputValue, setInputValue] = React.useState("");
    const [pageFilters, setPageFilters] = React.useState(filtersObject);
    const [reports, setReports] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [downloadUrl, setDownloadurl] = React.useState("");

    const inputChangedHandler = (value, filterIdentifier) => {
        let updatedFilters = { ...pageFilters };
        updatedFilters[filterIdentifier].value = value;
        setPageFilters(updatedFilters);
    };

    const searchHandler = () => {
        let filters = {};
        setLoading(true);
        setReports([]);

        if (inputValue) filters["name"] = inputValue;
        for (let key in pageFilters) {
            const element = pageFilters[key];
            if (element.elementType === "province-fields") {
                for (let addKey in pageFilters[key].value)
                    if (pageFilters[key].value[addKey]) filters[addKey] = pageFilters[key].value[addKey];
            } else if (element.elementType === "date-range") {
                if (element.value.before) filters[key + "_before"] = dateFixer(pageFilters[key].value.before);
                if (element.value.after) filters[key + "_after"] = dateFixer(pageFilters[key].value.after);
            } else if (element.elementType === "text-range") {
                if (element.value.before) filters[key + "_before"] = pageFilters[key].value.before;
                if (element.value.after) filters[key + "_after"] = pageFilters[key].value.after;
            } else if (element.elementConfig.options?.length === 2) {
                if (element.value.length === 1) filters[key] = element.value[0];
            } else if (element.value) {
                if (!(Array.isArray(element.value) && element.value.length === 0)) filters[key] = element.value;
            }
        }
        setDownloadurl(
            axios.defaults.baseURL +
                "/reports/filter?" +
                Object.keys(filters)
                    .map((key) => key + "=" + filters[key])
                    .join("&") +
                "&excel=1"
        );
        getFilterReports(filters).then((response) => {
            setReports(response);
            setLoading(false);
        });
    };

    return (
        <MainContainer noBottom>
            <FilterContainer
                header={
                    <React.Fragment>
                        <SearchBar
                            value={inputValue}
                            onChange={(event) => setInputValue(event.target.value)}
                            onSubmit={searchHandler}
                            placeholder="جستجو در نام گزارش و دسته‌بندی"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disableElevation
                            onClick={searchHandler}
                            loading={loading}
                            className={classes.searchButton}
                        >
                            جستجو
                        </Button>

                        <Button
                            className={classes.linkButton}
                            variant="contained"
                            size="small"
                            component="a"
                            download
                            href={downloadUrl}
                            disabled={reports.length === 0}
                            disableElevation
                        >
                            <GetAppIcon />
                            دریافت اکسل نتایج
                        </Button>
                    </React.Fragment>
                }
                onClick={searchHandler}
                filters={searchFilters(pageFilters, inputChangedHandler)}
            >
                {loading && <LinearLoading width={200} title="در حال جستجو" />}
                {reports.length === 0 && !loading && (
                    <div style={{ marginTop: 30 }}>
                        <NoContent text="نتیجه ای یافت نشد" maxWidth={400} />{" "}
                    </div>
                )}
                {reports.length > 0 && <ReportsTable reports={reports.map((report) => fixReport(report))} groupRoute />}
            </FilterContainer>
        </MainContainer>
    );
};

export default SearchPage;
