import React from "react";
import { Card, CardActionArea, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        borderRadius: 4,
        boxShadow: "none",
    },
    actionArea: {
        height: "100%",
        padding: theme.spacing(2, 3),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    text: {
        fontWeight: 600,
    },
}));

const DashboardCard = (props) => {
    const classes = useStyles();
    const { title, number, color, textColor } = props;

    return (
        <Card className={classes.card} style={{ backgroundColor: color }}>
            <CardActionArea className={classes.actionArea} style={{ color: textColor }}>
                <Typography variant="subtitle2" color="inherit" className={classes.text}>
                    {title}
                </Typography>
                <Typography variant="subtitle2" color="inherit" className={classes.text}>
                    {number}
                </Typography>
            </CardActionArea>
        </Card>
    );
};

export default DashboardCard;
