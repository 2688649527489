import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, DialogActions, DialogContent } from "@material-ui/core";

import { showAlert } from "../../../../store/actions/snackbarActions";
import { createTask } from "../../../../api/reportsAPI";
import { fetchSingleReport } from "../../../../store/actions/reportsActions";
import formCreator from "../../../../shared/formCreator";
import validateForm from "../../../../shared/validateForm.js";
import { dateFixer } from "../../../../shared/toEnNumber";
import taskInputs from "./taskInputs";

const CreateTaskContainer = (props) => {
    const dispatch = useDispatch();
    const { report } = props;
    const [controls, setControls] = React.useState(taskInputs());

    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedControls = { ...controls };
        updatedControls[inputIdentifier].value = value;
        setControls(updatedControls);
    };
    const handleSubmit = () => {
        const validatedForm = validateForm(controls);
        setControls(validatedForm.data);
        let formData = {};
        for (let key in controls) {
            if (key === "returned_mail_date") formData[key] = dateFixer(controls[key].value);
            else formData[key] = controls[key].value;
        }
        formData["report_id"] = report.id;

        if (validatedForm.error === false)
            createTask(formData).then((response) => {
                dispatch(showAlert(response.message, response.type));
                dispatch({ type: "CLOSE_DIALOG" });
                if (response.type === "success") {
                    dispatch(fetchSingleReport(report.id));
                }
            });
    };
    const formElements = formCreator(controls, inputChangedHandler);

    return (
        <React.Fragment>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    component="form"
                    onSubmit={(event) => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                >
                    {formElements}
                    <input type="submit" style={{ display: "none" }} />
                </Grid>
            </DialogContent>
            <DialogActions style={{ padding: 20 }}>
                <Button onClick={() => dispatch({ type: "CLOSE_DIALOG" })} color="secondary">
                    لغو
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained" autoFocus>
                    ساخت ابلاغ
                </Button>
            </DialogActions>
        </React.Fragment>
    );
};

export default CreateTaskContainer;
