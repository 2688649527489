import React from "react";
import { Grid, Typography } from "@material-ui/core";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import Questionnaire from "./Questionnaire/Questionnaire";
import CalculatorContainer from "./WageCalculator/CalculatorContainer";
import LoginForm from "./LoginForm/LoginContainer";
import IntroDescription from "./IntroDescription/IntroDescription";

import styles from "./style";

const IntroLogin = ({ settings }) => {
    const classes = styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Grid container className={isMobile ? classes.mobileExpertIntroHeader : classes.expertIntroHeader}>
            <Questionnaire />
            <Grid
                item
                md={12}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid container>
                    {!isMobile && <IntroDescription description={settings?.description} />}
                    <Grid item md={12} style={{ padding: isMobile ? "0" : "0 15%" }}>
                        <Grid container spacing={5} style={{ width: "100%", margin: 0 }}>
                            <LoginForm />
                            <CalculatorContainer />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IntroLogin;
