const formData = (val) => ({
    start_date: {
        elementType: "date-picker",
        elementConfig: {
            label: "از تاریخ",
        },
        gridConfig: {
            xs: 6,
        },
        value: val.start_date
    },
    end_date: {
        elementType: "date-picker",
        elementConfig: {
            label: "تا تاریخ",
        },
        gridConfig: {
            xs: 6,
        },
        value: val.end_date
    }
});

export default formData;