import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import ErrorBoundary from "../components/hoc/ErrorBoundary";
import AuthHoc from "../components/hoc/AuthHoc";

import LoginPage from "../pages/Auth/ExpertIntro";
import Questionnaire from "../pages/Auth/Questionnaire";
import WageCalculator from "../pages/WageCalculator/WageCalculator";
import WageFactor from "../pages/WageCalculator/WageFactor";
import AppRoutes from "./AppRoutes";

const MainRoutes = (props) => {
    const user = useSelector((store) => store.user);

    return (
        <BrowserRouter basename="/">
            <ErrorBoundary>
                <AuthHoc>
                    <React.Suspense fallback={""}>
                        <Switch>
                            {user.id && <Route path={`/`} component={AppRoutes} />}
                            <Route path="/login" component={LoginPage} />
                            <Route path="/questionnaire" component={Questionnaire} />
                            <Route path="/wageCalculator" component={WageCalculator} />
                            <Route path="/wageFactor" component={WageFactor} />
                            <Route path="/">
                                <Redirect to="/login" />
                            </Route>
                        </Switch>
                    </React.Suspense>
                </AuthHoc>
            </ErrorBoundary>
        </BrowserRouter>
    );
};

export default MainRoutes;
