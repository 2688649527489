import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import axios from "../../../../api/instance";

import { showAlert } from "../../../../store/actions/snackbarActions";

const RemoveEmployee = (props) => {
    const dispatch = useDispatch();
    const { removeEmployee, memberId, groupId } = props;
    const submitRemoveEmployee = () => {
        axios
            .post("/groups/remove", { mobile_number: props.mobile, group_id: parseInt(groupId) })
            .then((res) => {
                dispatch({ type: "CLOSE_DIALOG" });
                dispatch(showAlert("کاربر با موفقیت حذف شد", "success"));
                removeEmployee(memberId);
            })
            .catch((err) => {
                dispatch({ type: "CLOSE_DIALOG" });
                dispatch(showAlert(err.response.data.message, "error"));
            });
    };
    return (
        <>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <Typography>آیا از حذف این مورد اطمینان دارید؟</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch({ type: "CLOSE_DIALOG" })} color="primery">
                    لغو
                </Button>
                <Button onClick={submitRemoveEmployee} color="secondary" variant="contained" autoFocus>
                    حذف کن
                </Button>
            </DialogActions>
        </>
    );
};

export default RemoveEmployee;
