import React from "react";
import { Avatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: "50%",
        transition: "all 0.5s",
    },
}));

const Profile = (props) => {
    const classes = useStyles();
    const { src, width, height, className } = props;

    return (
        <div style={{ width: width ?? "100%", height: height ?? "100%" }} className={classes.container}>
            <Avatar src={src} className={className} style={{ width: "100%", height: "100%" }} alt="profile" />
        </div>
    );
};

export default Profile;
