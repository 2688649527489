import React from "react";
import { Grid, Typography } from "@material-ui/core";
import SpeakerNotesOffOutlinedIcon from "@material-ui/icons/SpeakerNotesOffOutlined";
import Grow from "@material-ui/core/Grow";

import MyMessage from "./Messaging/MyMessage";
import AnotherMessage from "./Messaging/AnotherMessage";
import MessagesListSkeleton from "./MessagesSkeleton";
import styles from "./conversationStyle";

const ConversationBody = (props) => {
    const classes = styles();
    const { messages } = props;
    const len = messages.length;

    const scroll = React.useRef(null);
    const [messageLen, setMessageLen] = React.useState(0);

    React.useEffect(() => {
        setTimeout(() => {
            if (scroll.current)
                scroll.current.scrollIntoView({
                    behavior: messageLen + 1 === len ? "smooth" : "auto",
                    block: "nearest",
                    inline: "start",
                });
            setMessageLen(len);
        }, 1000);
    }, [JSON.stringify(messages)]);
    return (
        <div className={classes.root}>
            {messages.skeleton ? (
                <>
                    <MessagesListSkeleton number={2} />
                </>
            ) : messages.length === 0 ? (
                <Grow in={true}>
                    <Grid className={classes.ticketListNoData}>
                        <SpeakerNotesOffOutlinedIcon style={{ fontSize: 45, color: "#ccc" }} />
                        <Typography style={{ color: "#ccc" }}>هیچ پیامی برای نمایش وجود ندارد</Typography>
                    </Grid>
                </Grow>
            ) : (
                <Grid container>
                    {messages.map((msg, key) => {
                        if (msg.owner === "SELF") return <MyMessage key={msg.id} data={msg} number={key} />;
                        else return <AnotherMessage key={msg.id} data={msg} number={key} />;
                    })}
                </Grid>
            )}
            <div id="scroll" ref={scroll}></div>
        </div>
    );
};

export default ConversationBody;
