import axios from "./instance";

export const assignToGroup = (payload) => {
    return axios
        .post("/groups/assign", payload)
        .then((response) => {
            return { data: response?.data?.data, message: "کاربر با موفقیت اضافه شد", type: "success" };
        })
        .catch((error) => {
            return { data: error.response?.data, message: error?.response?.data?.message, type: "error" };
        });
};

export const revokeEmployee = (payload) => {
    return axios
        .post("/groups/revoke", payload)
        .then((response) => {
            return { data: response?.data?.data, message: "تغییر نقش کاربر با موفقیت انجام شد", type: "success" };
        })
        .catch((error) => {
            return { data: error.response?.data, message: error?.response?.data?.message, type: "error" };
        });
};
