import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Avatar, makeStyles } from "@material-ui/core";

import Button from "../../../../components/UI/Buttons/LoadingButton";

import DataTable from "../../../../components/Tables/DataTable/DataTable";
import ActionDialog from "../../../../components/Dialogs/ActionDialog";
import ExpertInfoTable from "../../../../components/Tables/ExpertInfoTable/ExpertInfoTable";

import { showAlert } from "../../../../store/actions/snackbarActions";
import { assignExpert } from "../../../../api/assigneesAPI";
import locale from "../../../../shared/locale";

const useStyles = makeStyles((theme) => ({
    head: {
        backgroundColor: "#F9FAFC",
        color: "#A3A9B7",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        "&:nth-of-type(3)": {
            textAlign: "left",
        },
        borderWidth: 0,
    },
    row: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        marginTop: 30,
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        "&:nth-of-type(3)": {
            textAlign: "left",
        },
        borderWidth: 2,
    },
    seeMore: {
        color: "#A3A9B7",
        backgroundColor: "rgba(196, 196, 196, 0.3)",
        border: "2px solid #A3A9B7",
        padding: theme.spacing(0.2, 3),
        borderRadius: 5,
        "&:hover": {
            textDecoration: "underline",
        },
    },
    choose: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(0.4, 3),
        borderRadius: 5,
        width: 120,
        "&:hover": {
            backgroundColor: "#59c9fad1",
        },
    },
}));

const ExpertsContainer = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { reportId } = props;
    const [openDialog, setOpenDialog] = React.useState({ info: false, expert: {} });
    const [loading, setLoading] = React.useState(false);
    const user = useSelector((store) => store.user);
    const { experts, type } = props;
    const chooseHandler = (role, id) => {
        setLoading(true);
        assignExpert({ role_id: role, report_id: reportId, user_id: id }).then((response) => {
            setLoading(false);
            dispatch(showAlert(response.message, response.type));
        });
    };

    const expertsData = experts.map((expert) => ({
        data: {
            image: <Avatar src={expert.avatar_link} />,
            name: expert.name,
            surname: expert.surname,
            actions: (
                <Button
                    className={classes.seeMore}
                    onClick={() => setOpenDialog({ ...openDialog, info: true, expert: expert })}
                >
                    مشاهده
                </Button>
            ),
            choose: (
                <Button
                    className={classes.choose}
                    loading={loading}
                    disabled={loading}
                    onClick={() => chooseHandler(type, expert.id)}
                >
                    {locale(type)}
                </Button>
            ),
        },
        config: {
            className: classes.row,
            align: "center",
        },
    }));

    return (
        <React.Fragment>
            <ActionDialog
                title="اطلاعات کارشناس"
                open={openDialog.info}
                onClose={() => setOpenDialog({ ...openDialog, info: false })}
                actions={[
                    {
                        label: "مشاهده پروفایل",
                        variant: "contained",
                        color: "primary",
                        onClick: () =>
                            history.push({
                                pathname: `/${user.active_route}/expertprofile`,
                                search: "userId=" + openDialog.expert.id,
                            }),
                    },
                ]}
            >
                <ExpertInfoTable expert={openDialog.expert} />
            </ActionDialog>
            <Grid container spacing={2} direction="column" justify="flex-start">
                {experts.length > 0 && (
                    <DataTable
                        head={{
                            data: {
                                avatar: "",
                                name: "نام",
                                surname: "نام خانوادگی",
                                action_1: "مشاهده جزئیات",
                                role: "انتخاب به عنوان",
                            },
                            config: {
                                className: classes.head,
                                align: "center",
                            },
                        }}
                        body={expertsData}
                        rowsPerPage={10}
                    />
                )}
            </Grid>
        </React.Fragment>
    );
};

export default ExpertsContainer;
