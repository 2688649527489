export const checkStatus = (array, status, role) => {
    if (role === undefined || status === undefined) return array;

    let updatedArray = array.filter((item) => {
        // each item has a role , status  and condition
        // each token is arbitary

        if (!item.content) return true;
        let roleFlag = false;
        let statusFlag = false;

        if (Array.isArray(item.role)) {
            item.role.forEach((element) => {
                if (element.toLowerCase() === role.toLowerCase()) roleFlag = true;
            });
        } else {
            roleFlag = item.role?.toLowerCase() === role.toLowerCase() || !item.role;
        }

        if (Array.isArray(item.status)) {
            item.status.forEach((element) => {
                if (element.toLowerCase() === status.toLowerCase()) statusFlag = true;
            });
        } else {
            statusFlag = item.status?.toLowerCase() === status.toLowerCase() || !item.status;
        }

        if (role === roles.SuperAdmin) roleFlag = true;

        return roleFlag && statusFlag && (item.condition === true || item.condition === undefined);
    });
    updatedArray = updatedArray.map((item) => {
        if (!item.content) return item;
        return item.content;
    });

    return updatedArray;
};

export const assigneeStatus = {
    NO_ACTION: "NO_ACTION",
    REJECTED: "REJECTED",
    REMOVED: "REMOVED",
    APPROXIMATED: "APPROXIMATED",
    ACCEPTED: "ACCEPTED",
    IN_PROGRESS: "IN_PROGRESS",
    RESPONDED: "RESPONDED",
    RESPONSE_REJECTED: "RESPONSE_REJECTED",
    CONFIRMING: "CONFIRMING",
    CONFIRMED: "CONFIRMED",
    NOT_CONFIRMED: "NOT_CONFIRMED",
    PAID: "PAID",
};

export const reportStatus = {
    NO_EXPERT: "NO_EXPERT",
    ASSIGNING: "ASSIGNING",
    IN_PROGRESS: "IN_PROGRESS",
    NOTIFIED: "NOTIFIED",
    COMPLETED: "COMPLETED",
    REJECTED: "REJECTED",
};

export const roles = {
    Supervisor: "Supervisor",
    GroupAdmin: "GroupAdmin",
    GroupMember: "GroupMember",
    People: "People",
    Referee: "Referee",
    SuperAdmin: "SuperAdmin",
    Expert: "Expert",
    Secretary: "Secretary",
    Head: "Head",
};

export const statusColors = {
    [reportStatus.NO_EXPERT]: "#00B2FF",
    [reportStatus.ASSIGNING]: "#00B2FF",
    [reportStatus.IN_PROGRESS]: "#EA971A",
    [reportStatus.NOTIFIED]: "#EA971A",
    [reportStatus.COMPLETED]: "#03DE73",
    [reportStatus.REJECTED]: "#FF4646",
};

export const statusBackColors = {
    [reportStatus.NO_EXPERT]: "rgba(70, 199, 255, 0.1)",
    [reportStatus.ASSIGNING]: "rgba(70, 199, 255, 0.1)",
    [reportStatus.IN_PROGRESS]: "rgba(234, 151, 26, 0.1)",
    [reportStatus.NOTIFIED]: "rgba(234, 151, 26, 0.1)",
    [reportStatus.COMPLETED]: "rgba(3, 222, 115, 0.1)",
    [reportStatus.REJECTED]: "rgba(255, 70, 70, 0.1)",
};

export default checkStatus;
