import React from 'react';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {StylesProvider, jssPreset} from '@material-ui/core/styles';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles'
import theme from "../theme";

// Configure JSS
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const ThemeProvider = (props) => {
    return (
        <StylesProvider jss={jss}>
            <MuiThemeProvider theme={theme}>
                {props.children}
            </MuiThemeProvider>
        </StylesProvider>
    );
}

export default ThemeProvider;