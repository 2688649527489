import React from "react";
import { makeStyles, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import TablePerPage from "../CollapsibleTable/TablePerPage";

const useStyles = makeStyles((theme) => ({
    root: { width: "100%" },
    pagination: {
        margin: 20,
        "& ul": {
            justifyContent: "flex-end",
        },
    },
}));

const DataTable = (props) => {
    const classes = useStyles();
    const { head = {}, body = [] } = props;
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);

    const makeRow = (row) => {
        const { data = {}, config = {} } = row;
        const head_keys = Object.keys(data);
        return head_keys.map((key, index) => {
            return (
                <TableCell key={index} {...config}>
                    {data[key] ?? ""}
                </TableCell>
            );
        });
    };

    const slicedBody = limit > 0 ? body.slice((page - 1) * limit, page * limit) : body;
    return (
        <TableContainer className={classes.root}>
            <Table>
                <TableHead>
                    <TableRow>{makeRow(head)}</TableRow>
                </TableHead>
                <TableBody>
                    {slicedBody.map((row, index) => (
                        <TableRow key={index}>{makeRow(row)}</TableRow>
                    ))}
                </TableBody>
            </Table>
            {body.length > 10 && (
                <Grid container>
                    <Grid item xs={6}>
                        <TablePerPage limit={limit} changeLimit={(limit) => setLimit(limit)} />
                    </Grid>
                    <Grid item xs={6}>
                        <Pagination
                            className={classes.pagination}
                            count={Math.ceil(body.length / limit)}
                            onChange={(event, value) => setPage(value)}
                        />
                    </Grid>
                </Grid>
            )}
            {props.children}
        </TableContainer>
    );
};

export default DataTable;
