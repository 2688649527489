import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        boxShadow: "none",
        backgroundColor: "none",
        border: "none !important",
        borderRadius: "8px !important",
        padding: theme.spacing(0, 1),
        "&:before": {
            display: "none",
        },
        [theme.breakpoints.down("sm")]: {
            // border: "1px solid #000 !important",
        },
    },
    expanded: { margin: theme.spacing(1.5, 0) },
    heading: {
        fontSize: theme.typography.pxToRem(13),
        color: "#5c5f66",
    },
    details: {
        marginTop: -25,
    },
}));

const FiltersAccordion = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const { title, active } = props;

    const toggleCollapse = () => {
        setExpanded((prevState) => (prevState ? false : true));
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={() => toggleCollapse()}
            classes={{ root: classes.root, expanded: classes.expanded }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ fontSize: 20 }} />}>
                <Badge color="primary" badgeContent="" variant="dot" invisible={!active}>
                    <Typography className={classes.heading}>{title}</Typography>
                </Badge>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>{props.children}</AccordionDetails>
        </Accordion>
    );
};

export default FiltersAccordion;
