import React from "react";
import axios from "../../../../api/instance";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import { showAlert, TYPES } from "../../../../store/actions/snackbarActions";

import formCreator from "../../../../shared/formCreator";
import validateForm from "../../../../shared/validateForm";
import addFormData from "./addSubgroupInputs";

const AddSubgroup = (props) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user);
    const [formData, setFormData] = React.useState({ hide: { type: user.active_role_id !== "SUPERADMIN" } });
    const [checkForm, setCheckForm] = React.useState(false);
    const { groupId, addGroup } = props;

    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedControls = { ...formData };
        updatedControls[inputIdentifier] = value;
        setFormData(updatedControls);
    };

    const submitAddOrg = () => {
        const validatedForm = validateForm(addFormData(formData));
        setCheckForm(true);
        if (validatedForm.error === false)
            axios
                .post("/groups", { ...formData, parent_id: groupId })
                .then((response) => {
                    dispatch({ type: "CLOSE_DIALOG" });
                    dispatch(showAlert("سازمان با موفقیت اضافه شد", TYPES.SUCCESS));
                    addGroup(response.data.data);
                })
                .catch((err) => {
                    dispatch(showAlert(err?.response?.data?.message, TYPES.ERROR));
                });
    };

    let formElements = formCreator(addFormData(formData), inputChangedHandler);
    if (checkForm) {
        formElements = formCreator(validateForm(addFormData(formData)).data, inputChangedHandler);
    }
    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    {formElements}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch({ type: "CLOSE_DIALOG" })} color="secondary">
                    لغو
                </Button>
                <Button onClick={submitAddOrg} color="primary" variant="contained" autoFocus>
                    ثبت
                </Button>
            </DialogActions>
        </>
    );
};

export default AddSubgroup;
