import React from "react";
import { Button, TextField, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../api/instance";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useDispatch } from "react-redux";

import { toEnNumber } from "../../../shared/toEnNumber";
import styles from "./LoginFormStyle";

const RegisterForm = (props) => {
    const dispatch = useDispatch();
    const classes = styles();
    const [formData, setFormData] = React.useState({
        mobile_number: props.phoneNumber,
    });
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState({});
    const changeInput = (e) => {
        setFormData({ ...formData, [e.target.id]: toEnNumber(e.target.value) });
    };

    const submitForm = (event) => {
        event.preventDefault();
        let e = {};
        if (formData.name === null || formData.name === undefined) {
            e.name = "نام نمیتواند خالی باشد";
        }
        if (formData.surname === null || formData.surname === undefined) {
            e.surname = "نام خانوادگی نمیتواند خالی باشد";
        }
        setError({ ...e });

        if (
            (formData.name !== "" || formData.name !== undefined) &&
            (formData.surname !== "" || formData.surname !== undefined)
        ) {
            setLoading(true);
            axios
                .post("/auth/register", formData)
                .then((response) => {
                    setLoading(false);
                    dispatch({ type: "SET_TIME", time: response.data.remaining_time });
                    props.changeStep(props.phoneNumber, "validate");
                })
                .catch((err) => {
                    setLoading(false);
                    setError(err.response.data.message);
                });
        }
    };
    return (
        <>
            <Button
                onClick={() => props.changeStep(props.phoneNumber, "login")}
                style={{
                    position: "absolute",
                    color: "#fff",
                    top: 0,
                    left: 0,
                    zIndex: 10,
                }}
                endIcon={<ArrowBackIcon />}
            >
                بازگشت
            </Button>
            <form onSubmit={submitForm} style={{ width: "100%", marginTop: 10 }}>
                <Grid container spacing={1}>
                    <Grid item md={6} xs={12} className={classes.inputFieldContainer}>
                        <TextField
                            InputProps={{
                                className: classes.input,
                            }}
                            fullWidth
                            size="small"
                            placeholder="نام"
                            variant="outlined"
                            id="name"
                            onChange={changeInput}
                            error={error.name}
                            helperText={error.name}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.inputFieldContainer}>
                        <TextField
                            InputProps={{
                                className: classes.input,
                            }}
                            fullWidth
                            size="small"
                            placeholder="نام خانوادگی"
                            variant="outlined"
                            id="surname"
                            onChange={changeInput}
                            error={error.surname}
                            helperText={error.surname}
                        />
                    </Grid>
                    <Grid item md={12} xs={12} className={classes.inputFieldContainer}>
                        <TextField
                            InputProps={{
                                className: classes.input,
                            }}
                            fullWidth
                            size="small"
                            placeholder="کد ملی"
                            variant="outlined"
                            id="national_id"
                            error={typeof error.national_id !== "undefined"}
                            helperText={typeof error.national_id !== "undefined" && error.national_id}
                            onChange={changeInput}
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                        className={classes.inputFieldContainer}
                        style={{ paddingTop: 0, marginTop: 5 }}
                    >
                        <Grid container spacing={1}>
                            <Grid item md={12} xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        fontSize: 15,
                                        fontFamily: "IRANSansBold",
                                        backgroundColor: "#9A44FC",
                                    }}
                                    type="submit"
                                >
                                    {loading ? <CircularProgress size={26} color="inherit" /> : "ثبت نام"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default RegisterForm;
