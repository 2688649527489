import React from "react";
import {
    AppBar,
    Toolbar,
    Grid,
    Paper,
    Button,
    useTheme,
    useMediaQuery,
    Dialog,
    IconButton,
    Typography,
} from "@material-ui/core";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TuneIcon from "@material-ui/icons/Tune";

import Styles from "./styleFilterContainer";

const FilterContainer = (props) => {
    const classes = Styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [openDialog, setOpenDialog] = React.useState(false);
    const { header, filters, onClick } = props;

    if (isMobile) {
        return (
            <React.Fragment>
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullScreen={isMobile} fullWidth={true}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpenDialog(false)}>
                            <ArrowForwardIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            فیلتر نتایج جستجو بر اساس :
                        </Typography>
                    </Toolbar>
                    <div className={classes.filterContainer}>{filters}</div>
                </Dialog>
                <Grid container direction="center" alignItems="center" justify="center">
                    {header}
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.filterButton}
                        startIcon={<TuneIcon className={classes.icon} />}
                        onClick={() => setOpenDialog(true)}
                        size="small"
                    >
                        فیلتر جستجو
                    </Button>
                    <Grid item xs={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Grid container className={classes.filterHeader}>
                {header}
            </Grid>
            <Grid container style={{ height: "100%", marginTop: 16 }}>
                <Grid item xs={4} md={3} lg={2}>
                    <Paper className={classes.sideFilter}>{filters}</Paper>
                </Grid>
                <Grid item xs={8} md={9} lg={10} style={{ padding: "0 15px", marginTop: -15 }}>
                    {props.children}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default FilterContainer;
