import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, Typography } from "@material-ui/core";

function TicketListSkeleton(props) {
  let res = [];
  for (let i = 0; i < (props.number ? props.number : 10); i++) {
    res.push(
      <Grid style={{display: "flex", flexDirection: "column"}}>
        <Typography variant="h1">
          <Skeleton
            animation="wave"
            key={i}
            style={{width: "60%", float: "right"}}
          />
        </Typography>
        <Typography variant="body1">
          <Skeleton
            animation="wave"
            key={i}
            style={{width: "30%", float: "right"}}
          />
        </Typography>

        <Typography variant="h1">
          <Skeleton
            animation="wave"
            key={i}
            style={{width: "60%", float: "left"}}
          />
        </Typography>
        <Typography variant="body1">
          <Skeleton
            animation="wave"
            key={i}
            style={{width: "30%", float: "left"}}
          />
        </Typography>
      </Grid>
    );
  }
  return res;
}

export default TicketListSkeleton;
