import moment from "moment";

export const toEnNumber = (inputNumber2) => {
    if (inputNumber2 === undefined) return "";

    var str = inputNumber2.toString();
    if (str === "") return "";

    str = str.replace(/۰/g, "0");
    str = str.replace(/۱/g, "1");
    str = str.replace(/۲/g, "2");
    str = str.replace(/۳/g, "3");
    str = str.replace(/۴/g, "4");
    str = str.replace(/۵/g, "5");
    str = str.replace(/۶/g, "6");
    str = str.replace(/۷/g, "7");
    str = str.replace(/۸/g, "8");
    str = str.replace(/۹/g, "9");
    return str;
};

export const dateFixer = (date) => {
    return toEnNumber(moment(date).format("YYYY-MM-DD"));
};
