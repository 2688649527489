import React, { useState } from "react";
import axios from "../../../api/instance";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CreateIcon from "@material-ui/icons/Create";
import ChatIcon from "@material-ui/icons/Chat";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ListAltIcon from "@material-ui/icons/ListAlt";

import ReportInfoTable from "../../../components/Tables/ReportInfoTable/ReportInfoTable";
import LinearLoading from "../../../components/Static/LinearLoading/LinearLoading";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import HeadCard from "../../../components/Surfaces/Cards/HeadCard/HeadCard";

import ExpertsContainer from "./AllExpertsContainer";
import EstimationDialog from "./Components/EstimationDialog";
import CreateTaskContainer from "./Components/CreateTask";
import ButtonCard from "../../../components/Surfaces/Cards/ButtonCard";
import Styles from "./styleReportDetails";

import { fetchReports, fetchCategories, fetchSingleReport, deleteReport } from "../../../store/actions/reportsActions";
import checkStatus, { reportStatus, roles, statusColors, statusBackColors } from "../../../shared/checkStatus";
import { dialogTypes } from "../../../components/Dialogs/dialogTypes";
import { fetchGroups } from "../../../store/actions/groupsActions";
import { showDialog } from "../../../store/actions/dialogActions";
import { getAssignees } from "../../../api/assigneesAPI";

const AllReportDetails = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = Styles();
    const { groupId, reportId } = useParams();
    const [assignees, setAssignees] = useState([]);
    const [assigneeLoading, setLoading] = React.useState(false);

    const { reports } = useSelector((store) => store.reports);
    const { show } = useSelector((store) => store.snackbar);
    const user = useSelector((store) => store.user);

    const report = reports.find((item) => item.id == reportId);
    const groupBased =
        user.active_role === roles.SuperAdmin || user.active_role === roles.Referee ? `/group/${report?.group_id}` : "";

    const redirectToGroupRoute = () => {
        history.push(`/${user.active_route}${groupBased}`);
    };
    React.useEffect(() => {
        if (!report && show) redirectToGroupRoute();
        if (report && report?.group_id != groupId)
            history.push(`/${user.active_route}${groupBased}/report/${report.id}`);
    }, [report]);

    React.useEffect(() => {
        setLoading(true);
        getAssignees(reportId).then((response) => {
            setLoading(false);
            setAssignees(response.reverse());
        });
        dispatch(fetchReports());
        dispatch(fetchGroups());
        dispatch(fetchCategories());
        dispatch(fetchSingleReport(reportId));
    }, []);

    const handleRedirect = (append) => {
        history.push(`/${user.active_route}${groupBased}/report/${report.id}${append}`);
    };

    const headerOptions = [
        {
            label: (
                <>
                    <InsertDriveFileIcon />
                    مشاهده اسناد
                </>
            ),
            badge: report?.documents.length,
            disabled: report?.documents.length === 0,
            onClick: () =>
                dispatch(
                    showDialog({
                        title: "اسناد گزارش",
                        files: report?.documents,
                        type: dialogTypes.FILE_PREVIEW,
                    })
                ),
        },
        {
            condition:
                user?.active_group_type === "SUPERVISOR" &&
                user.active_role_id === "GROUPADMIN" &&
                report?.status !== reportStatus.COMPLETED,
            content: {
                label: (
                    <>
                        <ListAltIcon /> ابلاغ
                    </>
                ),
                loading: assigneeLoading,
                options: [
                    {
                        label: "ساخت ابلاغ",
                        onClick: () =>
                            dispatch(
                                showDialog({
                                    title: "ورود اطلاعات نامه‌ی ابلاغ",
                                    fullScreen: true,
                                    content: <CreateTaskContainer report={report} />,
                                })
                            ),
                    },
                    {
                        component: "a",
                        label: "دریافت ابلاغ",
                        href: axios.defaults.baseURL + "/reports/task?report_id=" + reportId,
                        target: "_blank",
                        disabled: report?.task_count === 0,
                        onClick: () => {},
                    },
                ],
            },
        },
        {
            label: (
                <>
                    <CreateIcon />
                    ویرایش
                </>
            ),
            onClick: () => handleRedirect("/editreport"),
        },
        {
            label: (
                <>
                    <ChatIcon />
                    مکالمات
                </>
            ),
            onClick: () => handleRedirect("/conversation"),
        },
        {
            condition: report?.status !== reportStatus.COMPLETED,
            content: {
                label: (
                    <>
                        <DeleteIcon />
                        حذف گزارش
                    </>
                ),
                className: classes.redButton,
                onClick: () =>
                    dispatch(
                        showDialog({
                            title: "حذف گزارش",
                            content: "آیا از حذف گزارش اطمینان دارید؟",
                            onSubmit: () => {
                                redirectToGroupRoute();
                                dispatch(deleteReport(reportId));
                            },
                            type: dialogTypes.SUBMIT,
                        })
                    ),
            },
        },
        {
            condition: report?.status === reportStatus.COMPLETED,
            content: {
                label: (
                    <>
                        <DoneAllIcon />
                        مشاهده پاسخ
                    </>
                ),
                className: classes.greenButton,
                onClick: () =>
                    dispatch(
                        showDialog({
                            title: "اطلاعات پاسخ گزارش",
                            content: <EstimationDialog assignees={assignees} />,
                            type: dialogTypes.ACTION,
                        })
                    ),
            },
        },
    ];

    const nodes = [
        {
            condition: !report,
            content: <Skeleton variant="rect" height={300} animation="wave" className={classes.skeleton} />,
        },
        {
            condition: report !== undefined,
            content: (
                <HeadCard
                    title={report?.name}
                    subTitle={"مهلت گزارش : " + report?.due_date_jalaali}
                    caption={<span style={{ color: statusColors[report?.status] }}>{report?.caption}</span>}
                    captionProps={{ style: { backgroundColor: statusBackColors[report?.status] } }}
                    chip={report?.chip}
                    options={checkStatus(headerOptions, "", user?.active_role)}
                >
                    <ReportInfoTable report={report} />
                </HeadCard>
            ),
        },
        {
            condition: assigneeLoading,
            content: <LinearLoading width={220} title="در حال بارگیری" />,
        },
        {
            role: [roles.GroupAdmin, roles.Referee],
            condition:
                user?.active_group_type === "SUPERVISOR" &&
                report?.status !== reportStatus.COMPLETED &&
                !assigneeLoading,
            content: (
                <Grid container spacing={1} style={{ marginTop: 10 }}>
                    <Grid item xs={6} md={4} lg={3}>
                        <ButtonCard
                            title="افزودن کارشناس"
                            icon={<PersonAddIcon />}
                            onClick={() => handleRedirect("/searchexpert?type=expert")}
                        />
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <ButtonCard
                            title="افزودن کارورز"
                            icon={<PersonAddIcon />}
                            onClick={() => handleRedirect("/searchexpert?type=intern")}
                        />
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <ButtonCard
                            title="افزودن هیئت نظارت"
                            icon={<PersonAddIcon />}
                            onClick={() => handleRedirect("/searchexpert?type=supervisor")}
                        />
                    </Grid>
                </Grid>
            ),
        },
        {
            role: [roles.GroupAdmin, roles.GroupMember],
            condition:
                user?.active_group_type === "BUSINESS" && report?.status !== reportStatus.COMPLETED && !assigneeLoading,
            content: (
                <div style={{ marginTop: 10 }}>
                    <ButtonCard
                        title="انتخاب سیستمی"
                        icon={<GroupAddIcon />}
                        onClick={() => handleRedirect("/autoassign")}
                    />
                </div>
            ),
        },
        {
            condition: !assigneeLoading,
            content: <ExpertsContainer experts={assignees} setExperts={setAssignees} report={report} />,
        },
    ];

    return <MainContainer>{checkStatus(nodes, "", user?.active_role)}</MainContainer>;
};

export default AllReportDetails;
