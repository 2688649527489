import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { Typography } from "@material-ui/core";

const FileView = (props) => {
    return (
        <a className="uploadedFileView" href={props.data.file_path} target="blank">
            {props.data.mime_type === "image/png" || props.data.mime_type === "image/jpeg" ? (
                <img className="imageFile" src={props.data.file_path} style={{ width: "100%" }} alt="uploadedFile" />
            ) : (
                <div className="otherFile">
                    <div className="otherFileIcon">
                        <DescriptionIcon />
                    </div>
                    <Typography variant="span" style={{ fontFamily: "arial", direction: "ltr", wordWrap: "anywhere" }}>
                        {props.data.file_name}
                    </Typography>
                </div>
            )}
        </a>
    );
};

export default FileView;
