const AddEmployeeInputs = (defaults) => {
    return {
        mobile_number: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره موبایل",
                type: "number",
                variant: "outlined",
            },
            validation: {
                required: true,
                pattern: /^09[0-9]{9}$/,
            },
            value: defaults.values.mobile_number ?? "",
        },
        role_id: {
            elementType: "select-input",
            elementConfig: {
                label: "نقش کاربر",
                options: defaults.options?.role_id,
                variant: "outlined",
            },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            value: defaults.values.role_id ?? "GroupMember",
        },
        group_id: {
            elementType: "store-field",
            elementConfig: {
                label: "سازمان",
                variant: "outlined",
                store: "groups",
                field: "groups",
                mapper: (value) => value?.id,
            },
            validation: {
                required: true,
            },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            value: defaults.values?.group_id ?? "",
        },
    };
};

export default AddEmployeeInputs;
