import axios from "../../api/instance";
import getRouteFromRole from "../../shared/getRouteFromRole";
import { enterRole } from "./rolesActions";
import { showAlert } from "./snackbarActions";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_LICENSE = "UPDATE_LICENSE";
export const UPDATE_USER_INFORMATION = "UPDATE_USER_INFORMATION";

const fixUserData = (user) => {
    let updatedUser = { ...user };
    if (updatedUser.active_role === "GroupAdmin" && updatedUser.active_group_type === "SUPERVISOR") {
        updatedUser.active_role = "Referee";
        updatedUser.active_route = "referee";
    }

    updatedUser.group_route = updatedUser.active_role === "Referee" || updatedUser.active_role === "Superadmin";
    return updatedUser;
};

export const addUserInformation = (user) => {
    return {
        type: LOGIN,
        value: { ...fixUserData(user) },
    };
};
export const logout = () => ({ type: LOGOUT });
export const updateLicense = (license) => ({ type: UPDATE_LICENSE, data: license });
export const updateUserInformation = (data) => ({
    type: UPDATE_USER_INFORMATION,
    data: fixUserData({ ...data, active_route: getRouteFromRole(data.active_role) }),
});

export const signContractAgreement = () => {
    return (dispatch) => {
        axios
            .post("/users/contract-agreement")
            .then(() => {
                dispatch(updateUserInformation({ contract_agreement_date: new Date() }));
                dispatch(enterRole("EXPERT"));
            })
            .catch((error) => {
                dispatch(showAlert("مشکلی در امضای قرارداد وجود دارد.", "error"));
            });
    };
};
