import React from "react";
import Mapir from "mapir-react-component";
import { useDispatch } from "react-redux";
import { Button, makeStyles } from "@material-ui/core";
import HeightIcon from "@material-ui/icons/Height";

import DataTable from "../DataTable/DataTable";
import TableRadiusBox from "../../UI/Containers/TableRadiusBox";
import MapContainer from "../../Map/MapContainer";

import { showDialog } from "../../../store/actions/dialogActions";

const useStyles = makeStyles((theme) => ({
    container: {},
    row: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        border: "1px solid #DEDEDE",
        width: "50%",
    },
    locationCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    mapContainer: {
        width: "70%",
        borderRadius: 6,
        position: "relative",
    },
    mapButton: {
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: 6,
        top: 0,
        backgroundColor: "rgba(45,45,45,0.6)",
        color: "#fff",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
        zIndex: 20,
        "&:hover": {
            backgroundColor: "rgba(45,45,45,0.8)",
        },

        "& svg": {
            transform: "rotate(-35deg)",
        },
    },
}));

const ReportInfoTable = (props) => {
    const { report } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const mapCoords = report?.map_coords?.split(",").length === 2 ? report.map_coords.split(",") : [];

    const mapNode = (width, heigth) => (
        <MapContainer
            userLocation
            containerStyle={{
                width: width,
                height: heigth,
            }}
            center={mapCoords.length === 2 ? mapCoords : null}
        >
            <Mapir.Marker coordinates={mapCoords.length === 2 ? mapCoords : null} anchor="bottom" />
        </MapContainer>
    );

    return (
        <TableRadiusBox className={classes.container}>
            <DataTable
                body={[
                    {
                        data: {
                            first: `پرونده: ${report.category ?? "____"}`,
                            second: `تاریخ ساخت: ${report.created_at ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    {
                        data: {
                            first: `نوع گزارش: ${report.is_person ? "فردی" : "هیئتی"}`,
                            second: `مهلت گزارش: ${report.due_date_jalaali ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    {
                        data: {
                            first: `استان: ${report.province ?? "____"}`,
                            second: `شهرستان: ${report.town ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    {
                        data: {
                            first: `شهر: ${report.city ?? "____"}`,
                            second: `آدرس: ${report.map_address ?? "____"}`,
                        },
                        config: {
                            className: classes.row,
                        },
                    },
                    {
                        data: {
                            first: (
                                <div className={classes.locationCell}>
                                    موقعیت جغرافیایی
                                    <div className={classes.mapContainer}>
                                        {mapNode("100%", 40)}
                                        <Button
                                            variant="outlined"
                                            disableElevation
                                            className={classes.mapButton}
                                            disabled={mapCoords.length !== 2}
                                            onClick={() =>
                                                dispatch(
                                                    showDialog({
                                                        content: mapNode(600, 400),
                                                    })
                                                )
                                            }
                                        >
                                            <HeightIcon />
                                            بزرگنمایی
                                        </Button>
                                    </div>
                                </div>
                            ),
                        },
                        config: {
                            className: classes.row,
                            colspan: "2",
                        },
                    },
                ]}
            />
        </TableRadiusBox>
    );
};

export default ReportInfoTable;
