import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import { showAlert } from "../../../../store/actions/snackbarActions";
import { assignToGroup } from "../../../../api/groupsAPI";
import validateForm from "../../../../shared/validateForm";
import formCreator from "../../../../shared/formCreator";
import addEmployeeInputs from "./addEmployeeInputs";

const roles = [
    { label: "کارمند", value: "GROUPMEMBER" },
    { label: "مدیر", value: "GROUPADMIN" },
    { label: "ارجاع دهنده", value: "REFEREE" },
    { label: "هیئت نظارت", value: "SUPERVISOR" },
];

const AddEmployee = (props) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user);

    const { groupId, isBusiness } = props;

    const [formData, setFormData] = React.useState({
        values: { role_id: "GROUPMEMBER", group_id: parseInt(groupId) },
        options: {
            role_id: isBusiness
                ? user.active_role_id === "SUPERADMIN"
                    ? roles.slice(0, 2)
                    : roles.slice(0, 1)
                : roles,
        },
    });
    const [checkForm, setCheckForm] = React.useState(null);

    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedForm = { ...formData };
        updatedForm.values[inputIdentifier] = value;
        setFormData(updatedForm);
    };

    const submitAddEmployee = () => {
        const validatedForm = validateForm(addEmployeeInputs(formData));
        setCheckForm(true);
        if (validatedForm.error === false)
            assignToGroup(formData.values).then((response) => {
                if (response.type === "success") {
                    dispatch({ type: "CLOSE_DIALOG" });
                    dispatch(showAlert(response.message, "success"));
                    props.addNew(response.data);
                } else {
                    dispatch({ type: "CLOSE_DIALOG" });
                    dispatch(showAlert(response?.message, "error"));
                }
            });
    };

    let formElements = formCreator(addEmployeeInputs(formData), inputChangedHandler);
    if (checkForm) {
        formElements = formCreator(validateForm(addEmployeeInputs(formData)).data, inputChangedHandler);
    }

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    {formElements}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch({ type: "CLOSE_DIALOG" })} color="secondary">
                    لغو
                </Button>
                <Button onClick={submitAddEmployee} color="primary" variant="contained" autoFocus>
                    ثبت
                </Button>
            </DialogActions>
        </>
    );
};

export default AddEmployee;
