import axios from "./instance";
import vars from "../vars";

export const getUserInformation = () => {
    //09128846912
    //09145831108
    //hamid 09035053923
    //navid 09144391949
    return axios
        .get("/auth/check-login")
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            if (!vars.debug) window.location.href = vars.redirectUrl;
            return { id: null };
        });
};

export const getExpertsList = (payload) => {
    return axios
        .post("/users/experts", payload)
        .then((response) => {
            return {
                data: response.data.data,
                message: "",
                type: "success",
            };
        })
        .catch((error) => {
            return {
                data: [],
                message: error?.response?.data?.message,
                type: "error",
            };
        });
};

export const getExpertProfile = (userId) => {
    return axios
        .get("/users/experts/profile", {
            params: {
                user_id: userId,
            },
        })
        .then((response) => {
            return {
                data: response.data?.data,
                type: "success",
            };
        })
        .catch((error) => {
            return {
                data: {},
                type: "error",
            };
        });
};

export const sendOTP = () => {
    return axios
        .post("/users/send-otp")
        .then((response) => {
            return { data: response.data, type: "success" };
        })
        .catch((error) => {
            return {
                data: error.response.data,
                type: "error",
            };
        });
};

export const validateOTP = (formData) => {
    return axios
        .post("/users/validate-otp", formData)
        .then((response) => {
            return { data: response.data, type: "success" };
        })
        .catch((error) => {
            return {
                data: error.response.data,
                type: "error",
            };
        });
};

export const fetchCharts = () => {
    return axios
        .get("/charts/my-charts")
        .then((response) => {
            return { data: response.data, type: "success" };
        })
        .catch((error) => {
            return {
                message: "",
                data: [],
                type: "error",
            };
        });
};

export const editProfile = (payload) => {
    return axios
        .post("/users/profile/edit", payload)
        .then((response) => {
            return { message: response?.data?.message, data: response.data, type: "success" };
        })
        .catch((error) => {
            return { message: error.response?.data?.message, data: {}, type: "error" };
        });
};

export const inquireUser = (license_number) => {
    return axios
        .post("/users/inquire", { license_number: license_number })
        .then((response) => {
            return { message: "استعلام با موفقیت انجام شد.", data: response.data.data, type: "success" };
        })
        .catch((error) => {
            return { message: error.response?.data?.message, data: {}, type: "error" };
        });
};

export const uploadUserLicesnse = (payload) => {
    return axios
        .post("/users/upload-license", payload)
        .then((response) => {
            return { message: "اطلاعات با موفقیت ثبت شد.", data: response.data.data, type: "success" };
        })
        .catch((error) => {
            return { message: error.response?.data?.message, data: {}, type: "error" };
        });
};

export const fetchSuperAdminLicenses = (page) => {
    return axios
        .get("/superadmin/licenses?page=" + page)
        .then((response) => {
            return { message: "", data: response.data, type: "success" };
        })
        .catch((error) => {
            return { message: error.response?.data?.message, data: {}, type: "error" };
        });
};

export const checkSuperAdminLicenses = (payload) => {
    return axios
        .post("/superadmin/licenses/check", payload)
        .then((response) => {
            return { message: response.data?.message, data: response.data, type: "success" };
        })
        .catch((error) => {
            return { message: error.response?.data?.message, data: {}, type: "error" };
        });
};
