import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";

import LinearLoading from "../../../components/Static/LinearLoading/LinearLoading";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import ReportsTable from "../../../components/Tables/ReportsTable/ReportsTable";
import ExpertInfoTable from "../../../components/Tables/ExpertInfoTable/ExpertInfoTable";
import ProfileAvatar from "../../../components/UI/Image/ProfileAvatar";
import LicenseTable from "../../../components/Tables/LicenseTable/LicenseTable";

import { getExpertProfile } from "../../../api/userAPI";
import { fixReport } from "../../../store/actions/reportsActions";
import currencySeperator from "../../../shared/currencySeprator";

const useStyles = makeStyles((theme) => ({
    container: {},
    avatar: {},
    center: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const ExpertProfile = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [expert, setExpert] = React.useState();
    const userId = new URLSearchParams(history.location.search).get("userId");

    React.useEffect(() => {
        getExpertProfile(userId).then((response) => {
            if (response.type === "success")
                setExpert({ ...response.data, reports: response.data?.reports.map((report) => fixReport(report)) });
        });
    }, []);

    return (
        <MainContainer>
            {!expert ? (
                <LinearLoading width={220} title="در حال بارگیری" />
            ) : (
                <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                    <Grid item xs={12} md={4} className={classes.center}>
                        <ProfileAvatar width={250} height={250} src={expert?.avatar_link} />
                    </Grid>
                    <Grid item xs={12} md={8} style={{ width: "100%" }}>
                        <ExpertInfoTable
                            expert={expert}
                            append={[
                                `مجموع حق‌الزحمه تایید شده : ${currencySeperator(expert?.total_wages ?? 0)} تومان`,
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LicenseTable license={expert?.licenses[0]} />
                    </Grid>
                    <Grid item xs={12} style={{ width: "100%" }}>
                        <ReportsTable reports={expert?.reports} groupRoute />
                    </Grid>
                </Grid>
            )}
        </MainContainer>
    );
};

export default ExpertProfile;
