import axios from "./instance";

export const getGeolocations = () => {
    return axios
        .get("/geolocations/all")
        .then((response) => {
            return response?.data?.data;
        })
        .catch((error) => {
            console.log(error?.response?.data);
            return {};
        });
};

export const getCompetences = () => {
    return axios
        .get("/competences/all")
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            console.log(error?.response?.data);
            return [];
        });
};
