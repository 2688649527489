import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MainContainer from "../../../components/UI/Containers/MainContainer";
import LinearLoading from "../../../components/Static/LinearLoading/LinearLoading";
import Employees from "./Employees";
import SubGroups from "./SubGroups";

import { fetchGroups } from "../../../store/actions/groupsActions";
import Styles from "./styleOrganizationInfo";

const OrganizationInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = Styles();
    const groupId = new URLSearchParams(history.location.search).get("group_id");
    const user = useSelector((state) => state.user);
    const { loading, groups } = useSelector((state) => state.groups);
    let currentGroup = groups.find((item) => item.id == groupId);

    React.useEffect(() => {
        dispatch(fetchGroups(true));
    }, []);

    React.useEffect(() => {
        if (!groupId) history.push(`/${user.active_route}/orginfo?group_id=${user.active_group_id}`);
    }, [groupId]);

    const isSupervisor =
        currentGroup !== undefined ? currentGroup?.name + (currentGroup.type === "SUPERVISOR" ? " (ناظر) " : "") : "";

    return (
        <MainContainer>
            {loading ? (
                <LinearLoading width={200} title="در حال بارگیری" />
            ) : (
                <React.Fragment>
                    <Employees title={"لیست کاربران سازمان " + isSupervisor} currentGroup={currentGroup} />
                    <SubGroups
                        currentGroup={currentGroup}
                        groups={groups.filter((item) => item?.parent_id == currentGroup?.id)}
                        user={user}
                        title={"لیست زیر سازمان‌های " + isSupervisor}
                    />
                    {user.active_role_id === "SUPERADMIN" && groupId == user.active_group_id && (
                        <SubGroups
                            currentGroup={currentGroup}
                            groups={groups}
                            user={user}
                            title="لیست همه سازمان‌ها "
                        />
                    )}
                </React.Fragment>
            )}
        </MainContainer>
    );
};

export default OrganizationInfo;
