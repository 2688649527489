import axios from "./instance";

export const getGroups = () => {
    return axios
        .get("/calculator/groups")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return [];
        });
};

export const getFields = () => {
    return axios
        .get("/calculator/fields")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return [];
        });
};

export const getFieldAttributes = (payload) => {
    return axios
        .get("/calculator/attributes", { params: { field_id: payload } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return [];
        });
};

export const getInvoice = (trackignCode) => {
    return axios
        .get("/calculator/invoice/" + trackignCode)
        .then((response) => {
            return { data: response?.data?.data, message: "", type: "success" };
        })
        .catch((error) => {
            return { data: error.response?.data, message: error?.response?.data?.message, type: "error" };
        });
};

export const calculateAttributes = (payload) => {
    return axios
        .post("/calculator/calculate", payload)
        .then((response) => {
            return { data: response?.data, message: "محاسبه دستمزد انجام شد.", type: "success" };
        })
        .catch((error) => {
            return { data: error.response?.data, message: error?.response?.data?.message, type: "error" };
        });
};
