import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    actionContainer: {
        border: "2px solid #7F899B",
        borderRadius: 6,
        padding: theme.spacing(2, 4),
    },
    greenButton: {
        color: "#03DE73",
        backgroundColor: "rgba(3, 222, 115, 0.2)",
        marginRight: 10,
        minWidth: 120,
    },
    redButton: {
        color: "#FF3535",
        backgroundColor: "rgba(255, 53, 53, 0.2)",
        minWidth: 120,
    },
    infoTypo: {
        display: "flex",
        alignItems: "center",
        color: "#7F899B",
        marginRight: "30%",
        whiteSpace: "nowrap",
        "& svg": {
            fontSize: 20,
            marginRight: 5,
        },
    },
}));
