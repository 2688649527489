import React from "react";
import { useMediaQuery, useTheme, makeStyles } from "@material-ui/core";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(15),
        color: "#000",
        fontWeight: 600,
    },
    titleContainer: {
        padding: theme.spacing(1, 2, 1, 1),
    },
    actionsContainer: {
        padding: theme.spacing(2),
        flexWrap: "wrap",
    },
    icon: {
        marginLeft: 20,
        border: "1px solid",
        borderRadius: 4,
        padding: theme.spacing(0.5),
        "& svg": {
            fontSize: 12,
        },
    },
}));

const ActionDialog = (props) => {
    const { onClose, actions, open, title } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullScreen={isMobile} fullWidth={true}>
            <DialogTitle className={classes.titleContainer} divider={false}>
                <IconButton onClick={onClose} size="small" className={classes.icon}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>{props.children}</DialogContent>
            {actions?.length > 0 && (
                <DialogActions className={classes.actionsContainer}>
                    {actions.map((action, index) => {
                        if (action.show === false) return;
                        return (
                            <Button
                                key={index}
                                {...action}
                                onClick={() => {
                                    !action.checkClose && onClose();
                                    action.onClick();
                                }}
                                disableElevation
                            >
                                {action.label}
                            </Button>
                        );
                    })}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ActionDialog;
