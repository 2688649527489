import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    tableContainer: {
        boxShadow: "0 0 20px 0 #d7dee366",
        marginTop: 15,
    },
    titleContainer: {
        backgroundColor: "#fff",
        borderRadius: theme.spacing(1, 1, 0, 0),
        textAlign: "left",
        padding: theme.spacing(1.5, 1, 1.5, 3),
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600,
        color: "#54617A",
    },
    head: {
        backgroundColor: "#F9FAFC",
        color: "#A3A9B7",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(16),
    },
    row: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        marginTop: 30,
    },
    greenButton: {
        "&:disabled": {
            backgroundColor: "rgba(0,255,87,0.1)",
            color: "#03DE73",
            fontWeight: 600,
            padding: theme.spacing(0.5, 1.5),
            borderRadius: "5px",
        },
    },
    redButton: {
        backgroundColor: "rgba(255,70,70,0.1)",
        color: "#FF5656",
        fontWeight: 600,
        border: "2px solid rgba(255,86,86,0.5)",
        padding: theme.spacing(0.5, 1.5),
        borderRadius: "5px",
        textDecoration: "underline",
    },
    attributeContainer: {
        height: "100%",
        width: "100%",
        maxHeight: 130,
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
    },
    attribute: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        color: "#7A8499",
        margin: theme.spacing(1.2, 1),
        "& span": {
            fontWeight: 500,
            color: "#A3A9B7",
        },
    },
    greenBackground: {
        backgroundColor: "rgba(0, 255, 87, 0.1)",
    },
}));
