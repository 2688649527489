import React from "react";
import { Card, CardContent, Grid, Typography, useMediaQuery, useTheme, Chip, Box } from "@material-ui/core";

import Styles from "./styleHeadCard";
import BadgedButton from "../../../UI/Buttons/BadgedButton";
import MenuButton from "../../../UI/Buttons/MenuButton";

const HeadCard = (props) => {
    const classes = Styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const { title, options, caption, chip, captionProps } = props;

    let buttonGroup;
    if (options !== undefined)
        buttonGroup = (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={3}
                style={{ padding: theme.spacing(3) }}
            >
                {options.map((option, index) => {
                    if (option.hide) return;
                    if (option.options) {
                        return (
                            <Grid item xs={6} key={index}>
                                <MenuButton {...option} />
                            </Grid>
                        );
                    }
                    return (
                        <Grid item xs={6} key={index}>
                            <BadgedButton {...option} />
                        </Grid>
                    );
                })}
            </Grid>
        );

    return (
        <Card className={classes.card}>
            <Box className={classes.cardHeader}>
                <Box>
                    <Typography variant="body2">{title}</Typography>
                    {chip && <Typography variant="body2">شناسه گزارش : {chip}</Typography>}
                </Box>
                <Box {...captionProps}>{caption}</Box>
            </Box>
            <Grid container>
                <Grid item xs={12} md={props.children && 5}>
                    <CardContent className={classes.content}>{buttonGroup && <>{buttonGroup}</>}</CardContent>
                </Grid>
                {props.children && (
                    <Grid item xs={12} md={7}>
                        <CardContent>{props.children}</CardContent>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};

export default HeadCard;
