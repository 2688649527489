import React from "react";
import axios from "../../api/instance";
import { useDispatch, useSelector } from "react-redux";
import { addUserInformation } from "../../store/actions/userActions";
import { fetchNotifications } from "../../store/actions/notificationActions";
import { getUserInformation } from "../../api/userAPI";
import { getGeolocations } from "../../api/sharedAPI";
import { setGeo } from "../../store/actions/geoActions";
import getRouteFromRole from "../../shared/getRouteFromRole";

const AuthHoc = (props) => {
    console.log("HOC")
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user);
    const provinces = useSelector((store) => store.geo);

    React.useLayoutEffect(() => {
        if (!user.id) {
            getUserInformation().then((response) => {
                dispatch(
                    addUserInformation({
                        ...response,
                        active_route: getRouteFromRole(response.active_role),
                    })
                );
                response.id && dispatch(fetchNotifications());
                axios.get("csrf-cookie").then((response) => {
                    console.log("OK");
                });
                if (provinces.length === 0) {
                    getGeolocations().then((response) => {
                        dispatch(setGeo(response));
                    });
                }
            });
        }
    }, []);

    return provinces.length > 0 ? props.children : "";
};

export default AuthHoc;
