import { makeStyles } from "@material-ui/core/styles";
import BG from "../../../assets/images/headerBG.jpg";

export default makeStyles({
    descriptionContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 20,
    },
    titleContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 20,
    },
    titleOne: {
        color: "#9e46f5",
        fontSize: 25,
        fontWeight: "900",
        fontFamily: "IRANSansBold",
        marginRight: 5,
    },
    titleTwo: {
        color: "#fff",
        fontSize: 25,
        fontWeight: "900",
        fontFamily: "IRANSansBold",
    },
});
