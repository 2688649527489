const addTagInputs = (defaults) => {
    return {
        key: {
            elementType: "search-list-input",
            elementConfig: {
                label: "عنوان",
                inputChange: true,
                options: [],
            },
            gridConfig: {
                xs: 12,
            },
            value: defaults.values?.key ?? "",
        },
        value: {
            elementType: "search-list-input",
            elementConfig: {
                label: "مقدار",
                inputChange: true,
                options: [],
            },
            gridConfig: {
                xs: 12,
            },
            value: defaults.values?.value ?? "",
        },
    };
};

export default addTagInputs;
