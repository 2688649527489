import React from "react";
import { Grid, Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import MainContainer from "../../../components/UI/Containers/MainContainer";
import CreateCard from "../../../components/Surfaces/Cards/CreateCard";
import ReportMap from "../../../components/Map/ReportMap";

import { editReport } from "../../../store/actions/reportsActions";
import getFormData from "../../../shared/getFormData";
import { findDeleted } from "../../../shared/processDocuments.js";
import formCreator from "../../../shared/formCreator";
import validateForm from "../../../shared/validateForm";
import reportInputs from "./reportInputs";

const EditReport = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { reportId, groupId } = useParams();
    const { loading, reports } = useSelector((store) => store.reports);
    const report = reports?.find((item) => item.id == reportId);
    const { alertType, show } = useSelector((store) => store.snackbar);
    const provinces = useSelector((store) => store.geo);
    const user = useSelector((store) => store.user);

    const [checkForm, setCheckForm] = React.useState(false);
    const [geoData, setGeoData] = React.useState({
        loading: false,
        isInitial: true,
        coords: report?.map_coords?.split(",").length === 2 ? report?.map_coords.split(",") : [],
        address: report?.map_address ?? "",
        values: [],
    });

    const province = provinces.find((item) => item.name === report?.province);
    const town = province?.towns.find((item) => item.name === report?.town);
    const city = town?.cities.find((item) => item.name === report?.city);
    const [formData, setFormData] = React.useState({
        values: {
            name: report?.name,
            category: report?.category,
            is_person: report?.is_person,
            is_court: report?.is_court,
            due_date: report?.due_date,
            documents: report?.documents,
            group_id: report?.group_id,
            province_id: province?.id,
            town_id: town?.id,
            city_id: city?.id,
        },
        disabled: {
            group_id: user.active_role_id !== "SUPERADMIN",
        },
    });

    React.useLayoutEffect(() => {
        const groupRoute = groupId ? `/group/${groupId}` : "";
        if (!report) history.push(`/${user.active_route}${groupRoute}/report/${reportId}`);
    }, [reports]);
    React.useEffect(() => {
        if (alertType === "success" && show === true) {
            const groupRoute = groupId ? `/group/${groupId}` : "";
            history.push(`/${user.active_route}${groupRoute}/report/${reportId}`);
        }
    }, [report]);
    React.useEffect(() => {
        if (!geoData.province) return;
        const province = provinces.find((item) => item.name === geoData.province);
        const town = province?.towns.find((item) => item.name === geoData?.town);
        const city = town?.cities.find((item) => item.name === geoData?.city);
        setFormData({
            ...formData,
            values: { ...formData.values, province_id: province?.id, town_id: town?.id, city_id: city?.id },
        });
    }, [geoData]);

    const handleSubmit = () => {
        const validatedForm = validateForm(formData);
        setCheckForm(true);
        if (validatedForm.error === false)
            dispatch(
                editReport(
                    getFormData({
                        ...formData.values,
                        report_id: reportId,
                        documents: formData.values.documents.filter((item) => !item.id),
                        deleted_documents: findDeleted(report.documents, formData.values.documents),
                        map_coords: geoData.coords.join(","),
                        map_address: geoData.address,
                    })
                )
            );
    };
    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedForm = { ...formData };
        updatedForm.values[inputIdentifier] = value;
        setFormData(updatedForm);
    };

    let formElements = formCreator(reportInputs(formData, user), inputChangedHandler);
    if (checkForm) {
        formElements = formCreator(validateForm(reportInputs(formData, user)).data, inputChangedHandler);
    }

    return (
        <MainContainer>
            <CreateCard type="ثبت تفییرات" onSubmit={handleSubmit} loading={loading} disabled={false}>
                <Grid container spacing={2}>
                    {formElements.slice(0, formElements.length - 1)}
                    <ReportMap
                        data={geoData}
                        setData={setGeoData}
                        mapGrid={{
                            xs: 12,
                            md: 8,
                        }}
                        textFieldGrid={{
                            xs: 12,
                            md: 4,
                        }}
                    />

                    <Divider style={{ width: "100%", height: 2, margin: "10px 0" }} />

                    {formElements.slice(formElements.length - 1)}
                </Grid>
            </CreateCard>
        </MainContainer>
    );
};

export default EditReport;
