import React from "react";
import { useDispatch } from "react-redux";
import { TextField, Grid, DialogActions, makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import LoadingButton from "../../../../components/UI/Buttons/LoadingButton";
import { sendOTP, validateOTP } from "../../../../api/userAPI";
import { showAlert } from "../../../../store/actions/snackbarActions";
import { closeDialog } from "../../../../store/actions/dialogActions";

const useStyles = makeStyles((theme) => ({
    confirmButton: {
        height: "100%",
        minHeight: 40,
        backgroundColor: green[600],
        fontWeight: 600,
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    resendButton: { height: "100%", fontSize: 12, minHeight: 40 },
}));

const ConfirmReport = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { responseId, changeAssigneeData } = props;
    const [formData, setFormData] = React.useState({ verification_code: "" });
    const [counter, setCounter] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    const send = () => {
        if (counter !== 0) return;
        setLoading(true);
        sendOTP().then((response) => {
            if (response.data.remaining_time === 120) dispatch(showAlert(response.data.message));
            if (response.type === "success") setCounter(response.data.remaining_time);
            setLoading(false);
        });
    };

    React.useEffect(() => {
        send();
    }, []);
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (counter > 0) setCounter((counter) => --counter);
            else clearInterval(interval);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [counter]);
    const inputChangedHandler = (event) => {
        const id = event.target.id;
        const val = event.target.value;
        const formIsValid = val ? true : false;
        setFormData((state) => ({ ...state, [id]: val }));
    };
    const onSubmitHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        validateOTP({ ...formData, action: "EXPERT_CONFIRMATION", report_response_id: responseId }).then((response) => {
            if (response.type === "success") {
                changeAssigneeData({
                    status: "CONFIRMED",
                });
                dispatch(closeDialog());
            }
            setLoading(false);
            dispatch(showAlert(response.data.message, response.type));
        });
    };

    return (
        <DialogActions>
            <Grid container spacing={1} component="form" onSubmit={onSubmitHandler}>
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        variant="outlined"
                        label="کد تایید"
                        id="verification_code"
                        onChange={inputChangedHandler}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        color="primary"
                        size="small"
                        loading={loading}
                        className={classes.confirmButton}
                        disabled={formData["verification_code"] == ""}
                        disableElevation
                        fullWidth
                    >
                        تایید
                    </LoadingButton>
                </Grid>
                <Grid item xs={4}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.resendButton}
                        onClick={send}
                        disabled={loading}
                        disableElevation
                        fullWidth
                    >
                        {counter !== 0 ? counter + "ثانیه تا ارسال مجدد" : "ارسال مجدد کد تایید "}
                    </LoadingButton>
                </Grid>
            </Grid>
        </DialogActions>
    );
};

export default ConfirmReport;
