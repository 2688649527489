import React from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../../../api/instance";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../store/actions/snackbarActions";

const autoCompeleteStyles = makeStyles((theme) => ({
    inputRoot: { backgroundColor: "#D9EFFF" },
}));

const WageCalculator = ({ changeForm }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const autoCompeleteClasses = autoCompeleteStyles();
    const [Allfields, setAllField] = React.useState([]);
    const [fields, setField] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [formData, setFormData] = React.useState({});

    const changeSelect = (id, value) => {
        if (id === "group") {
            setFormData({ ...formData, field: null, group: value });
            setField([]);
            if (value !== null) {
                if (value.id == 0) {
                    setField(Allfields);
                } else {
                    setField(Allfields.filter((field) => field.group_id === value.id));
                }
            }
        } else {
            setFormData({ ...formData, [id]: value });
        }
    };
    React.useEffect(() => {
        axios
            .get("/calculator/fields")
            .then((response) => {
                setAllField(response.data);
            })
            .catch((error) => {});
        axios
            .get("/calculator/groups")
            .then((response) => {
                setGroups([{ id: 0, name: "همه گروه ها" }, ...response.data]);
            })
            .catch((error) => {});
    }, []);

    const submitForm = () => {
        if (formData.field && formData.group) {
            history.push({
                pathname: "/wageCalculator",
                state: { ...formData },
            });
        } else {
            dispatch(showAlert("لطفا گروه و رشته را انتخاب نمایید", "error"));
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
                <Autocomplete
                    fullWidth
                    id="group"
                    size="small"
                    options={groups}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => changeSelect("group", value)}
                    classes={autoCompeleteClasses}
                    renderInput={(params) => <TextField {...params} placeholder="گروه" variant="outlined" />}
                />
            </Grid>
            <Grid item md={6} xs={6}>
                <Autocomplete
                    fullWidth
                    id="field"
                    size="small"
                    options={fields}
                    value={fields.length === 0 ? null : formData.field}
                    getOptionLabel={(option) => option.field}
                    onChange={(e, value) => changeSelect("field", value)}
                    classes={autoCompeleteClasses}
                    noOptionsText="ابتدا گروه را انتخاب نمایید"
                    renderInput={(params) => <TextField {...params} placeholder="رشته" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    style={{
                        backgroundColor: "#51B6FF",
                        minHeight: 38,
                    }}
                    onClick={submitForm}
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    محاسبه
                </Button>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    style={{
                        color: "#51B6FF",
                    }}
                    onClick={() => changeForm("factor")}
                >
                    مشاهده فاکتور با کد رهگیری
                </Button>
            </Grid>
        </Grid>
    );
};

export default WageCalculator;
