import { OPEN_DIALOG, CLOSE_DIALOG } from "../actions/dialogActions";

const initialState = {
  show: false,
  data: null,
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      state.show = true;
      state.data = action.data;
      return {...state};
    case CLOSE_DIALOG:
      return { show: false };
    default:
      return state;
  }
};

export default dialogReducer;
