import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, BottomNavigation, BottomNavigationAction, Typography, IconButton } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { NavLink } from "react-router-dom";

import getRouteFromRole from "../../../shared/getRouteFromRole.js";

import nav from "../../../routes/nav";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#363740",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 100,
        borderTopRightRadius: 15,
        borderTopLeftRadius: 15,
        overflow: "hidden",
        flexWrap: "wrap",
        height: 55,
        transition: "height 0.3s",
        "@media print": {
            display: "none",
        },
    },
    text: {
        fontSize: 10,
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    actionRoot: {
        // minWidth: "20%",
        "& span": {
            color: "white",
            opacity: 0.5,
        },
    },
    selected: {
        "& span": {
            color: "white",
            opacity: 1,
        },
        "& svg": {
            fontSize: 28,
        },
        "& p": {
            fontSize: 10,
        },
    },
    icon: {
        fontSize: "inherit",
    },
    moreContainer: {
        display: "flex",
        flexDirection: "column",
    },
    caption: {
        fontSize: 8,
        marginTop: -16,
        marginLeft: 5,
        color: "#fff",
    },
    arrow: {
        "& svg": {
            transition: "all .3s",
            fontSize: 30,
            color: "#fff",
        },
    },
}));

const MobileAppBar = (props) => {
    const classes = useStyles();
    const user = useSelector((store) => store.user);
    const [value, setValue] = React.useState("dashboard");
    const [open, setOpen] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const suitableNav = nav.filter(
        (item) => item.accessibleRoles === "all" || item.accessibleRoles.includes(user.active_role)
    );

    return (
        <BottomNavigation
            value={value}
            onChange={handleChange}
            className={classes.appBar}
            style={{ height: open && 55 * Math.ceil(suitableNav.length / 4) }}
            showLabels
        >
            {suitableNav.length > 4 && (
                <div className={classes.moreContainer}>
                    <IconButton size="medium" className={classes.arrow} onClick={() => setOpen(!open)}>
                        {open ? <ArrowDropUpIcon style={{ transform: "rotate(-180deg)" }} /> : <ArrowDropUpIcon />}
                    </IconButton>
                    <Typography variant="caption" className={classes.caption}>
                        گزینه‌های بیشتر
                    </Typography>
                </div>
            )}
            {suitableNav.map((action, index) => {
                const Icon = action.icon;
                const link = action.roleIndependent
                    ? "/" + action.link
                    : "/" + getRouteFromRole(user.active_role) + action.link;
                return (
                    <BottomNavigationAction
                        key={action.key}
                        component={NavLink}
                        to={link}
                        value={action.key}
                        icon={<Icon />}
                        label={
                            <Typography variant="subtitle2" component="p" className={classes.text}>
                                {action.label}
                            </Typography>
                        }
                        classes={{ root: classes.actionRoot, selected: classes.selected }}
                    />
                );
            })}
        </BottomNavigation>
    );
};

export default MobileAppBar;
