import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        "& label ": {
            fontSize: 14,
        },
    },
    option: {
        fontSize: 14,
        paddingLeft: theme.spacing(4),
    },
    input: {
        fontSize: 15,
    },
    paper: {
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    listbox: {
        // maxHeight: 150,
    },
}));

const SearchListInput = (props) => {
    const classes = useStyles();
    const {
        value,
        label,
        options,
        onChange,
        inputChange,
        getOptionLabel,
        inputParams,
        required,
        ...otherProps
    } = props;
    return (
        <Autocomplete
            getOptionLabel={(option) => option.name ?? ""}
            options={options ? options : []}
            fullWidth
            onChange={!inputChange ? (event, value) => onChange(value) : ""}
            onInputChange={(event, value) => onChange(value)}
            noOptionsText="بی نتیجه"
            inputValue={value?.name ? value.name : value ?? ""}
            clearOnBlur={!inputChange}
            value={value?.name && value}
            disabled={otherProps?.disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    helperText={otherProps?.helperText}
                    error={otherProps?.error}
                    disabled={otherProps?.disabled}
                    variant={props.variant}
                    {...inputParams}
                />
            )}
            classes={{
                root: classes.root,
                option: classes.option,
                input: classes.input,
                noOptions: classes.input,
                paper: classes.paper,
                listbox: classes.listbox,
            }}
        />
    );
};

export default SearchListInput;
