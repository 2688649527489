import React from "react";
import clsx from "clsx";
import { Button, makeStyles, Typography } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 171,
        backgroundColor: "#FA5959",
        color: "#fff",
        marginTop: 10,
        padding: theme.spacing(0.4),
        "&:hover": {
            backgroundColor: "rgba(255, 115, 115, 1)",
        },
    },
    label: {
        backgroundColor: "rgba(255, 115, 115, 1)",
        padding: theme.spacing(0.7, 1),
    },
}));

const SupportButton = (props) => {
    const classes = useStyles();
    const { ...otherProps } = props;

    return (
        <Button
            variant="contained"
            component="a"
            href="https://support.edalatkhane.org/"
            target="_blank"
            disableElevation
            {...otherProps}
            startIcon={<CallIcon style={{ marginRight: 10 }} />}
            className={clsx(classes.root, otherProps.className)}
        >
            <Typography className={classes.label}>تماس با پشتیبانی</Typography>
        </Button>
    );
};

export default SupportButton;
