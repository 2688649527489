import React from "react";

const Questionnaire = () => {
  React.useEffect(() => {
      let script = document.createElement("script");
      script.src = "https://survey.porsline.ir/embed/iLp7et9/?height=100%;width=100%;border=none;";
      script.async = true;
      script.type = "text/javascript";
      document.getElementById("iLp7et9").appendChild(script);
  }, []);
  return <div id="iLp7et9" />;
};

export default Questionnaire;