import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useRoutes } from "../../../routes/routes";
import { matchRoutes } from "react-router-config";

import { Breadcrumbs, Typography, makeStyles, Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3, 0, 0, 4),
        "& nav": {
            width: "fit-content",
            backgroundColor: "#fff",
            borderRadius: 8,
            padding: theme.spacing(1, 2),
            boxShadow: "0 0 20px 0 #d7dee366",
        },
    },
    crumbItem: {
        position: "relative",
        padding: theme.spacing(2, 0),
        textDecoration: "none",
        color: "#D9D9D9",
    },
    title: {
        fontSize: theme.typography.pxToRem(13),
        textAlign: "center",
        fontWeight: 500,
        padding: theme.spacing(0, 0.5),
    },
    lastCrumb: {
        color: "#54617A",
    },
}));

const CustomBreadcrumbs = () => {
    const classes = useStyles();
    const routes = useRoutes();
    const location = useLocation();

    const userData = useSelector((state) => state.user);
    let routeLocation = location.pathname;
    const branch = matchRoutes(routes, routeLocation);

    const crumbs = routes
        .filter((route) => route.role === userData.active_role || route.role === "all")
        .filter(({ path }) => branch[0]?.match.path.includes(path))
        .map(({ path, ...rest }) => ({
            path: Object.keys(branch[0]?.match.params).length
                ? Object.keys(branch[0]?.match.params).reduce(
                      (path, param) => path.replace(`:${param}`, branch[0]?.match.params[param]),
                      path
                  )
                : path,
            params: branch[0]?.match.params,
            ...rest,
        }))
        .reverse();

    let updatedCrumbs = [];
    crumbs.forEach((crumb) => {
        if (Array.isArray(crumb.name(crumb.params)) === true) {
            crumb.name(crumb.params).forEach((item) => {
                updatedCrumbs.push({ ...crumb, ...item });
            });
        } else updatedCrumbs.push(crumb);
    });

    if (updatedCrumbs.length < 1) return "";
    return (
        <div className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" separator={<ArrowBackIosIcon style={{ fontSize: 12 }} />}>
                {updatedCrumbs.map((crumb, index) => {
                    return (
                        crumb.name(crumb.params) && (
                            <Link
                                to={crumb.path}
                                className={
                                    index === updatedCrumbs.length - 1
                                        ? clsx(classes.lastCrumb, classes.crumbItem)
                                        : classes.crumbItem
                                }
                                key={index}
                            >
                                <Grid container direction="row">
                                    {index === 0 && <HomeWorkIcon style={{ fontSize: 20, marginLeft: 10 }} />}
                                    <Typography variant="body1" className={classes.title} noWrap>
                                        {crumb.name(crumb.params)}
                                    </Typography>
                                </Grid>
                            </Link>
                        )
                    );
                })}
            </Breadcrumbs>
        </div>
    );
};

export default CustomBreadcrumbs;
