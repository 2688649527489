import React from "react";
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
    },
}));

const ConfirmDialog = (props) => {
    const { onClose, open, title } = props;
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullScreen={true} fullWidth={true}>
            <DialogTitle className={classes.titleContainer}>
                <Typography variant="body1" component="p" className={classes.title}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>{props.children}</DialogContent>
            <DialogActions disableSpacing style={{ padding: 20 }}>
                <Button size="large" color="secondary" variant="contained" onClick={onClose}>
                    لغو
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
