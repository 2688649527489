const addSubgroupInputs = (defaults) => {
    return {
        name: {
            elementType: "text-field",
            elementConfig: { label: "نام سازمان", variant: "outlined" },
            validation: { required: true },
            value: defaults?.name ?? "",
            gridConfig: {
                xs: 12,
                md: 6,
            },
        },
        type: {
            elementType: "select-input",
            elementConfig: {
                label: "نوع سازمان",
                options: [
                    { label: "کسب و کار", value: "BUSINESS" },
                    { label: "ناظر", value: "SUPERVISOR" },
                ],
                variant: "outlined",
            },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            hide: defaults.hide?.type,
            value: defaults.type ?? "BUSINESS",
        },
        mobile_number: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره موبایل مدیر سازمان",
                type: "number",
                variant: "outlined",
            },
            validation: {
                required: true,
                pattern: /^09[0-9]{9}$/,
            },
            gridConfig: {
                xs: 12,
            },
            value: defaults?.mobile_number ?? "",
        },
        description: {
            elementType: "text-field",
            elementConfig: {
                label: "توضیحات",
                multiline: true,
                variant: "outlined",
                rows: "3",
            },
            value: defaults?.description ?? "",
            gridConfig: {
                xs: 12,
            },
        },
    };
};

export default addSubgroupInputs;
