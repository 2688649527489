import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../store/actions/snackbarActions";

const styles = makeStyles({
    inputFieldContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    input: {
        backgroundColor: "#fff",
    },
});

const ShowFactor = ({ changeForm }) => {
    const dispatch = useDispatch();
    const classes = styles();
    const history = useHistory();
    const [trackCode, setTrackCode] = React.useState("");
    const changeInput = (e) => {
        setTrackCode(e.target.value);
    };
    const submitForm = (e) => {
        e.preventDefault();
        if (trackCode !== "") {
            history.push(`/expert/wagefactor?tracking_code=${trackCode}`);
        } else {
            dispatch(showAlert("کد رهگیری نمیتواند خالی باشد", "error"));
        }
    };
    return (
        <form onSubmit={submitForm} style={{ width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12} className={classes.inputFieldContainer}>
                    <TextField
                        InputProps={{
                            className: classes.input,
                        }}
                        fullWidth
                        size="small"
                        placeholder="شماره فاکتور"
                        variant="outlined"
                        id="mobile_number"
                        onChange={changeInput}
                    />
                </Grid>
                <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.inputFieldContainer}
                    style={{ paddingTop: 0, marginTop: 5 }}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            fontSize: 15,
                            fontFamily: "IRANSansBold",
                            backgroundColor: "#9A44FC",
                        }}
                        type="submit"
                    >
                        مشاهده فاکتور
                    </Button>
                </Grid>
                <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.inputFieldContainer}
                    style={{ paddingTop: 0, marginTop: 5 }}
                >
                    <Button
                        fullWidth
                        color="primary"
                        variant="outlined"
                        style={{
                            borderColor: "rgb(242, 200, 200)",
                            color: "rgb(242, 200, 200)",
                        }}
                        onClick={() => changeForm("calculate")}
                    >
                        بازگشت
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default ShowFactor;
