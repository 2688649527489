import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    container: {
        backgroundColor: "#fff",
        borderRadius: 5,
        textAlign: "justify",
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 15,
        "& p": { fontSize: 14 },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1.5),
        },
    },
    inputContainer: {
        borderRadius: 5,
        backgroundColor: "#eceff5",
        padding: theme.spacing(3),
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1.5),
        },
    },
    userInputContainer: {
        backgroundColor: "#eceff5",
        borderRadius: 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: theme.spacing(2),
        width: "contain",
        position: "relative",
        marginTop: 15,
        "& p": {
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 600,
        },
        "@media print": {
            "&::after": {
                content: `""`,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "0.5em",
                borderTop: "1px solid #000",
                zIndex: "10",
            },
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    formControl: {
        backgroundColor: "#eceff5",
        borderRadius: 5,
        width: "100%",
        margin: 0,
        textAlign: "justify",
        "& svg": {
            fontSize: 28,
        },
        "& span": {
            fontSize: 14,
        },
    },
    submit: {
        backgroundColor: "#68e4ab",
        marginTop: 10,
        padding: theme.spacing(2),
        borderRadius: 10,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        "&:hover": {
            backgroundColor: "#68ffaf",
        },
        "&:disabled": {
            backgroundColor: "rgba(104,255,176,0.2)",
        },
    },
    priceInput: {
        marginTop: 10,
    },
    priceAction: {
        backgroundColor: "#68e4ab",
        minHeight: 55,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        "&:hover": {
            backgroundColor: "#68ffaf",
        },
    },
    resultContainer: {
        backgroundColor: "#fff",
        border: "2px solid #a7a7a7",
        borderRadius: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: theme.spacing(2, 4),
        marginTop: 15,
        "& p": {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: 700,
        },
    },
    codeConatiner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
        width: "contain",
        marginBottom: 10,
    },
    trackCode: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    title: {
        marginTop: 16,
        fontWeight: 600,
    },
    message: {
        color: "rgba(0, 0, 0, 0.7)",
    },
    LogoImg: {
        width: 80,
        "& img": {
            objectFit: "contain",
        },
    },
    articlesTitle: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        position: "relative",
        "@media print": {
            "&::after": {
                content: `""`,
                position: "absolute",
                bottom: -12,
                left: 0,
                right: 0,
                height: "0.5em",
                borderTop: "3px solid grey",
                zIndex: "10",
            },
        },
    },
    articleContainer: {
        borderRadius: 5,
        backgroundColor: "#eceff5",
        padding: theme.spacing(2),
        position: "relative",
        boxShadow: "none",
        width: "100%",
        "& p ": {
            fontSize: theme.typography.pxToRem(14),
        },
    },
    refButton: {
        position: "absolute",
        top: 0,
        right: 24,
        borderRadius: "0px 0px 6px 6px",
        backgroundColor: "#0089c7b0",
        fontSize: theme.typography.pxToRem(12),

        zIndex: 10,
    },
    fillButton: {
        backgroundColor: "#68e4ab",
        minHeight: 30,
        width: 200,
        marginTop: 10,
        color: "#fff",
        "&:hover": {
            backgroundColor: "#68ffaf",
        },
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.26)",
        },
    },
    elevate: {
        animation: `$elevation 3s ${theme.transitions.easing.easeInOut}`,
        width: "100%",
    },
    "@keyframes elevation": {
        "0%": {
            boxShadow: "0 0 0 0",
        },
        "10%": {
            boxShadow: "0 0 0 0",
        },
        "50%": {
            boxShadow: "0 4px 10px 4px rgba(0,0,0,0.5)",
        },
        "70%": {
            boxShadow: "0 4px 10px 4px rgba(0,0,0,0.5)",
        },
        "100%": {
            boxShadow: "0 0 0 0",
        },
    },
}));
