import React from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DataTable from "../DataTable/DataTable";
import { statusColors } from "../../../shared/checkStatus";
import locale from "../../../shared/locale.js";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        boxShadow: "0 0 20px 0 #d7dee366",
        marginTop: 15,
        borderRadius: 4,
    },
    head: {
        backgroundColor: "#DEDEDE",
        color: theme.palette.table.main,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        "&:nth-of-type(3)": {
            textAlign: "left",
        },
        borderWidth: 0,
    },
    row: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        marginTop: 30,
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        "&:nth-of-type(3)": {
            textAlign: "left",
        },
        borderWidth: 2,
    },
    button: {
        color: "#A3A9B7",
        backgroundColor: "rgba(196,196,196,0.3)",
        padding: theme.spacing(0.2, 3),
        borderRadius: 5,
    },
    status: {
        paddingLeft: theme.spacing(1),
        fontSize: theme.typography.pxToRem(12),
    },
    typo: {
        margin: theme.spacing(2, 0),
        fontWeight: 600,
    },
}));

const ReportsTable = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const user = useSelector((store) => store.user);
    const { reports, groupRoute } = props;

    const tableBody = reports.map((report, index) => {
        let directRoute = `/${user.active_route}/report/${report.id}`;
        if (groupRoute) directRoute = `/${user.active_route}/group/${report.group_id}/report/${report.id}`;
        return {
            data: {
                index: index + 1,
                name: report.name,
                status: (
                    <span
                        className={classes.status}
                        style={{ borderRight: `3px solid ${statusColors[report.status]}` }}
                    >
                        {locale(report.status)}
                    </span>
                ),
                id: report.chip,
                actions: (
                    <Button
                        variant="contained"
                        disableElevation
                        size="small"
                        onClick={() => history.push(directRoute)}
                        className={classes.button}
                    >
                        مشاهده
                    </Button>
                ),
            },
            config: {
                className: classes.row,
                align: "center",
            },
        };
    });
    if (reports.length === 0) {
        return (
            <Typography variant="body1" color="textSecondary" className={classes.typo}>
                گزارشی وجود ندارد.
            </Typography>
        );
    }
    return (
        <Box className={classes.tableContainer}>
            <DataTable
                head={{
                    data: {
                        index: "ردیف",
                        name: "نام گزارش",
                        status: "وضعیت",
                        id: "شناسه گزارش",
                        actions: "جزئیات",
                    },
                    config: {
                        className: classes.head,
                        align: "center",
                    },
                }}
                body={tableBody}
            />
        </Box>
    );
};

export default ReportsTable;
