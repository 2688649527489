import React from "react";
import { Box, Divider, Typography, IconButton, Fade } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import Styles from "./styleReportFramework";

const ReportTag = (props) => {
    const classes = Styles();
    const { header, content, onRemove } = props;
    const [open, setOpen] = React.useState(true);

    const handleRemove = () => {
        setOpen(false);
        setTimeout(() => {
            onRemove();
        }, 350);
    };

    return (
        <Fade in={open} timeout={{ enter: 0, exit: 350 }}>
            <Box className={classes.container}>
                <IconButton className={classes.icon} onClick={handleRemove} size="small">
                    <CloseRoundedIcon />
                </IconButton>
                <Typography variant="body2" className={classes.typo}>
                    {header}
                </Typography>
                <Divider orientation="vertical" className={classes.dividerRotated} flexItem />
                <Typography variant="body2" className={classes.typo}>
                    {content}
                </Typography>
            </Box>
        </Fade>
    );
};

export default ReportTag;
