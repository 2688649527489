import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Divider } from "@material-ui/core";

import MainContainer from "../../../components/UI/Containers/MainContainer";
import CreateCard from "../../../components/Surfaces/Cards/CreateCard";
import SupportButton from "../../../components/UI/Buttons/SupportButton";
import ReportMap from "../../../components/Map/ReportMap";

import { createReport } from "../../../store/actions/reportsActions";
import getFormData from "../../../shared/getFormData";
import formCreator from "../../../shared/formCreator";
import validateForm from "../../../shared/validateForm";
import reportInputs from "./reportInputs";

const CreateReport = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { reports, loading } = useSelector((store) => store.reports);
    const { alertType, show } = useSelector((store) => store.snackbar);
    const user = useSelector((store) => store.user);
    const provinces = useSelector((store) => store.geo);

    const report = reports[0];
    const [formData, setFormData] = React.useState({ values: { is_court: 0, is_person: 1 } });
    const [geoData, setGeoData] = React.useState({
        loading: false,
        coords: [],
        address: null,
        values: [],
    });
    const [checkForm, setCheckForm] = React.useState(false);

    React.useEffect(() => {
        if (alertType === "success" && show === true) {
            const groupRoute = user.group_route ? `/group/${report.group_id}` : "";
            history.push(`/${user.active_route}${groupRoute}/report/${report?.id}`);
        }
    }, [reports]);

    const handleSubmit = () => {
        const validatedForm = validateForm(reportInputs(formData));
        setCheckForm(true);
        if (validatedForm.error === false)
            dispatch(
                createReport(
                    getFormData({
                        ...formData.values,
                        map_coords: geoData.coords.join(","),
                        map_address: geoData.address,
                    })
                )
            );
    };
    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedForm = { ...formData };
        updatedForm.values[inputIdentifier] = value;
        setFormData(updatedForm);
    };

    React.useEffect(() => {
        const province = provinces.find((item) => item.name === geoData.province);
        const town = province?.towns.find((item) => item.name === geoData?.town);
        const city = town?.cities.find((item) => item.name === geoData?.city);
        setFormData({
            ...formData,
            values: { ...formData.values, province_id: province?.id, town_id: town?.id, city_id: city?.id },
        });
    }, [geoData]);

    let formElements = formCreator(reportInputs(formData, user), inputChangedHandler);
    if (checkForm) {
        formElements = formCreator(validateForm(reportInputs(formData, user)).data, inputChangedHandler);
    }

    return (
        <MainContainer>
            <CreateCard type="تعریف گزارش" onSubmit={handleSubmit} loading={loading} disabled={false}>
                <Grid container spacing={2}>
                    {formElements.slice(0, formElements.length - 1)}
                    <ReportMap
                        data={geoData}
                        setData={setGeoData}
                        mapGrid={{
                            xs: 12,
                            md: 8,
                        }}
                        textFieldGrid={{
                            xs: 12,
                            md: 4,
                        }}
                    />
                    <Divider style={{ width: "100%", height: 2, margin: "10px 0" }} />

                    {formElements.slice(formElements.length - 1)}
                </Grid>
            </CreateCard>
            <SupportButton />
        </MainContainer>
    );
};

export default CreateReport;
