import React from "react";
import { Provider } from "react-redux";
import "./App.css";

import MainRoutes from "./routes/MainRoutes";
import ThemeProvider from "./components/ThemeProvider";
import MasterDialog from "./components/Dialogs/MasterDialog";
import Snackbar from "./components/Static/Snackbar/Snackbar";

import store from "./store/store";

function App() {
    return (
        <ThemeProvider>
            <Provider store={store}>
                <div className="App">
                    <Snackbar />
                    <MainRoutes />
                    <MasterDialog />
                </div>
            </Provider>
        </ThemeProvider>
    );
}

export default App;
