import React from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";

import MainContainer from "../../components/UI/Containers/MainContainer";
import ReportSection from "./Components/ReportSection";
import useStickyState from "../../hooks/stickyState";
import Styles from "./Components/styleReportFramework";

const initial = {
    values: {
        tags: [],
    },
    options: {},
};

const ReportFramework = (props) => {
    const dispatch = useDispatch();
    const classes = Styles();
    const [formData, setFormData] = useStickyState(
        [
            {
                values: {
                    tags: [],
                },
                options: {},
            },
        ],
        "reportForm"
    );

    const inputChangedHandler = (value, inputIdentifier, formIndex) => {
        let updatedForm = [...formData];
        updatedForm[formIndex].values[inputIdentifier] = value;
        setFormData(updatedForm);
    };
    const addSection = () => {
        let updatedForm = [...formData, initial];
        setFormData(updatedForm);
    };

    return (
        <MainContainer>
            {formData.map((section, index) => (
                <ReportSection
                    values={section.values}
                    options={section.options}
                    changeHandler={(value, identifier) => inputChangedHandler(value, identifier, index)}
                    expanded={index === formData.length - 1}
                />
            ))}
            <Button
                variant="contained"
                size="large"
                fullWidth
                disableElevation
                onClick={addSection}
                className={classes.newSectionButton}
            >
                <AddIcon />
                ثبت اطلاعات جدید
            </Button>
        </MainContainer>
    );
};

export default ReportFramework;
