import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid, Slide, Popover, Typography } from "@material-ui/core";

import OrganizationCard from "./OrganizationCard";
import { fetchRoles } from "../../../../store/actions/rolesActions";

const useStyles = makeStyles((theme) => ({
    paper: {
        height: "100vh",
        backgroundColor: "transparent",
        borderRadius: 0,
        maxHeight: "unset",
        boxShadow: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(16),
        color: "#DDE2FF",
    },
    list: {
        width: 400,
        padding: theme.spacing(2, 3.5),
    },
    typo: {
        color: "#fff",
        marginRight: 10,
    },
    MuiPopover: {
        root: {
            zIndex: "1298 !important",
            backgroundColor: "rgba(27, 27, 27, 0.4)",
        },
    },
}));

const CardsDrawer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { anchor, open, onClose } = props;
    const roles = useSelector((store) => store.roles.group_roles);

    React.useEffect(() => {
        dispatch(fetchRoles());
    }, []);

    return (
        <Popover
            anchorEl={anchor.current}
            open={open}
            onClose={() => onClose()}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
            marginThreshold={0}
            PaperProps={{ className: classes.paper }}
            TransitionComponent={Slide}
            TransitionProps={{ direction: "left" }}
        >
            <Grid container spacing={2} className={classes.list}>
                <Typography variant="body2" gutterBottom className={classes.typo}>
                    نقش سازمانی خود را انتخاب کنید
                </Typography>
                {roles.map((role, index) => (
                    <Slide in={true} direction="left" timeout={{ enter: 200 * (index + 1), exit: 100 }}>
                        <Grid item style={{ width: "100%" }}>
                            <OrganizationCard role={role} onClose={onClose} />
                        </Grid>
                    </Slide>
                ))}
            </Grid>
        </Popover>
    );
};

export default CardsDrawer;
