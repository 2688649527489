import React from "react";

import { Typography, makeStyles, Collapse, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        flexDirection: "column",
    },
    collapse: {
        width: "100%",
    },
    button: {
        "&::after": {
            content: `""`,
            position: "absolute",
            bottom: 25,
            left: 0,
            right: 0,
            height: "0.5em",
            borderTop: "2px solid grey",
            zIndex: "10",
        },
    },
    readMore: {
        fontSize: 12,
        color: "#fff",
        borderRadius: 0,
        marginTop: 10,
        backgroundColor: "rgba(0,0,0,0.2)",
    },
}));

const ReadMoreTypo = (props) => {
    const classes = useStyles();
    const typoRef = React.useRef();

    const [readMore, setReadMore] = React.useState(false);
    const [height, setHeight] = React.useState(false);

    const { collapseHeight, children, ...otherProps } = props;
    React.useEffect(() => {
        setHeight(typoRef.current?.offsetHeight);
    }, [children]);

    return (
        <div className={classes.container}>
            <Collapse collapsedHeight={collapseHeight} in={readMore} className={classes.collapse}>
                <Typography {...otherProps} ref={typoRef}>
                    {children}
                </Typography>
            </Collapse>
            {height > collapseHeight && (
                <div className={classes.button}>
                    <Button
                        className={classes.readMore}
                        variant="contained"
                        disableElevation
                        size="small"
                        onClick={() => setReadMore((state) => !state)}
                    >
                        {readMore ? "بستن" : "مشاهده بیشتر"}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ReadMoreTypo;
