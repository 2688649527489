import React from "react";
import { useDispatch } from "react-redux";
import { Grid, DialogActions, makeStyles, DialogContent } from "@material-ui/core";

import Button from "../../../UI/Buttons/LoadingButton";

import inquireInputs from "./inquireInputs";
import formCreator from "../../../../shared/formCreator";
import getFormData from "../../../../shared/getFormData";
import validateForm from "../../../../shared/validateForm";
import { getCompetences } from "../../../../api/sharedAPI";
import { uploadUserLicesnse } from "../../../../api/userAPI";
import { showAlert } from "../../../../store/actions/snackbarActions";
import { closeDialog } from "../../../../store/actions/dialogActions";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    submit: {
        minWidth: 120,
    },
}));

const InquireDialog = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [formData, setFormData] = React.useState({ values: {}, options: { competences: [] } });
    const [checkForm, setCheckForm] = React.useState(false);

    React.useLayoutEffect(() => {
        getCompetences().then((response) => {
            const updatedResponse = response.map((item) => ({
                name: item.field,
                value: item.id,
                competences: item.competences.map((competence) => ({
                    name: competence.competence,
                    value: competence.id,
                })),
            }));
            setFormData({ ...formData, options: { ...formData.options, expertise: updatedResponse } });
        });
    }, []);
    React.useEffect(() => {
        if (formData.values.expertise)
            setFormData({
                ...formData,
                options: { ...formData.options, competences: formData.values.expertise?.competences },
            });
    }, [formData.values.expertise]);

    const handleSubmit = () => {
        const validatedForm = validateForm(inquireInputs(formData));
        setCheckForm(true);
        if (validatedForm.error === false) {
            uploadUserLicesnse(
                getFormData({
                    ...formData.values,
                    expertise: formData.values.expertise?.name,
                    competences: formData.values.competences?.map((item) => item.value),
                })
            ).then((response) => {
                dispatch(showAlert(response.message, response.type));
                if (response.type === "success") dispatch(closeDialog());
            });
        }
    };
    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedForm = { ...formData };
        updatedForm.values[inputIdentifier] = value;
        setFormData(updatedForm);
    };
    let formElements = formCreator(inquireInputs(formData), inputChangedHandler);
    if (checkForm) {
        formElements = formCreator(validateForm(inquireInputs(formData)).data, inputChangedHandler);
    }

    return (
        <>
            <DialogContent className={classes.content}>
                <Grid container spacing={2}>
                    {formElements}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disableElevation
                >
                    ثبت اطلاعات
                </Button>
            </DialogActions>
        </>
    );
};

export default InquireDialog;
