import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import copy from "clipboard-copy";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 10,
        padding: "4px 12px",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            cursor: "pointer",
        },
    },
}));

const ClipboardField = (props) => {
    const classes = useStyles();
    const { text, iconProps, textProps, containerProps } = props;
    const [tooltipText, setTooltipText] = useState("کپی");

    const copyToClipBoard = () => {
        copy(text);
        setTooltipText("کپی شد.");
    };

    return (
        <Tooltip title={tooltipText}>
            <div className={classes.root} {...containerProps} onClick={copyToClipBoard}>
                <FileCopyIcon {...iconProps} />
                <Typography {...textProps}>{text}</Typography>
            </div>
        </Tooltip>
    );
};

export default ClipboardField;
