import React from "react";
import { Box, Button, makeStyles, TableCell, TableRow, Collapse, Typography } from "@material-ui/core";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
    seeMore: {
        borderRadius: 5,
        backgroundColor: "rgba(196,196,196,0.3)",
        padding: theme.spacing(0.5, 1.5),
        "& p": {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 600,
            color: "#A3A9B7",
        },
        "& svg": {
            color: "#A3A9B7",
        },
        [theme.breakpoints.down("sm")]: {
            "& p": {
                display: "none",
            },
        },
    },
}));

const CollapsibleRow = (props) => {
    const classes = useStyles();
    const { data = {}, config = {}, disableCollapse } = props;

    const [open, setOpen] = React.useState(false);
    const head_keys = Object.keys(data);

    const cells = head_keys.map((key, index) => {
        return (
            <TableCell key={index} {...config}>
                {data[key] ?? ""}
            </TableCell>
        );
    });

    const collapseButton = (
        <Button size="small" onClick={() => setOpen(!open)} className={classes.seeMore}>
            <Typography variant="caption" color="textSecondary" component="p">
                مشاهده
            </Typography>
            <KeyboardArrowUpIcon
                style={{
                    transition: "all .3s",
                    transform: open ? "rotate(0deg)" : "rotate(-180deg)",
                }}
            />
        </Button>
    );

    return (
        <React.Fragment>
            <TableRow>
                {cells}
                {!disableCollapse && <TableCell {...config}>{collapseButton}</TableCell>}
            </TableRow>
            {!disableCollapse && (
                <TableRow style={{ height: 1 }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>{props.children}</Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
};

export default CollapsibleRow;
