import React from "react";
import { Card, CardActionArea, Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card: {
        width: "100%",
        height: "100%",
        "& > button": {
            width: "100%",
            height: "100%",
        },
        borderRadius: 6,
    },
    content: {
        height: "100%",
        width: "100%",
        padding: `${theme.spacing(1)}px !important`,
    },
    title: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 500,
        color: "#54617A",
        textAlign: "center",
    },
    caption: {
        width: "100%",
        fontSize: theme.typography.pxToRem(10),
        color: "#b8bdcc",
        textAlign: "center",
        marginTop: "auto",
        marginBottom: 5,
        fontWeight: 600,
    },
}));

const TableCard = (props) => {
    const classes = useStyles();
    const { name, caption, onClick, backgroundColor, captionColor } = props;

    return (
        <Card className={classes.card} style={{ backgroundColor: backgroundColor }} elevation={1}>
            <CardActionArea onClick={onClick} disabled={onClick === undefined}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.content}
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6" color="textSecondary" className={classes.title}>
                            {name}
                        </Typography>
                    </Grid>
                    <Typography variant="caption" className={classes.caption} style={{ color: captionColor }}>
                        {caption}
                    </Typography>
                </Grid>
            </CardActionArea>
        </Card>
    );
};

export default TableCard;
