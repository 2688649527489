import React from "react";
import { Card, CardActions, makeStyles } from "@material-ui/core";

import LoadingButton from "../../../components/UI/Buttons/LoadingButton";
const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(3, 2, 0, 2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1, 2, 0, 2),
        },
    },
    actions: {
        justifyContent: "flex-end",
        padding: 0,
        margin: theme.spacing(4, 0, 2, 0),
    },
    form: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    button: {
        width: 150,
        borderRadius: 8,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        padding: theme.spacing(1.3, 0),
        margin: theme.spacing(0),
        [theme.breakpoints.down("xs")]: {
            width: "contain",
        },
    },
}));

const ActionCard = (props) => {
    const classes = useStyles();
    const { actions, ...otherProps } = props;

    return (
        <Card className={classes.card} elevation={3} {...otherProps}>
            {props.children}
            <CardActions className={classes.actions}>
                {actions?.map((action, index) => (
                    <LoadingButton key={index} {...action} disableElevation>
                        {action.label}
                    </LoadingButton>
                ))}
            </CardActions>
        </Card>
    );
};

export default ActionCard;
