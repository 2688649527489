import { LOGIN, LOGOUT, UPDATE_LICENSE, UPDATE_USER_INFORMATION } from "../actions/userActions";

const initialState = {
    id: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...action.value };
        case UPDATE_LICENSE:
            return { ...state, licenses: action.data };
        case UPDATE_USER_INFORMATION:
            return { ...state, ...action.data };
        case LOGOUT:
            return { id: null };
        default:
            return state;
    }
};

export default userReducer;
