import React from "react";
import { Card, CardActionArea, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        borderRadius: 4,
        boxShadow: "none",
        backgroundColor: "rgba(241, 242, 244, 1)",
        border: "2.5px solid rgba(84, 97, 122, 0.5)",
    },
    actionArea: {
        height: "100%",
        padding: theme.spacing(1.2, 3),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        color: "rgba(163, 169, 183, 1)",
    },
    text: {
        fontWeight: 600,
    },
}));

const ButtonCard = (props) => {
    const classes = useStyles();
    const { title, icon, onClick, ...otherProps } = props;

    return (
        <Card className={classes.card} {...otherProps}>
            <CardActionArea className={classes.actionArea} onClick={onClick}>
                <Typography variant="subtitle2" color="inherit" className={classes.text}>
                    {title}
                </Typography>
                {icon}
            </CardActionArea>
        </Card>
    );
};

export default ButtonCard;
