import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

//expert routes import
import ExpertDashboard from "../pages/Expert/Dashboard/Dashboard";
import ExpertsReportDetails from "../pages/Expert/Archive/ReportDetails";

//organization routes import
import OrganizationDashboard from "../pages/Organization/Dashboard/Dashboard";
import OrganizationInfo from "../pages/Organization/OrganizationInfo/OrganizationInfo.jsx";
import SystematicAssign from "../pages/Organization/Assign/SystematicAssign";

//people routes import
import PeopleDashboard from "../pages/People/Dashboard/Dashboard";
import PeopleCreateReport from "../pages/People/Reports/CreateReport";

//referee routes import
import RefereeDashboard from "../pages/Referee/Dashboard/Dashboard";
import FinancialReport from "../pages/Referee/FinancialReport/FinancialReport";
import GroupDetails from "../pages/Referee/Archive/GroupDetails";
import RefereeSearchExpert from "../pages/Referee/Search/SearchExpert/SearchExpert.jsx";
import SearchReport from "../pages/Referee/Search/Report/SearchReport";
import ExpertProfile from "../pages/Referee/Profile/ExpertProfile.jsx";
import Conversation from "../pages/Referee/Conversation/Conversation.jsx";

//admin routes import
import AdminDashboard from "../pages/Admin/Dashboard/Dashboard";
import AdminSearchReport from "../pages/Admin/Search/Report/SearchReport";
import ApproveLicenses from "../pages/Admin/ApproveLicenses/ApproveLicenses";

//Common
import AllReportDetails from "../pages/Admin/ReportDetails/AllReportDetails";
import CreateReport from "../pages/Referee/Reports/CreateReport";
import EditReport from "../pages/Referee/Reports/EditReport";
import MaintenancePage from "../components/hoc/MaintenancePage";
import FinancialInfo from "../pages/Referee/FinancialReport/FinancialInfo";

//Role Independent
import ReportFramework from "../pages/ReportFramework/ReportFramwork";

import locale from "../shared/locale";

// role routes
export const useRoutes = () => {
    const reports = useSelector((state) => state.reports.reports);
    const groups = useSelector((state) => state.groups.groups);
    const history = useHistory();
    return [
        //expert routes------------------------------
        {
            path: "/expert/report/:reportId/conversation",
            component: Conversation,
            name: () => "مکالمات",
            role: "Expert",
        },
        {
            path: "/expert/report/:reportId",
            component: ExpertsReportDetails,
            name: (props) => reportName(reports, props),
            role: "Expert",
            exact: true,
        },
        {
            path: "/expert",
            component: ExpertDashboard,
            name: () => "میزکار کارشناس",
            role: "Expert",
            exact: true,
        },

        //groupadmin routes------------------------
        {
            path: "/org/report/:reportId/conversation",
            component: Conversation,
            name: () => "مکالمات",
            role: "GroupAdmin",
        },
        {
            path: "/org/report/:reportId/searchexpert",
            component: RefereeSearchExpert,
            name: (props) => assigneeRole(history, props),
            role: "GroupAdmin",
            exact: true,
        },
        {
            path: "/org/report/:reportId/autoassign",
            component: SystematicAssign,
            name: () => "انتخاب سیستمی",
            role: "GroupAdmin",
            exact: true,
        },
        {
            path: "/org/report/:reportId/editreport",
            component: EditReport,
            name: () => "ویرایش گزارش",
            role: "GroupAdmin",
            exact: true,
        },
        {
            path: "/org/report/:reportId",
            component: AllReportDetails,
            name: (props) => reportName(reports, props),
            role: "GroupAdmin",
            exact: true,
        },
        {
            path: "/org/orginfo",
            component: OrganizationInfo,
            name: (props) => orgInfo(groups, props, history, "/org/orginfo"),
            role: "GroupAdmin",
            exact: true,
        },
        {
            path: "/org/createreport",
            component: CreateReport,
            name: () => "ایجاد گزارش",
            role: "GroupAdmin",
            exact: true,
        },
        {
            path: "/org",
            component: OrganizationDashboard,
            name: () => "میزکار مدیر سازمان",
            role: "GroupAdmin",
            exact: true,
        },
        //groupmember routes------------------------
        {
            path: "/org/report/:reportId/conversation",
            component: Conversation,
            name: () => "مکالمات",
            role: "GroupMember",
        },
        {
            path: "/org/report/:reportId/autoassign",
            component: SystematicAssign,
            name: () => "انتخاب سیستمی",
            role: "GroupMember",
            exact: true,
        },
        {
            path: "/org/report/:reportId",
            component: AllReportDetails,
            name: (props) => reportName(reports, props),
            role: "GroupMember",
            exact: true,
        },
        {
            path: "/org/createreport",
            component: CreateReport,
            name: () => "ایجاد گزارش",
            role: "GroupMember",
            exact: true,
        },
        {
            path: "/org",
            component: OrganizationDashboard,
            name: () => "میزکار سازمانی",
            role: "GroupMember",
            exact: true,
        },

        //people routes-------------------------------
        {
            path: "/user/report/:reportId/conversation",
            component: Conversation,
            name: () => "مکالمات",
            role: "People",
        },
        {
            path: "/user/report/:reportId",
            component: AllReportDetails,
            name: (props) => reportName(reports, props),
            role: "People",
            exact: true,
        },
        {
            path: "/user/createreport",
            component: () => <MaintenancePage message="این بخش به زودی در دسترس خواهد بود." />,
            name: () => "ایجاد گزارش",
            role: "People",
            exact: true,
        },
        {
            path: "/user",
            component: PeopleDashboard,
            name: () => "میزکار عمومی",
            role: "People",
            exact: true,
        },

        //referee routes-------------------------------
        {
            path: "/referee/group/:groupId/report/:reportId/conversation",
            component: Conversation,
            name: () => "مکالمات",
            role: "Referee",
        },
        {
            path: "/referee/group/:groupId/report/:reportId/editreport",
            component: EditReport,
            name: () => "ویرایش گزارش",
            role: "Referee",
            exact: true,
        },
        {
            path: "/referee/group/:groupId/report/:reportId/searchexpert",
            component: RefereeSearchExpert,
            name: (props) => assigneeRole(history, props),
            role: "Referee",
            exact: true,
        },
        {
            path: "/referee/group/:groupId/report/:reportId",
            component: AllReportDetails,
            name: (props) => reportName(reports, props),
            role: "Referee",
            exact: true,
        },
        {
            path: "/referee/group/:groupId",
            component: GroupDetails,
            name: (props) => groupName(groups, props),
            role: "Referee",
            exact: true,
        },
        {
            path: "/referee/expertprofile",
            component: ExpertProfile,
            name: () => "پروفایل کارشناس",
            role: "Referee",
            exact: true,
        },
        {
            path: "/referee/createreport",
            component: CreateReport,
            name: () => "ایجاد گزارش",
            role: "Referee",
            exact: true,
        },
        {
            path: "/referee/search",
            component: SearchReport,
            name: () => "جستجو‌ی گزارش",
            role: "Referee",
            exact: true,
        },
        {
            path: "/referee/financialReport",
            component: FinancialReport,
            name: () => "گزارش مالی",
            role: "Referee",
            exact: true,
        },
        {
            path: "/referee",
            component: RefereeDashboard,
            name: () => "میزکار ناظر",
            role: "Referee",
            exact: true,
        },
        //supervisor routes-------------------------------
        {
            path: "/supervisor/report/:reportId/conversation",
            component: Conversation,
            name: () => "مکالمات",
            role: "Supervisor",
        },
        {
            path: "/supervisor/report/:reportId",
            component: ExpertsReportDetails,
            name: (props) => reportName(reports, props),
            role: "Supervisor",
            exact: true,
        },
        {
            path: "/supervisor",
            component: ExpertDashboard,
            name: () => "میزکار ناظر",
            role: "Supervisor",
            exact: true,
        },
        //admin routes-------------------------------
        {
            path: "/admin/group/:groupId/report/:reportId/editreport",
            component: EditReport,
            name: () => "ویرایش گزارش",
            role: "SuperAdmin",
            exact: true,
        },
        {
            path: "/admin/group/:groupId/report/:reportId/searchexpert",
            component: RefereeSearchExpert,
            name: (props) => assigneeRole(history, props),
            role: "SuperAdmin",
            exact: true,
        },
        {
            path: "/admin/expertprofile",
            component: ExpertProfile,
            name: () => "پروفایل کارشناس",
            role: "SuperAdmin",
            exact: true,
        },
        {
            path: "/admin/group/:groupId/report/:reportId/conversation",
            component: Conversation,
            name: () => "مکالمات",
            role: "SuperAdmin",
        },
        {
            path: "/admin/group/:groupId/report/:reportId",
            component: AllReportDetails,
            name: (props) => reportName(reports, props),
            role: "SuperAdmin",
            exact: true,
        },
        {
            path: "/admin/group/:groupId",
            component: GroupDetails,
            name: (props) => groupName(groups, props),
            role: "SuperAdmin",
            exact: true,
        },
        {
            path: "/admin/search",
            component: AdminSearchReport,
            name: () => "جستجو‌ی گزارش",
            role: "SuperAdmin",
            exact: true,
        },
        {
            path: "/admin/approve-licenses",
            component: ApproveLicenses,
            name: () => "تایید پروانه‌های کارشناسی",
            role: "SuperAdmin",
            exact: true,
        },
        {
            path: "/admin/createreport",
            component: CreateReport,
            name: () => null,
            role: "SuperAdmin",
        },
        {
            path: "/admin/orginfo",
            component: OrganizationInfo,
            name: (props) => orgInfo(groups, props, history, "/admin/orginfo"),
            role: "SuperAdmin",
            exact: true,
        },
        {
            path: "/admin",
            component: AdminDashboard,
            name: () => "میزکار ادمین",
            role: "SuperAdmin",
            exact: true,
        },
        //Role Independent
        {
            path: "/reportframework",
            component: ReportFramework,
            name: () => "قالب گزارش",
            role: "all",
            exact: true,
        },
    ];
};

const reportName = (reports, props) => {
    let name = reports.filter((report) => report.id == props?.reportId)[0]?.name;

    return name;
};

const groupName = (groups, props) => {
    let name = groups.filter((group) => group.id == props?.groupId)[0]?.name;
    return name;
};

const assigneeRole = (history, props) => {
    const type = new URLSearchParams(history.location.search).get("type");
    return `جستجوی ${locale(type)}`;
};

const orgInfo = (groups, props, history, path) => {
    let groupId = new URLSearchParams(history.location.search).get("group_id");
    let groupTree = [];

    while (true) {
        const currentGroup = groups.find((item) => item.id == groupId);
        if (currentGroup === undefined) break;
        groupTree.push({ path: `${path}?group_id=${currentGroup?.id}`, name: () => "سازمان " + currentGroup?.name });
        if (currentGroup?.parent_id === null) break;
        groupId = currentGroup?.parent_id;
    }

    return groupTree.reverse();
};
