import React from "react";
import clsx from "clsx";
import moment_jalaali from "moment-jalaali";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, IconButton, CircularProgress } from "@material-ui/core";
import CropFreeIcon from "@material-ui/icons/CropFree";

import LicenseTable from "../../../components/Tables/LicenseTable/LicenseTable";
import DataTable from "../../../components/Tables/DataTable/DataTable";
import Styles from "../profileStyles";

import { inquireUser } from "../../../api/userAPI";
import { showDialog } from "../../../store/actions/dialogActions";
import { showAlert } from "../../../store/actions/snackbarActions";
import { updateLicense } from "../../../store/actions/userActions";

const LicensesTable = (props) => {
    const history = useHistory();
    const classes = Styles();
    const dispatch = useDispatch();
    const { licenses } = props;
    const [loading, setLoading] = React.useState(false);

    const reInquire = (licenseNumber) => {
        setLoading(true);
        inquireUser(licenseNumber).then((response) => {
            dispatch(showAlert(response.message, response.type));
            setLoading(false);
            if (response.type === "success") dispatch(updateLicense(response.data));
        });
    };

    const tableBody = licenses.map((license, index) => {
        return {
            data: {
                license_number: license.license_number,
                expertise: license.expertise,
                issued_date: license.issued_date ? moment_jalaali(license.issued_date).format("jYYYY/jM/jD") : "____",
                expiration_date: moment_jalaali(license.expiration_date).format("jYYYY/jM/jD"),
                action_1: (
                    <IconButton
                        size="small"
                        className={classes.recheckButton}
                        onClick={() => reInquire(license.license_number)}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : <CropFreeIcon />}
                    </IconButton>
                ),
                action_2: (
                    <Button
                        variant="contained"
                        disableElevation
                        size="small"
                        onClick={() =>
                            dispatch(
                                showDialog({
                                    type: "ACTION",
                                    content: (
                                        <div style={{ paddingBottom: 10 }}>
                                            {" "}
                                            <LicenseTable license={license} />
                                        </div>
                                    ),
                                })
                            )
                        }
                        className={classes.blueButton}
                    >
                        مشاهده
                    </Button>
                ),
            },
            config: {
                className: clsx(classes.tableRow, classes.licenseRow),
            },
        };
    });

    return (
        <Box className={classes.tableContainer}>
            <DataTable
                head={{
                    data: {
                        license_number: "شماره پروانه",
                        expertise: "رشته",
                        issued_date: "تاریخ صدور",
                        expiration_date: "تاریخ اعتبار",
                        action_1: "استعلام مجدد",
                        action_2: "جزئیات",
                    },
                    config: {
                        className: clsx(classes.tableHead, classes.licenseRow),
                    },
                }}
                body={tableBody}
            />
        </Box>
    );
};

export default LicensesTable;
