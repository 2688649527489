import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3, 2, 4, 4),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1, 2, 4, 2),
        },
    },
    noBottom: {
        padding: theme.spacing(3, 2, 0, 4),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0, 2, 0, 2),
        },
    },
}));

const MainContainer = (props) => {
    const classes = useStyles();
    const { noBottom, ...otherProps } = props;

    return (
        <div className={noBottom ? classes.noBottom : classes.root} {...otherProps}>
            {props.children}
        </div>
    );
};

export default MainContainer;
