import {
    SET_REPORTS,
    ADD_REPORT,
    SET_CATEGORIES,
    EDIT_REPORT,
    REMOVE_REPORT,
    TOGGLE_LOADING_REPORT,
} from "../actions/reportsActions";

const initialState = {
    reports: [],
    categories: [],
    loading: false,
};

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REPORTS:
            return { ...state, reports: action.value };
        case SET_CATEGORIES:
            return { ...state, categories: action.value };
        case ADD_REPORT:
            return { ...state, reports: [action.value, ...state.reports] };
        case EDIT_REPORT:
            return {
                ...state,
                reports: state.reports.map((report) => {
                    if (report.id === action.value.id) return action.value;
                    return report;
                }),
            };
        case REMOVE_REPORT:
            let newReports = [...state.reports];
            newReports = newReports.filter((item) => item.id != action.value);
            return { ...state, reports: [...newReports] };
        case TOGGLE_LOADING_REPORT:
            return { ...state, loading: action.value };
        default:
            return state;
    }
};

export default reportsReducer;
