import React from "react";
import clsx from "clsx";
import { Grid, IconButton, Typography, makeStyles } from "@material-ui/core";

const Styles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        margin: 20,
    },
    perPageItems: {
        marginLeft: 5,
    },
    perPageItem: {
        fontFamily: "YekanBakh",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        padding: theme.spacing(0.5, 0.8),
        borderRadius: 5,
        marginLeft: 10,
    },
    title: {
        color: "rgba(84, 97, 122, 1)",
        fontWeight: 600,
    },
    active: {
        backgroundColor: "rgba(196, 196, 196, 0.3)",
    },
}));

const TablePerPage = (props) => {
    const classes = Styles();
    const { limit, changeLimit } = props;

    const values = [10, 20, 30];

    return (
        <Grid container>
            <Grid item xs={12} className={classes.root}>
                <Typography variant="subtitle2" className={classes.title}>
                    تعداد سطر های هر صفحه:
                </Typography>
                <div className={classes.perPageItems}>
                    {values.map((value, index) => (
                        <IconButton
                            className={
                                limit === value ? clsx(classes.perPageItem, classes.active) : classes.perPageItem
                            }
                            key={index}
                            onClick={(e) => changeLimit(value)}
                        >
                            {value}
                        </IconButton>
                    ))}
                </div>
            </Grid>
        </Grid>
    );
};

export default TablePerPage;
