import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paper, CircularProgress } from "@material-ui/core";
import MainContainer from "../../../components/UI/Containers/MainContainer";
import ConversatinHeader from "../../../components/Conversation/Header/Header";
import ConversationBody from "../../../components/Conversation/ConversationBody";
import AddMessage from "../../../components/Conversation/AddMessage/AddMessage";

import { fetchReports } from "../../../store/actions/reportsActions";
import { fetchGroups } from "../../../store/actions/groupsActions";
import { fetchMessages } from "../../../api/reportsAPI";
import { getAssignees } from "../../../api/assigneesAPI";

const Conversation = () => {
    const dispatch = useDispatch();
    const { reportId } = useParams();
    const { reports } = useSelector((store) => store.reports);
    const report = reports.find((item) => item.id == reportId);
    const [messages, setMessages] = React.useState([]);
    const [assignees, setAssignees] = React.useState([]);

    React.useEffect(() => {
        dispatch(fetchReports());
        dispatch(fetchGroups());
        fetchMessages(reportId).then((response) => {
            setMessages(response.data);
        });
        getAssignees(reportId).then((response) => {
            setAssignees(response.reverse());
        });
    }, []);

    const addMessage = (message) => {
        setMessages((state) => [...state, message]);
    };

    return (
        <MainContainer>
            <div
                style={{
                    height: "calc(100vh - 120px)",
                    position: "relative",
                    margin: "15px 15px -5px 15px",
                }}
            >
                {assignees.length > 0 && <ConversatinHeader assignees={assignees} />}
                <div style={{ height: 40, backgroundColor: "rgba(61, 87, 136, 0.2)", width: "100%" }}></div>
                <ConversationBody messages={messages} />
                <AddMessage addMessage={addMessage} />
            </div>
        </MainContainer>
    );
};

export default Conversation;
