import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment } from "@material-ui/core";

import currencySeparator, { currencyFixer } from "../../../shared/currencySeprator";
import { toEnNumber } from "../../../shared/toEnNumber";
const useStyles = makeStyles((theme) => ({}));

const PriceField = (props) => {
    const classes = useStyles();
    const { label, value, onChange, InputProps, currency } = props;

    return (
        <TextField
            {...props}
            InputProps={{
                value: currencySeparator(value ?? ""),
                endAdornment: <InputAdornment position="start">{currency ? currency : "تومان"}</InputAdornment>,
                onChange: (event) => onChange(currencyFixer(toEnNumber(event.target.value))),
                type: "tel",
                ...InputProps,
            }}
        />
    );
};

export default PriceField;
