import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import styles from "./style";

const IntroDescription = ({ description }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const classes = styles();
    return (
        <>
            <Grid item md={12} xs={12}>
                {!isMobile && (
                    <div className={classes.titleContainer}>
                        <Typography component="h2" className={classes.titleTwo}>
                            کارشناس رسمی
                        </Typography>
                    </div>
                )}
                <Typography
                    component="p"
                    style={{
                        fontFamily: "IRANSansBold",
                        fontSize: 12,
                        textAlign: "center",
                    }}
                >
                    {description}
                </Typography>
            </Grid>
        </>
    );
};

export default IntroDescription;
