import React from "react";
import { makeStyles, Button, InputBase, Paper } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        width: "60%",
        borderRadius: 8,
        boxShadow: "0px 0px 20px rgba(215, 222, 227, 0.4)",
        height: 45,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    inputBase: {
        flex: 1,
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(16),
    },
    input: {
        fontSize: theme.typography.pxToRem(14),
    },
    icon: {
        display: "flex",
        padding: theme.spacing(0, 0.5, 0, 1),
        color: "#A3A9B7",
        "& svg": {
            fontSize: 28,
        },
    },
}));

const SearchBar = (props) => {
    const classes = useStyles();
    const { onSubmit, onChange, value, ...otherProps } = props;

    return (
        <Paper
            component="form"
            onSubmit={(event) => {
                event.preventDefault();
                onSubmit();
            }}
            className={classes.root}
        >
            <div className={classes.icon}>
                <SearchIcon />
            </div>
            <InputBase
                className={classes.inputBase}
                {...otherProps}
                value={value}
                onChange={onChange}
                inputProps={{ className: classes.input }}
            />
        </Paper>
    );
};
export default SearchBar;
