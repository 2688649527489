import React from "react";
import { Card, Typography, makeStyles, IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import GetAppIcon from "@material-ui/icons/GetAppRounded";

import FileIcon from "../Icons/FileIcon";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: 4,
        boxShadow: "none",
        position: "relative",
        minHeight: 60,
        cursor: "pointer",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover !important",
    },
    caption: {
        color: "#fff",
        direction: "rtl",
        whiteSpace: "nowrap",
        fontSize: theme.typography.pxToRem(11),
        marginTop: 10,
        width: "70%",
        "&:hover": {
            animation: `$floatText 3s infinite linear`,
        },
    },
    icon: {
        position: "absolute",
        color: "#fff",
        right: 0,
        zIndex: 20,
        "& svg": {
            fontSize: 17,
        },
    },
    typeContainer: {
        position: "absolute",
        color: "#fff",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: theme.spacing(1.1),
        "& svg": {
            fontSize: 26,
        },
        "& span": {
            marginTop: -16,
            fontSize: theme.typography.pxToRem(7),
        },
    },
    "@keyframes floatText": {
        "0%": {
            textIndent: "100%",
        },

        "100%": {
            textIndent: "-100%",
        },
    },
}));

const FilePreview = (props) => {
    const classes = useStyles();
    const { src, label, type, onClick, height, icon } = props;

    let pattern = /\.\w+$/g.exec(label),
        fileName,
        fileType;
    if (!pattern) {
        pattern = /\.\w+$/g.exec(src);
        fileType = pattern[0].slice(1).toUpperCase();
        fileName = label;
    } else {
        fileType = pattern[0].slice(1).toUpperCase();
        fileName = pattern.input.slice(0, pattern.index);
    }

    let backgroundColor = "rgba(37, 137, 255, 0.7)";
    switch (fileType) {
        case "XLSX":
            backgroundColor = "rgba(22, 153, 59, 0.7)";
            break;
        case "DOCX":
            backgroundColor = "rgba(37, 137, 255, 0.7)";
            break;
        case "RAR":
        case "ZIP":
            backgroundColor = "rgba(255, 37, 37, 0.7)";
            break;
        case "PDF":
            backgroundColor = "rgba(255, 116, 37, 0.7)";
            break;
        default:
            backgroundColor = "rgba(10, 10, 10, 0.7)";
            break;
    }

    let cardStyle = {};
    let typeStyle = {};
    if (type.includes("image")) {
        cardStyle.background = `url(${src})`;
        typeStyle.backgroundColor = "rgba(10, 10, 10, 0.7)";
    } else {
        cardStyle.backgroundColor = backgroundColor;
    }

    return (
        <Card className={classes.container} onClick={onClick} style={cardStyle}>
            <IconButton className={classes.icon} size="small">
                {icon === "download" ? <GetAppIcon /> : <HighlightOffIcon />}
            </IconButton>
            <div className={classes.typeContainer} style={typeStyle}>
                <FileIcon color="#fff" />
                <Typography variant="caption">{fileType}</Typography>
                <Typography variant="body2" noWrap className={classes.caption}>
                    {fileName}
                </Typography>
            </div>
        </Card>
    );
};

export default FilePreview;
