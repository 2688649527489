import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import PostAddIcon from "@material-ui/icons/PostAddOutlined";
import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";
import BusinessIcon from "@material-ui/icons/Business";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ClassIcon from "@material-ui/icons/Class";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";

const nav = [
    {
        label: "عمومی",
        icon: PersonIcon,
        key: "people",
        accessibleRoles: "all",
        roleIndependent: true,
    },
    {
        label: "تخصصی",
        icon: WorkIcon,
        key: "expert",
        accessibleRoles: "all",
        roleIndependent: true,
    },
    {
        label: "سازمانی",
        icon: BusinessIcon,
        key: "organization",
        accessibleRoles: "all",
        roleIndependent: true,
    },
    {
        label: "افزودن حساب کاربری",
        icon: AddCircleIcon,
        key: "addaccount",
        accessibleRoles: "all",
        roleIndependent: true,
    },
    {
        label: "میز کار",
        key: "dashboard",
        icon: DashboardOutlinedIcon,
        link: "/dashboard",
        accessibleRoles: "all",
    },
    {
        label: "افزودن گزارش",
        icon: PostAddIcon,
        key: "createReport",
        link: "/createreport",
        accessibleRoles: ["Referee", "GroupAdmin", "GroupMember", "SuperAdmin"],
    },
    {
        label: "جستجوی گزارش",
        icon: SearchOutlinedIcon,
        key: "search",
        link: "/search",
        accessibleRoles: ["Referee", "Admin", "SuperAdmin"],
    },
    {
        label: "گزارش مالی",
        icon: LocalAtmIcon,
        key: "financialReport",
        link: "financialReport",
        accessibleRoles: ["Referee"],
    },
    {
        label: "محاسبه دستمزد",
        icon: MonetizationOnIcon,
        key: "wagecalculator",
        link: "wagecalculator",
        accessibleRoles: "all",
        roleIndependent: true,
    },
    {
        label: "اطلاعات سازمان",
        icon: AccountBalanceIcon,
        key: "orginfo",
        link: "/orginfo",
        accessibleRoles: ["SuperAdmin", "GroupAdmin"],
    },
    {
        label: "تایید پروانه",
        icon: PlaylistAddCheckIcon,
        key: "approve",
        link: "/approve-licenses",
        accessibleRoles: ["SuperAdmin"],
    },
    {
        label: "قالب گزارش",
        icon: ClassIcon,
        key: "framework",
        link: "reportframework",
        accessibleRoles: "all",
        roleIndependent: true,
    },
];

export default nav;
