import { makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
    card: {
        width: "100%",
        height: "100%",
        borderRadius: 12,
        boxShadow: "0 0 20px 0 #d7dee366",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(2),
        },
        "& > button": {
            height: "100%",
        },
    },
    cardHeader: {
        display: "flex",
        height: 50,
        width: "100%",
        "& > div:first-child": {
            width: "70%",
            backgroundColor: "rgba(249, 250, 252, 1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            color: "#54617A",
            "& p": {
                fontWeight: 600,
                fontSize: theme.typography.pxToRem(15),
            },
        },
        "& > div:last-child": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "30%",
        },
    },
    content: { height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" },
    type: { fontSize: "0.8rem", fontWeight: 500, color: "#5c5f66" },
    title: {
        [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(2),
        },
        fontSize: "1.1rem",
        fontWeight: 500,
        color: "#1d79be",
    },
    description: { fontSize: "0.9rem" },
    caption: {
        width: "100%",
        fontSize: "0.8rem",
        color: "rgba(0,0,0,0.8)",
        fontWeight: 700,
        textAlign: "center",
    },
}));
