import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import ActionDialog from "./ActionDialog";
import FilePreviewDialog from "./FilePreviewDialog";
import SubmitDialog from "./SubmitDialog";

import { dialogTypes } from "./dialogTypes";
import MapContainer from "../Map/MapContainer";
import Mapir from "mapir-react-component";

const MasterDialog = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const dialog = useSelector((state) => state.dialog);
    const dispatch = useDispatch();

    switch (dialog.data?.type) {
        case dialogTypes.FILE_PREVIEW:
            return (
                <FilePreviewDialog
                    {...dialog.data}
                    title={dialog.data?.title}
                    open={dialog.show}
                    onClose={() => dispatch({ type: "CLOSE_DIALOG" })}
                    fullScreen={isMobile && dialog.data?.fullScreen}
                    files={dialog.data?.files}
                >
                    {dialog.data?.content}
                </FilePreviewDialog>
            );
        case dialogTypes.SUBMIT:
            return (
                <SubmitDialog
                    title={dialog.data?.title}
                    open={dialog.show}
                    {...dialog.data}
                    onClose={() => dispatch({ type: "CLOSE_DIALOG" })}
                >
                    {dialog.data?.content}
                </SubmitDialog>
            );
        case dialogTypes.MAP_MARKER:
            return (
                <Dialog
                    open={dialog.show}
                    {...dialog.data.dialogProps}
                    onClose={() => dispatch({ type: "CLOSE_DIALOG" })}
                >
                    <MapContainer {...dialog.data.mapProps} center={dialog.data.coords}>
                        <Mapir.Marker coordinates={dialog.data.coords} anchor="bottom" />
                    </MapContainer>
                </Dialog>
            );
        case dialogTypes.ACTION:
            return (
                <ActionDialog open={dialog.show} {...dialog.data} onClose={() => dispatch({ type: "CLOSE_DIALOG" })}>
                    {dialog.data?.content}
                </ActionDialog>
            );
        default:
            return (
                <Dialog
                    open={dialog.show}
                    maxWidth="md"
                    {...dialog.data}
                    fullScreen={isMobile && dialog.data?.fullScreen}
                    onClose={() => dispatch({ type: "CLOSE_DIALOG" })}
                >
                    {dialog.data?.title && <DialogTitle>{dialog.data?.title}</DialogTitle>}
                    {dialog.data?.content}
                </Dialog>
            );
    }
};

export default MasterDialog;
