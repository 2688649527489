import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Grid, TextField, InputAdornment } from "@material-ui/core";

import SearchListInput from "../components/UI/Input/SearchListInput";
import SelectInput from "../components/UI/Input/SelectInput";
import MultipleSelect from "../components/UI/Input/MultipleSelect";
import DatePicker from "../components/UI/Input/DatePicker";
import FileUploader from "../components/UI/Input/FileUploader";
import SingleFileUploader from "../components/UI/Input/SingleFileUploader";

import ProvinceFields from "../components/UI/Fields/ProvinceFields";
import StoreField from "../components/UI/Fields/StoreField.jsx";
import PriceField from "../components/UI/Fields/PriceField";

import { toEnNumber } from "./toEnNumber";

const inputCreator = (type, value, onChange, props) => {
    switch (type) {
        case "store-field":
            return <StoreField fullWidth value={value} onChange={(value) => onChange(value)} {...props} />;
        case "text-field":
            return (
                <TextField
                    fullWidth
                    value={value}
                    onChange={(event) => onChange(toEnNumber(event.target.value))}
                    {...props}
                />
            );
        case "price-field":
            return <PriceField fullWidth value={value} onChange={(val) => onChange(val)} {...props} />;
        case "count-text-field":
            const maxCount = props.maxCount ?? 255;
            return (
                <TextField
                    fullWidth
                    value={value}
                    style={{ marginBottom: -20 }}
                    onChange={(event) => {
                        if (event.target.value.length <= maxCount) onChange(toEnNumber(event.target.value));
                    }}
                    helperText={value.length + ` از ${maxCount} کاراکتر`}
                    FormHelperTextProps={{
                        style: { fontSize: 10, textAlign: "left", color: "#b8bdcc", lineHeight: 3 },
                    }}
                    {...props}
                />
            );
        case "select-input":
            return (
                <SelectInput fullWidth value={value} onChange={(event) => onChange(event.target.value)} {...props} />
            );
        case "multiple-select":
            return <MultipleSelect fullWidth value={value} onChange={(val) => onChange(val)} {...props} />;
        case "search-list-input":
            return <SearchListInput fullWidth value={value} onChange={(value) => onChange(value)} {...props} />;
        case "date-picker":
            return <DatePicker fullWidth value={value} onChange={(date) => onChange(date)} {...props} />;
        case "file-uploader":
            let newFiles = [...value];

            newFiles = value.map((file) => {
                if (file.name === undefined) return {};
                if (file?.link !== undefined) return { ...file, id: uuidv4() };
                return {
                    link: URL.createObjectURL(file),
                    name: file.name,
                    mime_type: file.type,
                    id: uuidv4(),
                };
            });

            return (
                <FileUploader
                    label={props.label}
                    helperText="حداکثر حجم فایل 5 مگابایت"
                    maxFilesCount={10}
                    uploadedFiles={newFiles.filter((file) => Object.keys(file).length !== 0)}
                    onChange={(event) => onChange([...value, ...event.target.files])}
                    onRemove={(index) => onChange([...value.slice(0, index), ...value.slice(index + 1, value.length)])}
                />
            );
        case "single-file-uploader":
            let newFile = value;
            if (value && value?.link === undefined)
                newFile = {
                    link: URL.createObjectURL(value),
                    name: value.name,
                    mime_type: value.type,
                };
            return (
                <SingleFileUploader
                    label={props.label}
                    helperText="حداکثر حجم فایل 5 مگابایت"
                    uploadedFile={newFile}
                    onChange={(event) => onChange(event.target.files[0])}
                    onRemove={(index) => onChange(null)}
                />
            );
        default:
            return;
    }
};

const formCreator = (controls, inputChangedHandler) => {
    let formElements = [];
    for (let key in controls) {
        formElements.push({
            id: key,
            config: controls[key],
        });
    }
    formElements = formElements.map((formElement) => {
        if (formElement.config.hide) return "";

        if (formElement.config.elementType === "province-fields") {
            return (
                <ProvinceFields
                    {...formElement.config.elementConfig}
                    values={formElement.config.value}
                    provinceFieldName="province_id"
                    townFieldName="town_id"
                    cityFieldName="city_id"
                    onChange={(value) => {
                        inputChangedHandler(value.province_id, "province_id");
                        inputChangedHandler(value.town_id, "town_id");
                        inputChangedHandler(value.city_id, "city_id");
                    }}
                    mapper={(value) => value?.id}
                />
            );
        }
        return (
            <Grid item xs={12} key={formElement.id} {...formElement.config.gridConfig}>
                {inputCreator(
                    formElement.config.elementType,
                    formElement.config.value,
                    (value) => inputChangedHandler(value, formElement.id),
                    { ...formElement.config.elementConfig, id: formElement.id }
                )}
            </Grid>
        );
    });

    return formElements;
};

export { inputCreator };
export default formCreator;
