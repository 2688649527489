import React from "react";
import axios from "../../../api/instance";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import GetAppIcon from "@material-ui/icons/GetApp";
import ChatIcon from "@material-ui/icons/Chat";
import DoneAllIcon from "@material-ui/icons/DoneAll";

import VerticalStepper from "../../../components/Stepper/VerticalStepper/VerticalStepper";
import HeadCard from "../../../components/Surfaces/Cards/HeadCard/HeadCard";
import ConfirmReport from "./Components/ConfirmReport";
import StepSetWageEstimation from "../../Supervisor/Archive/Steps/SetWageEstimation";
import StepConfirmReport from "../../Supervisor/Archive/Steps/ConfirmReport";
import ConfirmDialog from "../../Supervisor/Archive/ConfirmDialog";
import EstimationDialog from "../../Admin/ReportDetails/Components/EstimationDialog";
import Styles from "../../Admin/ReportDetails/styleReportDetails";

import { showDialog } from "../../../store/actions/dialogActions";
import { dialogTypes } from "../../../components/Dialogs/dialogTypes";
import { roles, assigneeStatus } from "../../../shared/checkStatus";
import { checkStatus, statusBackColors, statusColors } from "../../../shared/checkStatus";

const ReportHeader = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = Styles();
    const { report, assignees, assignee, changeAssigneeData } = props;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [wageForm, setWageForm] = React.useState(null);

    let headerOptions = [];
    if (assignees && report)
        headerOptions = [
            {
                label: (
                    <>
                        <InsertDriveFileIcon />
                        مشاهده اسناد
                    </>
                ),
                badge: report?.documents.length,
                disabled: report?.documents.length === 0 || assignee.status === "REJECTED",
                onClick: () =>
                    dispatch(
                        showDialog({
                            title: "اسناد گزارش",
                            files: report?.documents,
                            type: dialogTypes.FILE_PREVIEW,
                            maxWidth: "md",
                        })
                    ),
            },
            {
                component: "a",
                label: (
                    <>
                        <GetAppIcon />
                        دریافت فایل ابلاغ
                    </>
                ),
                disabled: report?.task_count === 0 || assignee.status === "REJECTED",
                href: axios.defaults.baseURL + "/reports/task?report_id=" + report.id,
                target: "_blank",
            },
            {
                condition: assignee.status !== assigneeStatus.REJECTED,
                content: {
                    label: (
                        <>
                            <ChatIcon />
                            مکالمات
                        </>
                    ),
                    onClick: () => history.push(`/expert/report/${report.id}/conversation`),
                },
            },
            {
                role: [roles.Expert, roles.Head],
                condition: assignee.status === assigneeStatus.CONFIRMING,
                content: {
                    label: (
                        <>
                            <AssignmentTurnedInIcon />
                            تایید پاسخ
                        </>
                    ),
                    onClick: () =>
                        dispatch(
                            showDialog({
                                title: "اسناد پاسخ گزارش",
                                files: assignees.find((item) => item?.response)?.response?.documents,
                                type: dialogTypes.FILE_PREVIEW,
                                maxWidth: "sm",
                                fullScreen: true,
                                content: (
                                    <ConfirmReport
                                        responseId={assignees.find((item) => item.response)?.response?.id}
                                        changeAssigneeData={changeAssigneeData}
                                    />
                                ),
                            })
                        ),
                },
            },
            {
                role: [roles.Supervisor],
                condition: assignee.status === "CONFIRMING",
                content: {
                    label: (
                        <>
                            <AssignmentTurnedInIcon />
                            تایید پاسخ
                        </>
                    ),
                    onClick: () => setOpenDialog(true),
                },
            },
            {
                label: (
                    <>
                        <DoneAllIcon />
                        مشاهده پاسخ
                    </>
                ),
                hide: report.status !== "COMPLETED",
                className: classes.greenButton,
                onClick: () =>
                    dispatch(
                        showDialog({
                            type: dialogTypes.ACTION,
                            content: <EstimationDialog assignees={assignees} />,
                        })
                    ),
            },
        ];

    return (
        <>
            {assignees.length > 0 && (
                <ConfirmDialog open={openDialog} onClose={() => setOpenDialog(false)} title="تایید پاسخ دبیر">
                    <VerticalStepper
                        steps={[
                            {
                                title: "اطلاعات پاسخ",
                                content: (
                                    <StepSetWageEstimation
                                        assignees={assignees}
                                        documents={assignees.find((item) => item.response)?.response.documents}
                                        setWageForm={setWageForm}
                                    />
                                ),
                                action: { next: { disabled: !wageForm } },
                            },
                            {
                                title: "احراز هویت",
                                content: (
                                    <StepConfirmReport
                                        responseId={assignees?.find((item) => item.response?.id)?.response.id}
                                        wageForm={wageForm}
                                        report={report}
                                        changeAssigneeData={changeAssigneeData}
                                        closeDialog={() => setOpenDialog(false)}
                                    />
                                ),
                            },
                        ]}
                    />
                </ConfirmDialog>
            )}
            <HeadCard
                title={report.name}
                subTitle={"مهلت گزارش : " + report.due_date_jalaali}
                chip={report.chip}
                options={checkStatus(headerOptions, report.status, assignee.assignee_role_id)}
                caption={<span style={{ color: statusColors[report.status] }}>{report.caption}</span>}
                captionProps={{ style: { backgroundColor: statusBackColors[report?.status] } }}
            >
                {props.children}
            </HeadCard>
        </>
    );
};

export default ReportHeader;
