import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import currencySeperator from "../../../shared/currencySeprator";

const useStyles = makeStyles((theme) => ({}));

const PriceTypo = (props) => {
    const classes = useStyles();
    const { currency } = props;

    return (
        <Typography {...props}>
            {isNaN(props.children) ? props.children : currencySeperator(props.children) + (currency ?? " تومان ")}
        </Typography>
    );
};

export default PriceTypo;
