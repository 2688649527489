import React from "react";
import { RadioGroup, FormControlLabel, Radio, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    label: {
        color: "#8a8e99",
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 500,
    },
    control: {
        textAlign: "left",
        borderLeft: "1px solid #dadde6",
        paddingLeft: theme.spacing(2),
        "& > span:nth-child(2)": {
            marginRight: "auto",
        },
    },
    inputGroup: {
        width: "100%",
    },
}));

const CustomRadioGroup = (props) => {
    const classes = useStyles();
    const { options, value, onChange } = props;

    return (
        <RadioGroup column="true" value={value} className={classes.inputGroup} onChange={onChange}>
            {options.map((option, index) => (
                <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio disableRipple size="small" color="primary" />}
                    label={<span className={classes.label}>{option.name}</span>}
                    labelPlacement="start"
                    className={classes.control}
                />
            ))}
        </RadioGroup>
    );
};

export default CustomRadioGroup;
