import React from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    loading: {
        height: 285,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }
})

const LinearLoading = ({width, title}) => {
    const styles = useStyles();
    return(
        <div className={styles.loading}>
            <span>{title}</span>
            <LinearProgress style={{width, marginTop: 10}}/>
        </div>
    )
}

export default LinearLoading;