import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    myMessageContainer: {
        direction: "ltr",
        display: "flex",
        position: "relative",
        width: "100%",
        margin: "0 7px",
        "& .babelMessage": {
            top: 25,
            position: "absolute",
            left: -7,
            transform: "rotateY(180deg) rotateZ(16deg) rotateX(180deg)",
        },
    },
    anotherMessageContainer: {
        direction: "rtl",
        display: "flex",
        position: "relative",
        width: "100%",
        margin: "0 7px",
        "& .babelMessage": {
            top: 25,
            position: "absolute",
            right: -7,
            transform: "rotateY(0deg) rotateZ(16deg) rotateX(180deg)",
        },
    },
    message: {
        maxWidth: "45%",
        padding: 15,
        borderRadius: 6,
        margin: "20px 0",
        direction: "rtl",
        "& .mobileMessage": {
            maxWidth: "80%",
        },
        "&.myMessage": {
            backgroundColor: "#D6F1FF",
            "& .messageDate": {
                position: "absolute",
                left: 7,
                bottom: -1,
                fontSize: theme.typography.pxToRem(10),
                color: "#717070",
            },
            "& span": {
                left: 0,
            },
        },
        "&.anotherMessage": {
            backgroundColor: "#D6FFDD",
            textAlign: "right",
            "& .messageDate": {
                position: "absolute",
                right: 7,
                bottom: -1,
                fontSize: theme.typography.pxToRem(10),
                color: "#717070",
            },
            "& span": {
                right: 0,
            },
        },
        "& span": {
            fontSize: 10,
            position: "absolute",
            top: 0,
        },
        "& p ": {
            textAlign: "left",
        },
    },
    chipStyle: {
        fontSize: 7,
        position: "absolute",
        bottom: 0,
        height: "auto",
        padding: "0 5px 5px",
    },
    avatarContainer: {
        display: "flex",
        overflow: "hidden",
        position: "relative",
        borderRadius: "50%",
        width: 60,
        height: 60,
        justifyContent: "center",
        alignItems: "center",
        top: 13,
        "& > div": {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
    },
}));

export default useStyles;
