import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import { showAlert } from "../../../../store/actions/snackbarActions";
import { revokeEmployee } from "../../../../api/groupsAPI";
import formCreator from "../../../../shared/formCreator";
import addEmployeeInputs from "./addEmployeeInputs";

const roles = [
    { label: "کارمند", value: "GROUPMEMBER" },
    { label: "مدیر", value: "GROUPADMIN" },
    { label: "ارجاع دهنده", value: "REFEREE" },
    { label: "هیئت نظارت", value: "SUPERVISOR" },
    { label: "سوپر ادمین", value: "SUPERADMIN" },
];

const ChangeEmployeeRole = (props) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user);
    const { isBusiness, memberId, memberRole, changeRole } = props;

    const [formData, setFormData] = React.useState({
        values: { role_id: memberRole },
        options: {
            role_id: isBusiness
                ? user.active_role_id === "SUPERADMIN"
                    ? roles.slice(0, 2)
                    : roles.slice(0, 1)
                : user.active_role_id === "SUPERADMIN"
                ? roles
                : roles.slice(0, 4),
        },
    });

    const inputChangedHandler = (value, inputIdentifier) => {
        let updatedForm = { ...formData };
        updatedForm.values[inputIdentifier] = value;
        setFormData(updatedForm);
    };

    const submitRevokeEmployee = () => {
        revokeEmployee({ group_member_id: memberId, ...formData.values }).then((response) => {
            if (response.type === "success") {
                dispatch({ type: "CLOSE_DIALOG" });
                dispatch(showAlert(response.message, "success"));
                changeRole(response.data, memberId);
            } else {
                dispatch({ type: "CLOSE_DIALOG" });
                dispatch(showAlert(response?.message, "error"));
            }
        });
    };

    let formElements = formCreator({ role_id: addEmployeeInputs(formData)["role_id"] }, inputChangedHandler);

    return (
        <>
            <DialogContent>
                <Grid container spacing={1}>
                    {formElements}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch({ type: "CLOSE_DIALOG" })} color="secondary">
                    لغو
                </Button>
                <Button onClick={submitRevokeEmployee} color="primary" variant="contained" autoFocus>
                    ثبت
                </Button>
            </DialogActions>
        </>
    );
};

export default ChangeEmployeeRole;
