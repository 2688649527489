import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography, Grid, IconButton, Avatar } from "@material-ui/core";
import { Instagram, LinkedIn, Telegram, HelpOutlineOutlined } from "@material-ui/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowForwardIos";
import NotificationsIcon from "@material-ui/icons/Notifications";

import Styles from "./styleSidebar";
import SideBarMenu from "../../Surfaces/SideBarMenu/SideBarMenu";
import ProfileAvatar from "../../UI/Image/ProfileAvatar";
import LogoIcon from "../../UI/Icons/LogoIcon";
import NotificationDrawer from "../../Static/Drawer/NotificationDrawer";

import { toggleNotifications } from "../../../store/actions/notificationActions";
import nav from "../../../routes/nav";
import logoBlack from "../../../assets/images/logoblack.png";

const SideBar = (props) => {
    const classes = Styles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { open, user, onClick } = props;
    const anchorEl = React.useRef(null);

    return (
        <React.Fragment>
            <NotificationDrawer anchor={anchorEl} />
            <div className={classes.sideBar} style={{ width: open ? 250 : 140 }} ref={anchorEl}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="flex-start"
                    className={classes.root}
                    style={{ height: "100%", width: open ? "100%" : 100 }}
                    wrap="nowrap"
                >
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item>
                            <div className={classes.avatar}>
                                <IconButton
                                    className={classes.notification}
                                    size="small"
                                    onClick={() => dispatch(toggleNotifications())}
                                >
                                    <NotificationsIcon />
                                </IconButton>
                                <ProfileAvatar height={60} src={user.avatar_link} />
                            </div>
                        </Grid>
                        {open && (
                            <Grid item xs={7}>
                                <Typography variant="body1" className={classes.nameTypo}>
                                    {user.name + " " + user.surname}
                                </Typography>
                                <Typography variant="body2" className={classes.roleTypo}>
                                    {user.active_role !== "People" && user.active_role_slug}
                                </Typography>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    size="small"
                                    className={classes.profileButton}
                                    onClick={() => history.push("/profile")}
                                >
                                    مشاهده اطلاعات کاربری
                                </Button>
                            </Grid>
                        )}
                    </Grid>

                    <SideBarMenu open={open} nav={nav} anchor={anchorEl} />
                    {open && (
                        <Grid item style={{ display: "flex" }}>
                            <LogoIcon src={logoBlack} className={classes.logo} fill={"#A4A6B3"} />
                        </Grid>
                    )}
                    <Grid
                        container
                        spacing={1}
                        direction={open ? "row" : "column"}
                        justify="center"
                        alignItems="center"
                        className={classes.contactContainer}
                    >
                        <Grid item>
                            <IconButton
                                className={classes.contactIcon}
                                component="a"
                                href="https://support.edalatkhane.org/"
                                target="_blank"
                            >
                                <HelpOutlineOutlined />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                className={classes.contactIcon}
                                component="a"
                                href="https://instagram.com/edalatkhane_org?igshid=1mavs6to2471f"
                                target="_blank"
                            >
                                <Instagram />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                className={classes.contactIcon}
                                component="a"
                                href="https://www.linkedin.com/company/edalatkhane"
                                target="_blank"
                            >
                                <LinkedIn />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                className={classes.contactIcon}
                                component="a"
                                href="https://t.me/EdalatKhane_org"
                                target="_blank"
                            >
                                <Telegram />
                            </IconButton>
                        </Grid>
                        {open && (
                            <Typography variant="caption" className={classes.copyright}>
                                کلیه حقوق این سایت برای عدالتخانه محفوظ میباشد
                            </Typography>
                        )}
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    className={open ? classes.toggleButton : clsx(classes.toggleButton, classes.toggleButtonClose)}
                    onClick={() => {
                        onClick();
                        dispatch(toggleNotifications(false));
                    }}
                >
                    <ArrowBackIosIcon className={!open && classes.toggleIconClose} />
                </Button>
            </div>
        </React.Fragment>
    );
};

export default SideBar;
