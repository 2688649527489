import axios from "../../api/instance";
import moment_jalaali from "moment-jalaali";
import moment from "moment";
import { showAlert } from "./snackbarActions";
import locale from "../../shared/locale";

export const SET_REPORTS = "SET_REPORTS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const ADD_REPORT = "ADD_REPORT";
export const EDIT_REPORT = "EDIT_REPORT";
export const REMOVE_REPORT = "REMOVE_REPORT";
export const TOGGLE_LOADING_REPORT = "TOGGLE_LOADING_REPORT";

export const fixReport = (report) => {
    return {
        ...report,
        chip: "EDL_" + report.id,
        caption: locale(report.status),
        due_date_jalaali: moment_jalaali(report.due_date).format("jYYYY/jM/jD"),
        due_date: moment(report.due_date),
        created_at: moment_jalaali(report.created_at).format("jYYYY/jM/jD"),
        category: report.category !== null && report.category !== undefined ? report.category : "(بدون دسته‌بندی)",
    };
};

const fixCategory = (category) => {
    return {
        ...category,
        category: category.category !== null ? category.category : "(بدون دسته‌بندی)",
    };
};

export const toggleLoading = (value) => ({
    type: TOGGLE_LOADING_REPORT,
    value: value,
});
export const removeReport = (id) => ({ type: REMOVE_REPORT, value: id });
export const editSpecificReport = (report) => ({
    type: EDIT_REPORT,
    value: fixReport(report),
});
export const addReport = (report) => ({
    type: ADD_REPORT,
    value: fixReport(report),
});

export const setCategories = (categories) => {
    const newCategories = categories.map((item) => fixCategory(item));
    let unique = [];
    newCategories.forEach((element) => {
        if (!unique.find((item) => item.category == element.category)) {
            unique.push(element);
        }
    });
    return {
        type: SET_CATEGORIES,
        value: unique,
    };
};

export const setReports = (reports) => {
    const newReports = reports.map((report) => fixReport(report));
    return { type: SET_REPORTS, value: [...newReports.reverse()] };
};

export const fetchReports = (force) => {
    return (dispatch, getState) => {
        if (getState().reports.reports.length > 1 && !force) return;
        dispatch(toggleLoading(true));
        axios
            .get("/reports")
            .then((response) => {
                dispatch(setReports(response.data.data));
                dispatch(toggleLoading(false));
            })
            .catch((error) => {
                dispatch(toggleLoading(false));
            });
    };
};

export const fetchSingleReport = (reportId) => {
    return (dispatch) => {
        axios
            .get(`/reports?report_id=${reportId}`)
            .then((response) => {
                dispatch(editSpecificReport(response.data.data));
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const fetchCategories = (force) => {
    return (dispatch, getState) => {
        if (getState().reports.categories.length > 1 && !force) return;
        axios
            .get("/reports/categories")
            .then((response) => {
                dispatch(setCategories(response.data));
            })
            .catch((error) => {
                console.log("problem fetching categories");
            });
    };
};

export const deleteReport = (reportId) => {
    return (dispatch) => {
        dispatch(toggleLoading(true));
        axios
            .delete("/reports", {
                params: {
                    report_id: reportId,
                },
            })
            .then((response) => {
                dispatch(showAlert("گزارش  شما با موفقیت حذف شد", "success"));
                dispatch(removeReport(reportId));
                dispatch(toggleLoading(false));
            })
            .catch((error) => {
                dispatch(showAlert("مشکلی در حذف گزارش وجود دارد", "error"));
                dispatch(toggleLoading(false));
            });
    };
};

export const createReport = (payload) => {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true));
        axios
            .post("/reports", payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                dispatch(showAlert("گزارش  شما با موفقیت ساخته شد", "success"));
                dispatch(addReport(response.data.data));
                let newCategories = [
                    ...getState().reports?.categories,
                    { category: response.data.data.category, reports_count: 1 },
                ];
                dispatch(setCategories(newCategories));
                dispatch(toggleLoading(false));
            })
            .catch((error) => {
                dispatch(showAlert(error?.response?.data?.message, "error"));
                dispatch(toggleLoading(false));
            });
    };
};

export const editReport = (payload) => {
    return (dispatch) => {
        dispatch(toggleLoading(true));
        axios
            .post("/reports/edit", payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                dispatch(showAlert("تغییرات با موفقیت اعمال شد", "success"));
                dispatch(editSpecificReport(response.data.data));
                dispatch(toggleLoading(false));
            })
            .catch((error) => {
                dispatch(showAlert(error?.response?.data?.message, "error"));
                dispatch(toggleLoading(false));
            });
    };
};

export const createTask = (reportId) => {
    return (dispatch) => {
        dispatch(toggleLoading(true));
        axios
            .post("/reports/tasks", {
                report_id: reportId,
            })
            .then((response) => {
                dispatch(showAlert("ابلاغیه صادر شد", "success"));
                dispatch(toggleLoading(false));
            })
            .catch((error) => {
                dispatch(showAlert(error?.response?.data?.message, "error"));
                dispatch(toggleLoading(false));
            });
    };
};
