import React from "react";
import { Container, TextField, Typography, InputAdornment, Button } from "@material-ui/core";

import ArticleCheckbox from "./ArticleCheckbox";
import PriceField from "../../../components/UI/Fields/PriceField";
import ReadMoreTypo from "../../../components/UI/Typography/ReadMoreTypo";

import articleInfo from "./articleInfo";
import locale from "../../../shared/locale";
import Styles from "../wageStyles";
import clsx from "clsx";

const CalculatorAttributes = (props) => {
    const classes = Styles();
    const { attributes, handleChange, formData, contentRef } = props;

    const getTypo = (attribute, key) => {
        return (
            <React.Fragment key={key}>
                {attribute.title !== "" && (
                    <Typography variant="body1" gutterBottom className={classes.title}>
                        {attribute.title}
                    </Typography>
                )}
                {attribute.name !== "" && (
                    <ReadMoreTypo collapseHeight={60} variant="body2" gutterBottom className={classes.message}>
                        {articleInfo(attribute)} {attribute.name}
                    </ReadMoreTypo>
                )}
            </React.Fragment>
        );
    };

    const getRefrence = (relatedAttributes) => {
        let buttonNode = "";
        relatedAttributes.forEach((item, index) => {
            if (item.type === "none") {
                buttonNode = (
                    <Button
                        className={classes.refButton}
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            contentRef.current[item.article_number].scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                            contentRef.current[item.article_number].className = clsx(
                                classes.articleContainer,
                                classes.elevate
                            );
                            setTimeout(() => {
                                contentRef.current[item.article_number].className = classes.articleContainer;
                            }, 4000);
                        }}
                    >
                        مشاهده متن ماده {item.article_number}
                    </Button>
                );
            }
        });
        return buttonNode;
    };

    const getSubArticles = (relatedAttributes, attributeKey) => {
        return relatedAttributes.map((item, index) => {
            const value = formData[item.key]?.find((el) => el == attributeKey);

            return (
                <ArticleCheckbox
                    key={index}
                    controlProps={{ className: classes.formControl }}
                    label={getTypo(item)}
                    value={value}
                    onChange={(value) => {
                        let formValue = formData[item.key] ?? [];
                        if (value) formValue.push(attributeKey);
                        else formValue = formValue.filter((el) => el !== attributeKey);
                        handleChange(formValue, item.key);
                    }}
                />
            );
        });
    };

    let finalAttributes = attributes?.map((attribute, index) => {
        switch (attribute.type) {
            case "none":
                return (
                    <React.Fragment>
                        {attribute.related_attribute.length > 0 && <div style={{ height: 30 }}></div>}

                        <ReadMoreTypo
                            key={index}
                            collapseHeight={60}
                            variant="body2"
                            gutterBottom
                            className={classes.articleText}
                        >
                            {articleInfo(attribute)} {attribute.name}
                        </ReadMoreTypo>
                    </React.Fragment>
                );
            case "boolean":
                return (
                    <ArticleCheckbox
                        controlProps={{ className: classes.formControl }}
                        label={getTypo(attribute)}
                        value={formData[attribute.key]}
                        onChange={(value) => handleChange(value, attribute.key)}
                    />
                );
            case "cost":
                return (
                    <React.Fragment key={index}>
                        {getTypo(attribute)}
                        <PriceField
                            style={{ marginTop: 10 }}
                            label={attribute.input_label}
                            value={formData[attribute.key]}
                            variant="outlined"
                            size="small"
                            currency={locale(attribute.type)}
                            fullWidth
                            onChange={(value) => handleChange(value, attribute.key)}
                        />
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment key={index}>
                        {getTypo(attribute)}
                        <TextField
                            style={{ marginTop: 10 }}
                            label={attribute.input_label}
                            value={formData[attribute.key]}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">{locale(attribute.type)}</InputAdornment>
                                ),
                            }}
                            fullWidth
                            onChange={(event) => handleChange(event.target.value, attribute.key)}
                        />
                    </React.Fragment>
                );
        }
    });
    return finalAttributes.map((item, index) => (
        <Container
            className={classes.articleContainer}
            ref={(el) => (contentRef.current[attributes[index].article_number] = el)}
            style={{ marginTop: index !== 0 ? 10 : 0 }}
        >
            {getRefrence(attributes[index].related_attribute)}
            {item}
            {getSubArticles(
                attributes[index].related_attribute.filter((attribute) => attribute.type === "boolean"),
                attributes[index].key
            )}
        </Container>
    ));
};

export default CalculatorAttributes;
