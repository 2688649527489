import React from "react";
import axios from "../../api/instance";
import { useDispatch } from "react-redux";

import IntroLogin from "./IntroLogin";
import Attribute from "./Attribute/Attribute";
import Members from "./Members/Members";
import MyCustomers from "./MyCustomers/MyCustomers";

const ExpertIntro = () => {
    const [content, setContent] = React.useState({});

    React.useLayoutEffect(() => {
        axios
            .get("/homepage")
            .then((response) => {
                setContent(response.data.data);
            })
            .catch((error) => {});
    }, []);

    return (
        <div style={{ position: "relative" }}>
            <IntroLogin settings={content.settings} />
            <Attribute cards={content.cards ?? []} />
            <Members statics={content.settings?.statistics ?? []} />
            <MyCustomers customers={content.customers ?? []} />
        </div>
    );
};

export default ExpertIntro;
