import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    questionnaireContainer: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        top: 0,
        left: "calc(50% - 175px)",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    root: {
        width: 350,
        maxWidth: 500,
        backgroundColor: "#292a2d",
    },
    ActionContainer: {
        padding: 0,
    },
}));

const Questionnaire = () => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <div className={classes.questionnaireContainer}>
            <Card className={classes.root}>
                <CardContent style={{ padding: 16 }}>
                    <Grid container>
                        <Grid xs={7} style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                variant="body2"
                                style={{
                                    color: "#fff",
                                    fontSize: 11,
                                    fontFamily: "IRANSansBold",
                                }}
                                component="p"
                            >
                                پاسخ به پرسشنامه کارشناس رسمی
                            </Typography>
                        </Grid>
                        <Grid xs={5}>
                            <Button
                                fullWidth
                                variant="outlined"
                                style={{
                                    backgroundColor: "rgb(158, 70, 245)",
                                    color: "#e8e8e8",
                                    fontFamily: "IRANSansBold",
                                }}
                                onClick={() => history.push("/questionnaire")}
                            >
                                مشاهده پرسشنامه
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};
export default Questionnaire;
