import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import LinearLoading from "../../../components/Static/LinearLoading/LinearLoading";
import DataTable from "../../../components/Tables/DataTable/DataTable";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        boxShadow: "0 0 20px 0 #d7dee366",
        marginTop: 15,
        borderRadius: 4,
    },
    head: {
        backgroundColor: "#F9FAFC",
        color: "#A3A9B7",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        "&:nth-of-type(2)": {
            textAlign: "left",
        },

        borderWidth: 0,
    },
    row: {
        color: "#A3A9B7",
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "#fff",
        marginTop: 30,
        "&:nth-of-type(2)": {
            textAlign: "left",
        },
        borderWidth: 2,
    },
    button: {
        color: "#A3A9B7",
        backgroundColor: "rgba(196,196,196,0.3)",
        padding: theme.spacing(0.2, 3),
        borderRadius: 5,
    },
}));

const GroupsTable = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const user = useSelector((store) => store.user);
    const { groups, reports, loading } = props;

    const tableBody = groups.map((group, index) => ({
        data: {
            index: index + 1,
            name: group.name,
            reportsCount: reports.filter((report) => report.group_id === group.id).length,
            actions: (
                <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    className={classes.button}
                    onClick={() => history.push(`/${user.active_route}/group/${group.id}`)}
                >
                    مشاهده
                </Button>
            ),
        },
        config: {
            className: classes.row,
            align: "center",
        },
    }));

    if (loading) {
        return <LinearLoading width={200} title="در حال بارگیری" />;
    }
    return (
        <Box className={classes.tableContainer}>
            <DataTable
                head={{
                    data: {
                        index: "ردیف",
                        name: "نام",
                        reportsCount: "تعداد گزارش",
                        actions: "جزئیات",
                    },
                    config: {
                        className: classes.head,
                        align: "center",
                    },
                }}
                body={tableBody}
            />
        </Box>
    );
};

export default GroupsTable;
