const iso7064Mod97_10 = (iban) => {
    let remainder = iban,
        block;

    while (remainder.length > 2) {
        block = remainder.slice(0, 9);
        remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
    }

    return parseInt(remainder, 10) % 97;
};

const publicFormData = (defaults, styles) => {
    return {
        name: {
            elementType: "text-field",
            elementConfig: {
                label: "نام",
                variant: "outlined",
            },
            value: defaults.values.name,
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
        },
        surname: {
            elementType: "text-field",
            elementConfig: {
                label: "نام خانوادگی",
                variant: "outlined",
            },
            value: defaults.values.surname,
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
        },
        national_id: {
            elementType: "text-field",
            elementConfig: {
                label: "کد ملی",
                type: "number",
                variant: "outlined",
                disabled: defaults?.disabled?.national_id,
            },
            validation: {
                length: 10,
            },
            value: defaults.values.national_id,
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
        },
        mobile_number: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره موبایل",
                type: "number",
                variant: "outlined",
                disabled: true,
            },
            value: defaults.values.mobile_number,
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
            validation: {
                length: 11,
            },
        },
        bank_name: {
            elementType: "text-field",
            elementConfig: {
                label: "نام بانک",
                variant: "outlined",
            },
            value: defaults.values.bank_name,
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
        },
        IBAN: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره شبا",
                variant: "outlined",
                type: "number",
                className: styles.IBAN,
                InputProps: {
                    endAdornment: "IR",
                },
            },
            validation: {
                length: 24,
                custom: (value) => {
                    if (!value) return true;
                    const newValue = "IR" + value.toString();
                    let newStr = newValue.toString().substr(4);
                    let d1 = newValue.charCodeAt(0) - 65 + 10;
                    let d2 = newValue.charCodeAt(1) - 65 + 10;
                    newStr += d1.toString() + d2.toString() + newValue.substr(2, 2);
                    let remainder = iso7064Mod97_10(newStr);
                    if (remainder !== 1) {
                        return false;
                    }
                    return true;
                },
            },
            value: defaults.values.IBAN,
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 4,
            },
        },
        province_id: {
            elementType: "province-fields",
            elementConfig: {
                variant: "outlined",
            },
            value:
                {
                    province_id: defaults.values?.province_id,
                    town_id: defaults.values?.town_id,
                    city_id: defaults.values?.city_id,
                } ?? {},
        },
    };
};

export default publicFormData;
