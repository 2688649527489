import axios from "../../api/instance";
import { showAlert, TYPES } from "./snackbarActions";
import { updateUserInformation } from "./userActions";

export const SET_ROLES = "SET_ROLES";
export const SET_GROUP_ROLES = "SET_GROUP_ROLES";
export const UPDATE_GROUP_ROLE = "UPDATE_GROUP_ROLE";
export const TOGGLE_ROLES_DRAWER = "TOGLLE_ROLES_DRAWER";
export const TOGGLE_LOADING_ROLES = "TOGGLE_LOADING_ROLES";

export const toggleRoles = (value) => ({ type: TOGGLE_ROLES_DRAWER, value: value });
export const toggleLoading = (value) => ({ type: TOGGLE_LOADING_ROLES, value: value });
export const setRoles = (roles) => ({ type: SET_ROLES, value: roles });
export const setGroupRoles = (roles) => ({ type: SET_GROUP_ROLES, value: roles });
export const updateGroupRole = (role) => ({ type: UPDATE_GROUP_ROLE, value: role });

export const fetchRoles = () => {
    return (dispatch) => {
        dispatch(toggleLoading(true));
        axios
            .get("/users/my-roles")
            .then((response) => {
                dispatch(setRoles(response.data.data?.roles));
                dispatch(setGroupRoles(response.data.data?.group_roles));
                dispatch(toggleLoading(false));
            })
            .catch((error) => {
                dispatch(toggleLoading(false));
                console.log("problem fetching roles");
            });
    };
};

export const acceptOrRejectRole = (role, status) => {
    return (dispatch) =>
        axios
            .post("/users/my-roles/check", {
                group_member_id: role.group_member_id,
                status: status,
            })
            .then((response) => {
                dispatch(updateGroupRole({ ...role, state: status ? "ACCEPTED" : "REJECTED" }));
                dispatch(showAlert(response.data.message, TYPES.SUCCESS));
            })
            .catch((error) => {
                dispatch(showAlert(error.response?.data?.message, TYPES.ERROR));
            });
};

export const enterRole = (id, groupId) => {
    return (dispatch) =>
        axios
            .post("/users/set-role", {
                role_id: id,
                group_id: groupId,
            })
            .then((response) => {
                dispatch(updateUserInformation(response.data));
                dispatch(showAlert("با موفقیت وارد شدید", TYPES.SUCCESS));
            })
            .catch((error) => {
                dispatch(showAlert(error.response?.data?.message, TYPES.ERROR));
            });
};
