import React from "react";
import clsx from "clsx";
import { Grid, Typography, Avatar, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment-jalaali";
import FileView from "../File/FileView";
import styles from "./MessageStyle";

const AnotherMessage = (props) => {
    const { message, name, surname, created_at, mime_type, avatar_link } = props.data;
    const classes = styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const UrlReplace = (text) => {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        const ans = [...text.matchAll(urlRegex)];
        text = text.split(urlRegex);
        ans.forEach((item, key) => {
            item = item[1];
            text[key + 1] = (
                <a href={item} target="_blank">
                    {item}
                </a>
            );
        });
        return text;
    };
    return (
        <Grid item md={12} xs={12} sm={12} style={{ display: "flex", position: "relative" }}>
            <div className={classes.anotherMessageContainer}>
                <div className={clsx(classes.message, "anotherMessage")} style={{ maxWidth: isMobile && "90%" }}>
                    <Typography component="span">{name + " " + surname}</Typography>
                    {!mime_type ? (
                        message.split("\n").map((msg, key) => (
                            <Typography key={key} component="p" style={{ fontSize: "0.8rem" }}>
                                {UrlReplace(msg)}
                            </Typography>
                        ))
                    ) : (
                        <FileView data={props.data} />
                    )}
                    <Typography component="p" className="messageDate">
                        {moment(created_at, "YYYY/MM/DD HH:mm").locale("fa").format("YYYY/MM/DD HH:mm")}
                    </Typography>
                </div>
            </div>
            <div className={classes.avatarContainer}>
                <Avatar src={avatar_link} />
            </div>
        </Grid>
    );
};

export default AnotherMessage;
