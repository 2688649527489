import React from "react";
import clsx from "clsx";
import { Box, Button, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DataTable from "../../../components/Tables/DataTable/DataTable";
import Styles from "../profileStyles";

import { enterRole, acceptOrRejectRole } from "../../../store/actions/rolesActions";
import locale from "../../../shared/locale.js";

const RolesTable = (props) => {
    const history = useHistory();
    const classes = Styles();
    const dispatch = useDispatch();
    const { groupRoles } = props;

    const tableBody = groupRoles.map((role, index) => {
        let actionNode = "";

        switch (role.state) {
            case "PENDING":
                actionNode = (
                    <>
                        <Button className={classes.greenButton} onClick={() => dispatch(acceptOrRejectRole(role, 1))}>
                            قبول نقش
                        </Button>
                        <Button className={classes.redButton} onClick={() => dispatch(acceptOrRejectRole(role, 0))}>
                            رد نقش
                        </Button>
                    </>
                );
                break;
            case "ACCEPTED":
                actionNode = (
                    <Button
                        variant="contained"
                        disableElevation
                        size="small"
                        onClick={() => {
                            dispatch(enterRole(role.role_id, role.group_id));
                            history.push("/");
                        }}
                        className={classes.blueButton}
                    >
                        ورود
                    </Button>
                );
                break;
            case "REJECTED":
                actionNode = (
                    <Typography variant="caption" className={classes.rejectedText}>
                        رد شده
                    </Typography>
                );
            default:
                break;
        }
        return {
            data: {
                group_name: role.group_name,
                group_type: locale(role.group_type),
                role_id: role.slug,
                action: actionNode,
            },
            config: {
                className: clsx(classes.tableRow, classes.roleRow),
            },
        };
    });

    return (
        <Box className={classes.tableContainer}>
            <DataTable
                head={{
                    data: {
                        group_name: "سازمان",
                        group_type: "نوع سازمان",
                        role_id: "نقش",
                        action: "",
                    },
                    config: {
                        className: classes.tableHead,
                    },
                }}
                body={tableBody}
            />
        </Box>
    );
};

export default RolesTable;
