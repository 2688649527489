import React from "react";
import clsx from "clsx";
import { Box, makeStyles } from "@material-ui/core";

const borderRadius = 4;

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        "& > div": {
            padding: theme.spacing(0.2, 0.2),
        },
        "& table": {
            borderCollapse: "initial",
        },
        "& tr:first-child td:first-child": {
            borderTopLeftRadius: borderRadius,
        },
        "& tr:first-child td:last-child": {
            borderTopRightRadius: borderRadius,
        },
        "& tr:last-child td:first-child": {
            borderBottomLeftRadius: borderRadius,
        },
        "& tr:last-child td:last-child": {
            borderBottomRightRadius: borderRadius,
        },
        "& thead th:first-child": {
            borderTopRightRadius: borderRadius,
        },
        "& thead th:last-child": {
            borderTopLeftRadius: borderRadius,
        },
        "& tr:first-child td": {
            borderTopWidth: borderRadius / 2,
        },
        "& tr:last-child td": {
            borderBottomWidth: borderRadius / 2,
        },
        "& tr td:last-child": {
            borderRightWidth: borderRadius / 2,
        },
        "& tr td:first-child": {
            borderLeftWidth: borderRadius / 2,
        },
    },
}));

const TableRadiusBox = (props) => {
    const classes = useStyles();
    const { className } = props;
    return <Box className={clsx(classes.tableContainer, className)}>{props.children}</Box>;
};

export default TableRadiusBox;
