const searchInputs = (defaults) => {
    return {
        name: {
            elementType: "text-field",
            elementConfig: {
                label: "نام",
            },
            gridConfig: {
                lg: 3,
                md: 4,
                xs: 6,
            },
            value: "",
        },
        surname: {
            elementType: "text-field",
            elementConfig: {
                label: "نام خانوادگی",
            },
            gridConfig: {
                lg: 3,
                md: 4,
                xs: 6,
            },
            value: "",
        },
        license_number: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره پروانه",
                type: "number",
            },
            gridConfig: {
                lg: 3,
                md: 4,
                xs: 6,
            },
            hide: defaults.hide.license_number,
            value: "",
        },
        national_id: {
            elementType: "text-field",
            elementConfig: {
                label: "کد ملی",
                type: "number",
            },
            gridConfig: {
                lg: 3,
                md: 4,
                xs: 6,
            },
            value: "",
        },
        province_id: {
            elementType: "search-list-input",
            elementConfig: {
                label: "استان",
                options: defaults.province_id.options ?? [],
            },
            gridConfig: {
                lg: 3,
                md: 4,
                xs: 6,
            },
            value: "",
        },
        town_id: {
            elementType: "search-list-input",
            elementConfig: {
                label: "شهرستان",
                options: [],
            },
            gridConfig: {
                lg: 3,
                md: 4,
                xs: 6,
            },
            value: "",
        },
        city_id: {
            elementType: "search-list-input",
            elementConfig: {
                label: "شهر",
                options: [],
            },
            gridConfig: {
                lg: 3,
                md: 4,
                xs: 6,
            },
            value: "",
        },
        expertise: {
            elementType: "search-list-input",
            elementConfig: {
                label: "رشته",
                options: [],
            },
            gridConfig: {
                lg: 3,
                md: 4,
                xs: 6,
            },
            hide: defaults.hide.expertise,
            value: "",
        },
        competences: {
            elementType: "multiple-select",
            elementConfig: {
                label: "صلاحیت",
                options: [],
                noOptionLabel: "رشته را انتخاب کنید",
            },
            gridConfig: {
                lg: 6,
                md: 8,
                xs: 12,
            },
            hide: defaults.hide.competences,
            value: [],
        },
    };
};
export default searchInputs;
